import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ColDef, GridApi, GridReadyEvent, IDetailCellRendererParams, IsRowMaster } from 'ag-grid-community';
import { CCAPlanningService } from 'src/app/services/cca-planning.service';
import { ScheduleNominationsService } from '../../schedule-nominations.service';

declare var $: any;

@Component({
  selector: 'app-nomination-details',
  templateUrl: './nomination-details.component.html',
  styleUrls: ['./nomination-details.component.scss'],
})
export class NominationsDetailsComponent implements OnInit {
    
  public gridData = [];
  public nominationKey: any;
  private gridApi!: GridApi;
  public handlerService: ScheduleNominationsService;
  public loader =
    '<div aria-live="polite" aria-atomic="true" style="position:absolute;top:0;left:0;right:0; bottom:0; display: flex; justify-content: center; align-items: center; aria-label="loading"> Loading... </div>';
  public isRowMaster: IsRowMaster = (dataItem: any) => {
    return dataItem ? dataItem.nominationUIDataItem.length > 0 : false;
  };

  public columnDefs: ColDef[] = [
    { field: "id", hide: true },
    { field: "nominationKey", cellRenderer: "agGroupCellRenderer", checkboxSelection: true },
    { field: "nominationNo" },
    { field: "transportSystem" },
    { field: "nomType" },
    { field: "vehicleCode" }
  ];
  public defaultColDef: ColDef = {
    flex: 1,
  };

  public detailCellRendererParams: any = {
    detailGridOptions: {
      columnDefs: [
        { field: "itemId", hide: true },
        { field: "nominationKeyItem" },
        { field: "scheduleType", minWidth: 150 },
        { field: "nominationItemStatus" },
        { field: "scheduledDate" },
        { field: "locationId" },
        { field: "scheduledMaterial" },
        { field: "scheduledMaterialDesc" },
        { field: "scheduledQty" },
        { field: "referenceDocumentIndicator" },
        { field: "nominationReferenceDocument" },
        { field: "nominationReferenceDocumentItem" }
      ],
      defaultColDef: {
        flex: 1,
      },
    },
    getDetailRowData: function (params) {
      params.successCallback(params.data.nominationUIDataItem);
    },
  };
  public rowData!: any[];

  constructor(private http: HttpClient, private service: CCAPlanningService) {}

  ngOnInit(): void {

    this.handlerService = new ScheduleNominationsService(this.http, "LATAM");

  }

  onFirstDataRendered(params: any) {
    // arbitrarily expand a row for presentational purposes

    /*
    setTimeout(() => {
      params.api.getDisplayedRowAtIndex(1)!.setExpanded(true);
    }, 0);
    */
  }

  onGridReady(params: GridReadyEvent) {
    this.gridApi = params.api;
    this.rowData = this.gridData; 
  }

  getData(nomKey, nomNum) {
    this.gridApi.showLoadingOverlay();
    this.service.getNominations(nomKey,nomNum).subscribe(
      (response) => {
        this.gridData = response
      },
      (err) => {
        this.gridData = [];
        console.log(err);
      }
    );
  }

  reset() {
    this.gridData = [];
    this.service.changeNomination(null)
  }

  onRowSelected(row) {
    // console.log(row)
    this.nominationKey = row?.node.selected ? row.data.nominationKey : null;
    this.service.changeNomination(this.nominationKey)
  }

  
}
