<form class="mt-3" [formGroup]="baseOilsSettingsForm">
    <ng-container *ngFor="let group of groups">
        <div [formGroupName]="group" class="grp-container">
            <h5> <strong>{{ getName(group) }}</strong> </h5>
            <div *ngFor="let control of controls">
                <div class="form-group" *ngIf="control !== 'hurricaneTimePeriod'">
                    <label for="{{group +''+ control}}"> {{control}} </label>
                    <input type="number" class="form-control" id="{{group + control}}" [name]="control"
                        [formControlName]="control" [readonly]="readOnly" />
                </div>
                <div *ngIf="control == 'hurricaneTimePeriod'">
                    <app-date-range-selection
                    [label]="'Hurricane Time Period'" 
                    [from]="baseOilsSettingsForm.controls[group].value.hurricaneTimePeriod.startDate" 
                    [to]="baseOilsSettingsForm.controls[group].value.hurricaneTimePeriod.endDate" [id]="group"
                    (dateRangeSelection)="onDateRangeSelection($event, group)"
                    [readOnly]="readOnly"></app-date-range-selection>
                </div>
            </div>
        </div>
    </ng-container>
</form>