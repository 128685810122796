<div class="homepageComponent">
  <div>
    <div id="bg">
      <img [src]="imgRefinery" />
    </div>
    <div style="height:200px;border:10px solid white;">

    </div>
    <div *ngIf="!unauthorised" >
      <div class="row" style="padding-left: 500px!important;">   
        <button mat-button [matMenuTriggerFor]="belowMenu"
         class="mainbutton">{{selectedRefinery}}&nbsp;
         <mat-icon class="mat-icon-align-middle">arrow_forward</mat-icon>
        </button>

        <mat-menu #belowMenu="matMenu" yPosition="below">
          <button mat-menu-item style="width:350px;" (click)="OnRefinerySelection('pascagou',true)" [disabled]="objRefiAuth.showPAS">pascagoula</button>
          <button mat-menu-item (click)="OnRefinerySelection('richmond',true)" [disabled]="objRefiAuth.showRIC">richmond</button>
          <button mat-menu-item (click)="OnRefinerySelection('elsegund',true)" [disabled]="objRefiAuth.showELS" >elsegundo</button>
        </mat-menu>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <button mat-button (click)="LatamRouting()" 
         class="mainbutton" [disabled]="showLatam"
         >latam&nbsp;<mat-icon class="mat-icon-align-middle">open_in_new</mat-icon>
        </button>      
      </div>
      <br/><br/><br/><br/><br/>
      <div *ngIf="showMenuDisable" class="row" style="padding-left: 300px!important;">   
        <button mat-button [matMenuTriggerFor]="belowMenu"
         class="mainbutton">market elasticity&nbsp;
         <mat-icon class="mat-icon-align-middle">arrow_forward</mat-icon>
        </button>

        <mat-menu #belowMenu="matMenu" yPosition="below">
          <button *ngIf="showPASCHVP" mat-menu-item style="width:400px;" [disabled]="showHVPMenu" [matMenuTriggerFor]="HVP">hvp</button>
          <button *ngIf="showRICHVP" mat-menu-item style="width:400px;" [disabled]="showHVPMenu"  [matMenuTriggerFor]="RIC">hvp</button>
          <button *ngIf="showESEHVP" mat-menu-item style="width:400px;" [disabled]="showHVPMenu"  [matMenuTriggerFor]="ESE">hvp</button>
          <button *ngIf="showGasOils" mat-menu-item [disabled]="showGasOilsMenu" [matMenuTriggerFor]="GasOils">gas oils</button>
          <button *ngIf="showBaseOils" mat-menu-item [disabled]="showBaseOilsMenu" mat-menu-item [matMenuTriggerFor]="baseOils"  >base oils</button>
          <button *ngIf="showRICHVP"  mat-menu-item [disabled]="showBaseOilsMenu" [matMenuTriggerFor]="ricBaseOils">base oils</button>
        </mat-menu>

        <mat-menu #HVP="matMenu">
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/mogas'])" >Mogas</button>
         
          <button mat-menu-item [matMenuTriggerFor]="blend">Blend Grades</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/jet'])" >Jet</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/avgas'])" >Avgas</button>
          <button mat-menu-item  [matMenuTriggerFor]="diesel">Diesel</button>  
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/lco'])" >LCO</button>         
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/graphs'])" >Graphs</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/comparision','pascagou'])" >Comparison Reports</button>
        </mat-menu>
    
        <mat-menu #GasOils="matMenu">
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/gasoils/lsvgo'])" >LSVGO</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/gasoils/hsvgo'])"  >HSVGO</button>      
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/gasoils/hcgo'])"  >HCGO</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/gasoils/hco'])"  >HCO</button>      
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/graphs-gasoils'])" >Graphs GasOils</button>
          <button mat-menu-item  (click)="ElasticityRouting(['market-elasticity/gasoils-comparision'])" >Comparison Reports</button>
        </mat-menu>

        <mat-menu #blend="matMenu">
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/puldomestic'])"  >PUL Domestic</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ruldomestic'])"  >RUL Domestic</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/pulexport'])" >PUL Export</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/rulexport'])"  >RUL Export</button>
        </mat-menu>
    
        <mat-menu #diesel="matMenu">
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/lsd'])" >LSD</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ulsd'])" >ULSD</button>
          <button mat-menu-item (click)="ElasticityRouting()" [routerLink]="['market-elasticity/rds']" >RDS</button>
        </mat-menu>

        <mat-menu #RIC="matMenu">
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ric/mogas'])" >Mogas</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ric/jet'])" >Jet</button>      
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ric/oils'])"  >Diesel</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/rd','richmond'])" >Rd</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ric/avgas'])"  >AVGAS</button>   
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/graphs-ric'])" >Graphs</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/comparision','richmond'])" >Comparison Reports</button>
        </mat-menu>

        <mat-menu #ESE="matMenu">
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ese/mogas'])" >Mogas</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ese/jet'])" >Jet</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ese/oils'])">Diesel</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/rd','elsegund'])" >Rd</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/graphs-ese'])" >Graphs</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/comparision','elsegund'])">Comparison Reports</button>
        </mat-menu>

        <mat-menu #baseOils="matMenu">
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/baseoils'])"  >base oils</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/graphs-baseoils'])" >Graphs</button>
          <button mat-menu-item  (click)="ElasticityRouting(['market-elasticity/baseoils-comparision','pascagou'])" >Comparison Reports</button>
        </mat-menu>

        <mat-menu #ricBaseOils="matMenu">
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/ric/baseoils'])">base oils</button>
          <button mat-menu-item (click)="ElasticityRouting(['market-elasticity/graphs-baseoils-ric'])" >Graphs</button>
          <button mat-menu-item  (click)="ElasticityRouting(['market-elasticity/baseoils-comparision','richmond'])" >Comparison Reports</button>

        </mat-menu>

        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

        <button mat-button (click)="ExportsRouting()"
         class="mainbutton" [disabled]="showExportDisable"
         >exports schedules&nbsp;<mat-icon class="mat-icon-align-middle">open_in_new</mat-icon>
        </button>  
      </div>
    </div>
    
    <div class="error" *ngIf="unauthorised">Unauthorized</div>
  </div>
</div>
