class RefineryState {
  constructor() {
     // This is intentional
  }
  searchText = '';
  tabName = '';
  filteredProps = {};
  columnState: any[] = [];
}

export { RefineryState };
