import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError, of } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';

import { Router } from '@angular/router';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let handled: boolean = false;

    return next.handle(request).pipe(      
      catchError((returnedError) => {
        if (returnedError.status === 403) {
          this.router.navigate(['/'], {
            queryParams: { flow: 'unauthorised' },
          });
        }

        return throwError(returnedError.error);
      })
    );
  }
}
