<div class="icons">
  <a title="View" (click)="caseAction('view')"
    ><i class="fa fa-eye cursor" aria-hidden="true"></i
  ></a>

  <a title="Copy" (click)="caseAction('copy')">
    <i class="fas fa-copy cursor"></i>
  </a>
  <a title="Optimize" class="ml-2" (click)="caseAction('optimize')">
    <i class="fas fa-cogs cursor"></i>
  </a>
  <a title="Edit" class="ml-2" (click)="caseAction('edit')">
    <i class="fas fa-edit cursor"></i>
  </a>
  <a title="Delete" class="ml-2" (click)="caseAction('delete')">
    <i class="fas fa-trash-alt cursor" [style.color]="'red'"></i>
  </a>
</div>
