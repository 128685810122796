import { AbstractControl } from "@angular/forms";

export function validateOpsMin(opsMin:any, opsMax: any) {
    return function(form: AbstractControl) {
        
        const opsMinValue = form.get(opsMin).value;
        const opsMaxValue = form.get(opsMax).value;

        if(opsMinValue < opsMaxValue) {
            return null 
        }
        return {
            opsMin_error: true
        }
    }
}

export function validateMinESL(MinESL:any, OpsMin: any) {
    return function(form: AbstractControl) {
        
        const minESLValue = form.get(MinESL).value;
        const opsMinValue = form.get(OpsMin).value;

        if(minESLValue >= opsMinValue) {
            return null 
        }
        return {
            minESL_error: true
        }
    }
}

// MaxESL <= OpsMax
export function validateMaxESL(_maxESL:any, _opsMax: any) {
    return function(form: AbstractControl) {
        
        const maxESL = form.get(_maxESL).value;
        const opsMax = form.get(_opsMax).value;

        if(maxESL <= opsMax) {
            return null 
        }
        return {
            maxESL_error: true
        }
    }
}

// MinTargetInventory >= MinESL
export function validateminTargetInventory(_minTargetInventory:any, _minESL: any) {
    return function(form: AbstractControl) {
        
        const minTargetInventory = form.get(_minTargetInventory).value;
        const minESL = form.get(_minESL).value;

        if(minTargetInventory >= minESL) {
            return null 
        }
        return {
            minTargetInventory_error: true
        }
    }
}

// MaxTargetInventory <= MaxESL
export function validateMaxTargetInventory(_maxTargetInventory:any, _maxESL: any) {
    return function(form: AbstractControl) {
        
        const maxTargetInventory = form.get(_maxTargetInventory).value;
        const maxESL = form.get(_maxESL).value;

        if(maxTargetInventory <= maxESL) {
            return null 
        }
        return {
            maxTargetInventory_error: true
        }
    }
}

// TargetInventory >= MinTargetInventory
export function validateTargetInventory1(_targetInventory:any, _minTargetInventory: any) {
    return function(form: AbstractControl) {
        
        const targetInventory = form.get(_targetInventory).value;
        const minTargetInventory = form.get(_minTargetInventory).value;

        if(targetInventory >= minTargetInventory) {
            return null 
        }
        return {
            targetInventory_error1: true
        }
    }
}

// TargetInventory <= MaxTargetInventory
export function validateTargetInventory2(_targetInventory:any, _maxTargetInventory: any) {
    return function(form: AbstractControl) {
        
        const targetInventory = form.get(_targetInventory).value;
        const maxTargetInventory = form.get(_maxTargetInventory).value;

        if(targetInventory <= maxTargetInventory) {
            return null 
        }
        return {
            targetInventory_error2: true
        }
    }
}