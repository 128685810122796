import { formatDate } from '@angular/common';
import appConstant from '../../app/app.constants';
declare var $: any;
export class AgHelper {
  static highlightTextValueGetter(data, colDef) {
    if (data) {
      const _val: any = data[colDef.field];
      if (typeof _val !== 'object') {
        return _val;
      }

      if (_val === null) {
        return _val;
      }
      return _val['value'];
    }
    return;
  }

  static highlightTextValueSetter(newValue, data, colDef) {
    if (
      colDef.cellRendererParams &&
      colDef.cellRendererParams.module === 'admin'
    ) {
      data[colDef.field].value = colDef.cellRendererParams.validate(
        newValue,
        data[colDef.field].orgVal,
        colDef.headerName,
        data
      );
    } else {
      data[colDef.field].value = newValue;
    }

    return true;
  }

  static vcohighlightTextValueSetter(newValue, data, colDef) {
    if (colDef.cellRendererParams) {
      data[colDef.field] = colDef.cellRendererParams.validate(
        newValue,
        data[colDef.field],
        colDef.headerName,
        data
      );
    } else {
      data[colDef.field] = newValue;
    }

    return true;
  }

  // static getValueByType(value) {
  //   let _value: any;
  //   switch (typeof value) {
  //     case 'number':
  //       _value = value;
  //       break;
  //     default:
  //       _value = value;
  //       break;
  //   }
  //   return _value;
  // }

  static getDatePicker() {
    function Datepicker() {
       // This is intentional
    }
    Datepicker.prototype.init = function (params) {
      this.eInput = document.createElement('input');
      this.eInput.value = params.value;
      this.eInput.classList.add('ag-input');
      this.eInput.style.height = '100%';
      $(this.eInput).datepicker({ dateFormat: 'mm/dd/yy' });
    };
    Datepicker.prototype.getGui = function () {
      return this.eInput;
    };
    Datepicker.prototype.afterGuiAttached = function () {
      this.eInput.focus();
      this.eInput.select();
    };
    Datepicker.prototype.getValue = function () {
      return this.eInput.value;
    };
    Datepicker.prototype.destroy = function () {
       // This is intentional
    };
    Datepicker.prototype.isPopup = function () {
      return false;
    };
    return Datepicker;
  }

  static getDateFilter() {
    return {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        const dateAsString: any = formatDate(cellValue, 'MM/dd/yyyy', 'en-US');
        if (dateAsString == null) {
          return -1;
        }
        const dateParts = dateAsString.split('/');
        const cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[0] - 1),
          Number(dateParts[1])
        );
        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }
        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }
        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
      },
      browserDatePicker: true,
      minValidYear: 2000,
      filterOptions: ['inRange'],
      suppressAndOrCondition: true,
      buttons: ['clear'],
      inRangeInclusive: true,
    };
  }

  static getFilterPanelConfig = () => {
    return {
      toolPanels: [
        {
          id: 'filters',
          labelDefault: 'Filters',
          labelKey: 'filters',
          iconKey: 'filter',
          toolPanel: 'agFiltersToolPanel',
          toolPanelParams: {
            suppressExpandAll: true,
            suppressFilterSearch: true,
          },
        } /* ,
        {
          id: 'columns',
          labelDefault: 'Columns',
          labelKey: 'columns',
          iconKey: 'columns',
          toolPanel: 'agColumnsToolPanel',
          toolPanelParams: {
            suppressRowGroups: true,
            suppressValues: true,
            suppressPivotMode: true,
          },
        }, */,
      ],
      defaultToolPanel: 'filters',
    };
  };

  static findSubstring(
    value,
    filterText,
    delimiter = appConstant.SEARCH_DELIMITER
  ) {
    const filterTextLoweCase = filterText.toString().toLowerCase().trim();
    let valueLowerCase = '';
    if (value) {
      valueLowerCase = value.toString().toLowerCase();
    }
    function contains(target, lookingFor) {
      if (target === null) {
        return false;
      }
      let arr = [];
      if (lookingFor && delimiter) {
        arr = lookingFor ? lookingFor.split(delimiter) : [];
      }
      if (arr.length < 1) {
        return target.indexOf(lookingFor) >= 0;
      } else {
        for (let i = 0; i < arr.length; i++) {
          if (arr[i] && target.indexOf(arr[i].toString().trim()) >= 0) {
            return true;
          }
        }
        return false;
      }
    }
    return contains(valueLowerCase, filterTextLoweCase);
  }

  static roundFormatter = (value) => {
    if (value === undefined || value === null) {
      return null;
    }
    if (typeof value === 'number') {
      return value.toFixed(1);
    } else {
      return value;
    }
  };

  static groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }
}
