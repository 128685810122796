import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ThridPartyService {
  constructor(private http: HttpService) {}
  getRowData(id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/SraContracts`;
    return this.http.getData(url);
  }

  getColumnData(): Observable<any> {
    const url = environment.thirdpartyPageColumnDataUrl;
    return this.http.getData(url);
  }

  getProductOptions(): Observable<any> {
    const url = environment.masterDataProductsUrl;
    return this.http.getData(url);
  }

  getLocationOptions(): Observable<any> {
    const url = environment.masterDataLocationsUrl;
    return this.http.getData(url);
  }

  saveData(data, id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/thirdparty`;
    return this.http.postData(url, data);
  }
}
