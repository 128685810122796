class PulDomesticState {
  constructor() {
     // This is intentional
  }
  searchText = '';
  maxShipment = 200;
  filteredProps = {};
  columnState: any[] = [];
}

export { PulDomesticState };
