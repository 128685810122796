<div class="navBarComponent navbar" *ngIf="showTopBar">
  <div class="subnav" *ngIf="refineSelected">
    <button
      mat-button      
      href="javascript:void(0)"      
      routerLinkActive="active-main"
      [routerLinkActiveOptions]="{ exact: true }"
      [ngClass]="{ 'active-main': expandElasticity == true }"
      [matMenuTriggerFor]="MKT"
    >
      Market Elasticity
    </button>

    <mat-menu #MKT="matMenu">
      <button mat-menu-item *ngIf="showHVPMenu && showPASCHVP" [matMenuTriggerFor]="HVP" >HVP</button>
      <button mat-menu-item  *ngIf="showESEHVP" [matMenuTriggerFor]="ESE">HVP</button>
      <button mat-menu-item  *ngIf="showRICHVP" [matMenuTriggerFor]="RIC">HVP</button>
      <button mat-menu-item *ngIf="showGasOilsMenu && showGasOils" [matMenuTriggerFor]="GasOils">Gas Oils</button>
      <button mat-menu-item *ngIf="showBaseOilsMenu && showBaseOils" [matMenuTriggerFor]="baseOils"> Base Oils</button>
      <button mat-menu-item *ngIf="showBaseOilsMenu && showRICBaseOils" [matMenuTriggerFor]="baseOilsRic"> Base Oils</button>
    </mat-menu>

    <mat-menu #HVP="matMenu">
      <button mat-menu-item [routerLink]="['market-elasticity/mogas']" >Mogas</button>
      <button mat-menu-item [matMenuTriggerFor]="blend">Blend Grades</button>
      <button mat-menu-item [routerLink]="['market-elasticity/jet']">Jet</button>
      <button mat-menu-item [routerLink]="['market-elasticity/avgas']" >Avgas</button>
      <button mat-menu-item [matMenuTriggerFor]="diesel">Diesel</button>  
      <button mat-menu-item [routerLink]="['market-elasticity/lco']">LCO</button>         
      <button mat-menu-item [routerLink]="['market-elasticity/graphs']">Graphs</button>
      <button mat-menu-item [routerLink]="['market-elasticity/comparision','pascagou']">Comparison Reports</button>
    </mat-menu>

    <mat-menu #GasOils="matMenu">
      <button mat-menu-item [routerLink]="['market-elasticity/gasoils/lsvgo']">LSVGO</button>
      <button mat-menu-item [routerLink]="['market-elasticity/gasoils/hsvgo']" >HSVGO</button>      
      <button mat-menu-item [routerLink]="['market-elasticity/gasoils/hcgo']" >HCGO</button>
      <button mat-menu-item [routerLink]="['market-elasticity/gasoils/hco']" >HCO</button>      
      <button mat-menu-item [routerLink]="['market-elasticity/graphs-gasoils']">Graphs GasOils</button>
      <button mat-menu-item  [routerLink]="['market-elasticity/gasoils-comparision']">Comparison Reports</button>
    </mat-menu>

    <mat-menu #ESE="matMenu">
      <button mat-menu-item [routerLink]="['market-elasticity/ese/mogas']">Mogas</button>
      <button mat-menu-item [routerLink]="['market-elasticity/ese/jet']" >Jet</button>
      <button mat-menu-item [routerLink]="['market-elasticity/ese/oils']">Diesel</button>
      <button mat-menu-item [routerLink]="['market-elasticity/rd','elsegund']">Rd</button>
      <button mat-menu-item [routerLink]="['market-elasticity/graphs-ese']">Graphs</button>
      <button mat-menu-item [routerLink]="['market-elasticity/comparision','elsegund']">Comparison Reports</button>
    </mat-menu>

    <mat-menu #blend="matMenu">
      <button mat-menu-item [routerLink]="['market-elasticity/puldomestic']" >PUL Domestic</button>
      <button mat-menu-item [routerLink]="['market-elasticity/ruldomestic']" >RUL Domestic</button>
      <button mat-menu-item [routerLink]="['market-elasticity/pulexport']" >PUL Export</button>
      <button mat-menu-item [routerLink]="['market-elasticity/rulexport']" >RUL Export</button>
    </mat-menu>

    <mat-menu #diesel="matMenu">
      <button mat-menu-item [routerLink]="['market-elasticity/lsd']" >LSD</button>
      <button mat-menu-item [routerLink]="['market-elasticity/ulsd']" >ULSD</button>
      <button mat-menu-item [routerLink]="['market-elasticity/rds']" >RDS</button>
    </mat-menu>

    <mat-menu #RIC="matMenu">
      <button mat-menu-item [routerLink]="['market-elasticity/ric/mogas']">Mogas</button>
      <button mat-menu-item [routerLink]="['market-elasticity/ric/jet']" >Jet</button>      
      <button mat-menu-item [routerLink]="['market-elasticity/ric/oils']" >Diesel</button>
      <button mat-menu-item [routerLink]="['market-elasticity/rd','richmond']">Rd</button>
      <button mat-menu-item [routerLink]="['market-elasticity/ric/avgas']" >AVGAS</button>   
      <button mat-menu-item [routerLink]="['market-elasticity/graphs-ric']">Graphs</button>
      <button mat-menu-item [routerLink]="['market-elasticity/comparision','richmond']">Comparison Reports</button>
    </mat-menu>

    <mat-menu #baseOils="matMenu">
      <button mat-menu-item [routerLink]="['market-elasticity/baseoils']" >base oils</button>
      <button mat-menu-item [routerLink]="['market-elasticity/graphs-baseoils']" >Graphs</button>
      <button mat-menu-item  [routerLink]="['market-elasticity/baseoils-comparision','pascagou']" >Comparison Reports</button>    
    </mat-menu>

    <mat-menu #baseOilsRic="matMenu">
      <button mat-menu-item [routerLink]="['market-elasticity/ric/baseoils']" >base oils</button>
      <button mat-menu-item [routerLink]="['market-elasticity/graphs-baseoils-ric']" >Graphs</button>
      <button mat-menu-item  [routerLink]="['market-elasticity/baseoils-comparision','richmond']" >Comparison Reports</button>    
    </mat-menu>

  </div>


   <!--- LATAM -->
  <div class="subnav" *ngIf="showLatamMenu" >
    <button mat-button [matMenuTriggerFor]="latam" 
    routerLinkActive="active-main"
      [routerLinkActiveOptions]="{ exact: true }"
      [ngClass]="{ 'active-main': expandCCA == true }"
      >LATAM</button>    
    <mat-menu #latam="matMenu">
      <button mat-menu-item [routerLink]="['cca/cargo-planning']" >Cargo Planning</button>
      <button mat-menu-item [routerLink]="['cca/aop']" >Reports</button>
    </mat-menu>
  </div>

  <!--- Refinery Exports -->
  <div class="subnav" *ngIf="refineSelected && showExportMenu" >
    <button mat-button [matMenuTriggerFor]="exports" 
    routerLinkActive="active-main"
      [routerLinkActiveOptions]="{ exact: true }"
      [ngClass]="{ 'active-main': expandRefineryExports == true }">Exports</button>  
    <mat-menu #exports="matMenu" >
      <button mat-menu-item [routerLink]="exportsRouterLink" >Export Schedules</button>
    </mat-menu>
  </div>
</div>


