import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HomeService } from 'src/app/services/home.service';
import { ElasticityConstants } from '../constants/elasticity-constants';


@Component({
  selector: 'app-top-nav-bar',
  templateUrl: './top-nav-bar.component.html',
  styleUrls: ['./top-nav-bar.component.scss'],
})
export class TopNavBarComponent implements OnInit {
  url;
  toggle = false;
  expandReferenceData = false;
  expandCaseManagement = false;
  expandReports = false;
  expandElasticity = false;
  expandCCA = false;
  expandRefineryExports: Boolean = false;
  showTopBar = true;
  showForProd = true;
  showHVPMenu: boolean = false;
  showGasOilsMenu: boolean = false;
  showBaseOilsMenu: boolean = false;
  elasticityConstants: ElasticityConstants = new ElasticityConstants();
  userPermissions: any;
  showPASCHVP: boolean = false;
  showRICHVP: boolean = false;
  showESEHVP: boolean = false;
  showGasOils: boolean = false;
  showBaseOils: boolean = false;
  showRICBaseOils: boolean = false;
  loginedRefinery = "";
  showLatamMenu: boolean = false;
  showExportMenu: boolean = false;
  showMKEMenu: boolean = false;
  exportsRouterLink: string = "";
  refineSelected: boolean = false;
  objPermission: any;

  constructor(private router: Router,
    private homeService: HomeService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let evt: NavigationEnd = event;

        if (evt.url === '/') {
          this.showTopBar = false;
        } else {
          this.showTopBar = true;
        }

        this.resetToggles();

        if (evt.url.indexOf('case/') != 1) {
          window.sessionStorage.removeItem('caseID');
        }

        if (evt.url.indexOf('admin') != -1) {
          this.expandReferenceData = true;
          if (!environment.showForProd)
            this.router.navigate(['admin/doconstant']);
          return;
        }
        if (evt.url.indexOf('report') != -1) {
          this.expandReports = true;
          return;
        }

        if (evt.url.indexOf('market-elasticity') != -1) {
          this.expandElasticity = true;
          this.showForProd = environment.showForProd;
          return;
        }

        if (evt.url.indexOf('cca') != -1) {
          this.expandCCA = true;
          this.showForProd = environment.showForProd;
          return;
        }

        if (evt.url.indexOf('refinery-exports') != -1) {
          this.expandRefineryExports = true;
          this.showForProd = environment.showForProd;
          return;
        }

        if (window.sessionStorage.getItem('caseID')) {
          this.expandCaseManagement = true;
          return;
        }

      }
    });

    this.showHVPMenu = false;
    this.showGasOilsMenu = false;
    this.showBaseOilsMenu = false;
    this.showLatamMenu = false;
    this.showExportMenu = false;
    this.showMKEMenu = false;
    
    this.homeService.getUserPermissions().subscribe((data) => {      
      this.refineSelected = false;
      let ref = window.sessionStorage.getItem('loginRefinery');

      this.setRefinaryProductsMenu(ref);
      this.loginedRefinery = ref;

      //console.log("getUserPermissions: ", data);

      if (data != undefined) {
        this.objPermission = data;

        if (this.loginedRefinery != null && this.loginedRefinery != "") {

          //console.log("loginedRefinery:", this.loginedRefinery);

          this.refineSelected = true;
          let refCode = this.loginedRefinery.substring(0, 3);
          if (this.objPermission.modules.elasticity != undefined)  {
            
            let objMkt = this.objPermission.modules.elasticity[refCode];

            //console.log("this.objPermission.modules.elasticity",this.objPermission.modules.elasticity);
            this.showMKEMenu = true;
            
            if (objMkt != undefined) {

              

              if (objMkt.hvp != undefined) {
                if (objMkt.hvp.contributor == true || objMkt.hvp.readOnly == true) {
                  this.showHVPMenu = true;
                }                
              }
              if (objMkt.gasOils != undefined) {                
                if (objMkt.gasOils.contributor == true || objMkt.gasOils.readOnly == true) {
                  this.showGasOilsMenu = true;
                }
              }
              if (objMkt.baseOils != undefined) {                
                if (objMkt.baseOils.contributor == true || objMkt.baseOils.readOnly == true) {
                  this.showBaseOilsMenu = true;
                }
              }
            }
          }


          let objExport = this.objPermission.modules.exports[refCode];
          if (objExport != undefined) {
            if (objExport.contributor == true || objExport.readOnly == true) {
              this.showExportMenu = true;
            }
          }
        }

        let objLatam = this.objPermission.modules.latam;
        if (objLatam != undefined) {
          if (objLatam.contributor == true || objLatam.readOnly == true) {
            this.showLatamMenu = true;
          }
        }
      }
    });

  }

  ngOnInit() {

    this.showHVPMenu = false;
    this.showGasOilsMenu = false;
    this.showBaseOilsMenu = false;
    this.showLatamMenu = false;
    this.showExportMenu = false;
    this.refineSelected = false;

    this.homeService.getLoginRefinery().subscribe((data) => {
      this.showHVPMenu = false;
      this.showGasOilsMenu = false;
      this.showBaseOilsMenu = false;
      this.showLatamMenu = false;
      this.showExportMenu = false;
      this.refineSelected = false;

      if (data != undefined) {
        if (data != "") {
          this.refineSelected = true;
        }
        this.setRefinaryProductsMenu(data);
        this.loginedRefinery = data;

        this.homeService.getUserPermissions().subscribe((data) => {
          if (data != undefined) {
            this.objPermission = data;

            let refCode = this.loginedRefinery.substring(0, 3);
            if (this.objPermission.modules.elasticity != undefined) {

              let objMkt = this.objPermission.modules.elasticity[refCode];

              if (objMkt != undefined) {
                if (objMkt.hvp != undefined) {
                  if (objMkt.hvp.contributor == true || objMkt.hvp.readOnly == true) {
                    this.showHVPMenu = true;
                  }
                }
                if (objMkt.gasOils != undefined) {
                  if (objMkt.gasOils.contributor == true || objMkt.gasOils.readOnly == true) {
                    this.showGasOilsMenu = true;
                  }
                }
                if (objMkt.baseOils != undefined) {
                  if (objMkt.baseOils.contributor == true || objMkt.baseOils.readOnly == true) {
                    this.showBaseOilsMenu = true;
                  }
                }
              }
            }

            let objExport = this.objPermission.modules.exports[refCode];
            if (objExport != undefined) {
              if (objExport.contributor == true || objExport.readOnly == true) {
                this.showExportMenu = true;
              }
            }

            let objLatam = this.objPermission.modules.latam;
            if (objLatam != undefined) {
              if (objLatam.contributor == true || objLatam.readOnly == true) {
                this.showLatamMenu = true;
              }
            }

          }
        });

      }
    });
  }

  resetToggles() {
    this.expandReferenceData = false;
    this.expandCaseManagement = false;
    this.expandReports = false;
    this.expandElasticity = false;
    this.expandCCA = false;
    this.expandRefineryExports = false;
    this.toggle = false;
  }

  showSubNav(id) {
    this.toggle = !this.toggle;
    if (this.toggle) {
      document.getElementById(id).style.display = 'flex';
    } else {
      document.getElementById(id).style.display = 'none';
    }
  }

  setRefinaryProductsMenu(arg) {
    this.showPASCHVP = false;
    this.showRICHVP = false;
    this.showESEHVP = false;
    this.showGasOils = false;
    this.showBaseOils = false;
    this.showRICBaseOils = false;

    if (arg == 'pascagou') {
      this.showPASCHVP = true;
      this.showGasOils = true;
      this.showBaseOils = true;
      this.exportsRouterLink = "refinery-exports/schedules/pascagou";
    }
    else if (arg == 'richmond') {
      this.showRICHVP = true;
      this.showRICBaseOils = true;
      this.exportsRouterLink = "refinery-exports/schedules/richmond";
    }
    else if (arg == 'elsegund') {
      this.showESEHVP = true;
    }
  }

}
