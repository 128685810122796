<div class="stepper" *ngIf="showProgress">
    <div style="float:left;"> 
        <mat-stepper labelPosition="bottom" #stepper >
            <mat-step state="done" editable="false" >
                <ng-template matStepLabel>refresh start</ng-template>
            </mat-step>
            <mat-step [state]="inventoryStatus" editable="false" #inventory>
                <ng-template matStepLabel><div class="stepper-header-overlay" (click)="ShowStatusMessage('inv')"></div>inventory</ng-template>
            </mat-step>
            <mat-step [state]="productionStatus" editable="false" #production>
                <ng-template matStepLabel><div class="stepper-header-overlay" (click)="ShowStatusMessage('prod')"></div>production</ng-template>
            </mat-step>
            <mat-step [state]="nominationStatus" editable="false" #nomination>
                <ng-template matStepLabel><div class="stepper-header-overlay" (click)="ShowStatusMessage('nomi')"></div>nominations</ng-template>
            </mat-step>
            <mat-step [state]="refreshEndsStatus" editable="false" >
                <ng-template matStepLabel>refresh ends</ng-template>
            </mat-step>

            <ng-template matStepperIcon="done">
                <mat-icon>done</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="disable">
                <mat-icon class="inprogress" >error</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="error" >
                <mat-icon class="errorcolor" >cancel</mat-icon>
            </ng-template>
            <ng-template matStepperIcon="partial" >
                <mat-icon class="partialsuccess" >cancel</mat-icon>
            </ng-template>
        </mat-stepper>   
    </div> 
    <div style="float:left;padding-top:20px;"> 
        <div>
            <span><mat-icon [class]="rotateRefresh" >{{statusIcon}}</mat-icon></span>
            <span [class]="percentage">{{refreshPercentage}}</span>
        </div>
        <div>
            <span [class]="status">{{refreshStatus}}</span>
        </div>
    </div>
</div>