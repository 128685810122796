<div
  *ngIf="notification && notification.type === 'info'"
  class="alert alert-info text-center"
>
  <i class="fa fa-lg fa-spinner" aria-hidden="true"></i>
  <a class="close" (click)="removeAlert(notification)">&times;</a>
  <span class="font" [innerHTML]="notification.message"></span>
</div>

<div
  *ngIf="notification && notification.type === 'success'"
  class="alert alert-success text-center"
>
  <i class="fa fa-lg fa-check-circle" aria-hidden="true"></i>
  <a class="close" (click)="removeAlert(notification)">&times;</a>
  <span class="font" [innerHTML]="notification.message"></span>
</div>

<div
  *ngIf="notification && notification.type === 'error'"
  class="alert alert-danger text-center"
>
  <i class="fa fa-lg fa-times-circle" aria-hidden="true"></i>
  <a class="close" (click)="removeAlert(notification)">&times;</a>
  <span class="font" [innerHTML]="notification.message"></span>
</div>

<div
  *ngIf="notification && notification.type === 'warning'"
  class="alert alert-warning text-center"
>
  <i class="fa fa-lg fa-exclamation-triangle" aria-hidden="true"></i>
  <a class="close" (click)="removeAlert(notification)">&times;</a>
  <span class="font" [innerHTML]="notification.message"></span>
</div>

<div *ngIf="notification && notification.type === 'status'" class="stepperstatus" >
    <div>
        <i class="fa fa-lg fa-info-circle" aria-hidden="true"></i>
        <a class="close" (click)="removeAlert(notification)">&times;</a>
        <span class="fontred">refresh failed</span>
    </div>
    <div class="status-msg">
        <span>An error has occured in the {{notification.id}}</span>
    </div>
    <div class="status-det-msg">
      <p>
        {{notification.message}}
      </p>
    </div>
</div>
