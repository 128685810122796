import { Injectable } from '@angular/core';
import { Notification, NotificationType } from './notification.model';
import { Subject, Observable } from 'rxjs';
@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  private subject = new Subject<Notification>();
  options = {
    autoClose: true,
    keepAfterRouteChange: false,
    timeout: 3000,
  };
  constructor() {
     // This is intentional
  }

  onAlert(): Observable<Notification> {
    return this.subject.asObservable();
  }

  success(msg?: string) {
    const message = msg || 'Data is saved successfully';
    const obj = new Notification({
      ...this.options,
      type: NotificationType.Success,
      message,
    });
    this.alert(obj);
  }

  error(msg?: string, time?) {
    const message = msg || 'We hit a snag!!';
    this.options.timeout = time || 3000;
    this.alert(
      new Notification({
        ...this.options,
        type: NotificationType.Error,
        message,
      })
    );
  }

  info(msg?: string) {
    const message = msg || 'Info';
    const options = { autoClose: false, keepAfterRouteChange: false };
    this.alert(
      new Notification({ ...options, type: NotificationType.Info, message })
    );
  }

  warn(msg?: string) {
    const message = msg || 'Warning';
    this.alert(
      new Notification({
        ...this.options,
        type: NotificationType.Warning,
        message,
      })
    );
  }

  alert(alert: Notification) {
    this.subject.next(alert);
  }

  status(obj?: any, time?) {
    const message = obj.errMsg || 'We hit a snag!!';
    this.options.timeout = time || 6000;
    this.alert(
      new Notification({
        ...this.options,
        id:obj.type,
        type: NotificationType.Status,
        message
      })
    );
  }
}
