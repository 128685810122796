import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from 'src/app/services/common/notification.service';
import { HomeService } from 'src/app/services/home.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  unauthorised = false;
  showForProd = true;
  selectedRefinery = "Choose Refinery";
  passRefineryVal = "";
  showMenuDisable: boolean = false;
  showPASCHVP: boolean = false;
  showRICHVP: boolean = false;
  showESEHVP: boolean = false;
  showGasOils: boolean = false;
  showBaseOils: boolean = false;
  showLatam: boolean = true;
  showExportDisable: boolean = true;
  showHVPMenu: boolean = true;
  showGasOilsMenu: boolean = true;
  showBaseOilsMenu: boolean = true;
  exportsRouterLink: string = "";
  objAuth = null;
  arrRefinery: string[] = ['pas', 'ric', 'els'];
  objRefiAuth = { showPAS: true, showRIC: true, showELS: true };
  imgRefinery = "../../../assets/images/Pascagoula.jpg";

  constructor(
    private route: ActivatedRoute,
    private notification: NotificationService,
    private homeService: HomeService,
    private router: Router
  ) {

    this.homeService.setLoginRefinery("");  

  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      if (params && params['flow']) {
        this.unauthorised = true;
      } else {
        this.unauthorised = false;
      }
    });
    this.showForProd = environment.showForProd;

    this.homeService.getUserPermissions().subscribe((data) => {
      if (data != undefined) {
        this.objAuth = data;
        
        if (data.modules.elasticity != undefined || data.modules.exports != undefined) {
          this.arrRefinery.forEach(element => {
            let objMkt = data.modules.elasticity ? data.modules.elasticity[element] : data.modules.exports[element];
            
            if (objMkt != undefined) {
              if (objMkt.hvp != undefined) {
                if (objMkt.hvp.contributor == true || objMkt.hvp.readOnly == true) {
                  this.objRefiAuth["show" + element.toUpperCase()] = false;
                }
              }
              if (objMkt.gasOils != undefined) {
                if (objMkt.gasOils.contributor == true || objMkt.gasOils.readOnly == true) {
                  this.objRefiAuth["show" + element.toUpperCase()] = false;
                }
              }
              if (objMkt.baseOils != undefined) {
                if (objMkt.baseOils.contributor == true || objMkt.baseOils.readOnly == true) {
                  this.objRefiAuth["show" + element.toUpperCase()] = false;
                }
              }
            }

            let objExport = data.modules.exports[element];
            if (objExport != undefined) {
              if (objExport.contributor == true || objExport.readOnly == true) {
                this.objRefiAuth["show" + element.toUpperCase()] = false;
              }
            }

          });
        }

        let objLatam = data.modules.latam;
        if (objLatam != undefined) {
          if (objLatam.contributor == true || objLatam.readOnly == true) {
            this.showLatam = false;
          }
        }
      }

      let refVal = window.sessionStorage.getItem('loginRefinery');
      if(refVal != "" && refVal != null)
      {
        this.OnRefinerySelection(refVal, false);
      }

    });

    

  }

  LatamRouting() {
    this.homeService.setLoginRefinery(this.passRefineryVal);
    this.router.navigate(['cca/cargo-planning']);
  }

  ExportsRouting(){
    this.homeService.setLoginRefinery(this.passRefineryVal);
    this.router.navigate([this.exportsRouterLink]);
  }

  ElasticityRouting(routeLink){
    this.homeService.setLoginRefinery(this.passRefineryVal);
    this.router.navigate(routeLink);
  }

  OnRefinerySelection(arg, setRefinery) {

    if (setRefinery) {
      window.sessionStorage.setItem('loginRefinery', arg);
      this.homeService.setLoginRefinery(arg);
    }

    this.selectedRefinery = (arg == 'elsegund') ? "elsegundo" : arg;
    this.passRefineryVal = arg;
    this.showMenuDisable = true;
    this.showExportDisable = true;

    this.showPASCHVP = false;
    this.showRICHVP = false;
    this.showESEHVP = false;
    this.showGasOils = false;
    this.showBaseOils = false;
    this.showHVPMenu = true;
    this.showGasOilsMenu = true;
    this.showBaseOilsMenu = true;

    if (arg == 'pascagou') {
      this.showPASCHVP = true;
      this.showGasOils = true;
      this.showBaseOils = true;
      this.exportsRouterLink = "refinery-exports/schedules/pascagou";
      this.imgRefinery = "../../../assets/images/Pascagoula.jpg";
    }
    else if (arg == 'richmond') {
      this.showRICHVP = true;
      this.exportsRouterLink = "refinery-exports/schedules/richmond";
      this.imgRefinery = "../../../assets/images/Richmond.jpg";
    }
    else if (arg == 'elsegund') {
      this.showESEHVP = true;
      this.imgRefinery = "../../../assets/images/ElSegundo.jpg";
    }

    let refCode = this.selectedRefinery.substring(0, 3);

    if (this.objAuth.modules.elasticity != undefined) {

      let objMkt = this.objAuth.modules.elasticity[refCode];      

      if (objMkt != undefined) {
        if (objMkt.hvp != undefined) {
          if (objMkt.hvp.contributor == true || objMkt.hvp.readOnly == true) {
            this.showHVPMenu = false;
          }
        }
        if (objMkt.gasOils != undefined) {
          if (objMkt.gasOils.contributor == true || objMkt.gasOils.readOnly == true) {
            this.showGasOilsMenu = false;
          }
        }
        if (objMkt.baseOils != undefined) {
          if (objMkt.baseOils.contributor == true || objMkt.baseOils.readOnly == true) {
            this.showBaseOilsMenu = false;
          }
        }
      }
    }

    let objExport = this.objAuth.modules.exports[refCode];
    if (objExport != undefined) {
      if (objExport.contributor == true || objExport.readOnly == true) {
        this.showExportDisable = false;
      }
    }
  }
}
