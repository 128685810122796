import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ITopPanel } from '../market-elasticity-model';

@Injectable({
  providedIn: 'root'
})

export class shareMKTDataService {

  private topPanelSubject: BehaviorSubject<ITopPanel> = new BehaviorSubject<ITopPanel>(null);
  topPanelSettings: Observable<ITopPanel> = this.topPanelSubject.asObservable();

  private showButtonSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  showButtonSettings: Observable<any> = this.showButtonSubject.asObservable();

  private constantsSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constantsSettings: Observable<any> = this.constantsSubject.asObservable();

  private snapShotSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  snapShotSettings: Observable<any> = this.snapShotSubject.asObservable();

  private rowDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  rowData: Observable<any> = this.rowDataSubject.asObservable();

  private saveDataSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  saveData: Observable<any> = this.saveDataSubject.asObservable();

  private rowToStartSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  rowStart: Observable<any> = this.rowToStartSubject.asObservable();

  private isDataChangedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dataChanged: Observable<any> = this.isDataChangedSubject.asObservable();

  private isRefreshSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dataRefresh: Observable<any> = this.isRefreshSubject.asObservable();

  private isInventoryChangeSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  inventoryChanged: Observable<any> = this.isInventoryChangeSubject.asObservable();

  private stepperConfigSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  stepConfig: Observable<any> = this.stepperConfigSubject.asObservable();

  private adhocRefreshSubject = new Subject<any>();
  public settingsDataSubject:Subject<any> = new Subject<any>();

  constructor() {

  }

  getTopPanelSettings(val: any) {
    this.topPanelSubject.next(val)
  }

  getShowButton(val: any) {
    this.showButtonSubject.next(val);
  }

  getConstants(val: any) {
    this.constantsSubject.next(val);
  }

  getSnapShot(val: any) {
    this.snapShotSubject.next(val);
  }

  getRowData(val: any) {
    this.rowDataSubject.next(val);
  }

  getSettingsData(val:any) {
    this.settingsDataSubject.next(val);
  }

  getSaveData(val: any) {
    this.saveDataSubject.next(val);
  }

  getRowStart(val: any) {
    this.rowToStartSubject.next(val);
  }

  getDataChanged(val: boolean) {
    this.isDataChangedSubject.next(val);
  }

  getDataRefreshed(val: boolean) {
    this.isRefreshSubject.next(val);
  }

  getInventoryChanged(val: boolean) {
    this.isInventoryChangeSubject.next(val);
  }

  getStepperConfigChanged(val: any) {
    this.stepperConfigSubject.next(val);
  }

  sendAdhocRefreshData(val: any) {
    this.adhocRefreshSubject.next(val);
  }

  getAdhocRefreshData(): Observable<any> {
    return this.adhocRefreshSubject.asObservable();
  }

}