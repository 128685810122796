<div id="myModal" class="modal fade">
  <div class="modal-dialog modal-confirm">
    <div class="modal-content">
      <div class="modal-header flex-column">
        <h4 class="modal-title w-100">{{ title }}</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-hidden="true"
        >
          &times;
        </button>
      </div>
      <div class="modal-body">
        <p>{{ subTitle }}</p>
      </div>
      <div class="modal-footer justify-content-center">
        <button
          type="button"
          class="btn btn-secondary"
          data-dismiss="modal"
          (click)="onClick(false)"
        >
          {{ buttonSecondary }}
        </button>
        <button
          type="button"
          class="btn btn-danger"
          data-dismiss="modal"
          (click)="onClick(true)"
        >
          {{ buttonPrimary }}
        </button>
      </div>
    </div>
  </div>
</div>
