import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { shareMKTDataService } from 'src/app/market-elasticity/common/market-elasticity-share-data';
import { MktGridComponent } from 'src/app/market-elasticity/mkt-grid/mkt-grid.component';
import { NotificationService } from 'src/app/services/common/notification.service';
import { SettingsService } from 'src/app/services/settings.service';
import { BaseOilSettingsComponent } from './base-oil-settings/base-oil-settings.component';
import { validateMaxESL, validateMaxTargetInventory, validateMinESL, validateminTargetInventory, validateOpsMin, validateTargetInventory1, validateTargetInventory2 } from './settings-btn.validators';
declare var $: any;

@Component({
  selector: 'app-settings-btn',
  templateUrl: './settings-btn.component.html',
  styleUrls: ['./settings-btn.component.scss'],
})
export class SettingsBtnComponent implements OnInit {
  settingsForm: FormGroup
  submitted = false;
  constants: any;
  configData: any;
  initalValues: any;
  baseOilConstants = [];
  @ViewChild(BaseOilSettingsComponent, {static: false}) baseOilComponent: BaseOilSettingsComponent;

  @Input() readOnly: boolean;
  
  constructor(
    private fb: FormBuilder,
    private service: shareMKTDataService,
    private settingsService: SettingsService,
    private notification: NotificationService) { }

  ngOnInit() {
    this.initForm();
    this.service.settingsDataSubject.subscribe(res => {
      this.constants = res.constants;
      this.configData = res.configData;
      console.log("configData:", this.configData);
      //this.readOnly = true;
      console.log("SettingsBtnComponent -> readOnly", this.readOnly);
      if(this.configData.category == "BaseOils") {
        this.baseOilConstants = this.constants;
      } else {
        this.populateForm(this.constants);
      }
    });
  }

  get f() { return this.settingsForm.controls; }

  onSubmit() {
    if(this.configData.category !== "BaseOils") {
      this.submitted = true;
      if (this.settingsForm.valid) {
        if (this.isDataChanged()) {
          this.notification.success
          this.notification.info('Saving...');
          this.saveSettings();
        } else {
          this.notification.warn("Please do make changes before save");
        }
      }
    } else {
      this.baseOilComponent.saveBaseOilSettings();
    }
  }

  saveSettings() {
    this.settingsService.saveSettings([this.settingsForm.value], this.configData)
      .subscribe(res => {
        this.notification.success();
        if (this.hasDaysToForecastChanged()) {
          this.settingsService.getDataRefreshed('adhoc');
        } else {
          this.settingsService.getDataRefreshed('table');
        }
        this.closeModal();
      }, err => {
        this.notification.error();
      });
  }

  initForm() {
    this.settingsForm = this.fb.group({
      DaysToForecast: ['', [Validators.min(30), Validators.max(120)]],
      OpsMin: ['', Validators.min(0)],
      OpsMax: ['', Validators.min(0)],
      MinESL: ['', Validators.min(0)],
      MaxESL: ['', Validators.min(0)],
      MinTargetInventory: ['', Validators.min(0)],
      MaxTargetInventory: ['', Validators.min(0)],
      TargetInventory: ['', Validators.min(0)]
    },
      {
        validators: [
          validateOpsMin('OpsMin', 'OpsMax'),
          validateMinESL('MinESL', 'OpsMin'),
          validateMaxESL('MaxESL', 'OpsMax'),
          validateminTargetInventory('MinTargetInventory', 'MinESL'),
          validateMaxTargetInventory('MaxTargetInventory', 'MaxESL'),
          validateTargetInventory1('TargetInventory', 'MinTargetInventory'),
          validateTargetInventory2('TargetInventory', 'MaxTargetInventory')
        ]
      })
  }

  populateForm(_constants: any) {
    let constants = Object.assign({}, _constants);
    this.settingsForm.setValue({
      DaysToForecast: constants.daysToForecast,
      OpsMin: constants.opsMin,
      OpsMax: constants.opsMax,
      MinESL: constants.minESL,
      MaxESL: constants.maxESL,
      MinTargetInventory: constants.targetMin,
      MaxTargetInventory: constants.targetMax,
      TargetInventory: constants.targetInventory
    });
    this.initalValues = JSON.parse(JSON.stringify(this.settingsForm.value));
  }

  isDataChanged() {
    return Object.keys(this.settingsForm.value)
      .some(key => {
        return this.settingsForm.value[key] !== this.initalValues[key]
      });
  }

  hasDaysToForecastChanged() {
    return this.settingsForm.value['DaysToForecast'] !== this.initalValues['DaysToForecast']
  }

  closeModal() {
    $('#settings-modal').modal('hide');
  }

  openModal() {
    if(this.configData.category !== "BaseOils") {
      this.submitted = false;
      this.populateForm(this.constants);
    } else {
      this.baseOilComponent.initLoad();
      this.baseOilComponent.populateForm();
    }
    $('#settings-modal').modal('show');
  }

}
