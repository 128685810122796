import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { Observable, Subject } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  
  public userProfile;
  public userPermission;

  private loginedRefinery = new Subject<any>();
  
  constructor(private http: HttpService) {}

  getRowData(createdby, url?): Observable<any> {
    url = environment.baseCaseUrl + '/' + createdby;
    return this.http.getData(url);
  }

  getCaseData(id, url?): Observable<any> {
    url = environment.baseCaseUrl + '/' + null + '/' + id;
    return this.http.getData(url);
  }

  getOptimizerRowData(url?): Observable<any> {
    url = environment.optimizerCaseUrl
    return this.http.getData(url);
  }

  getUserName() {
    return (
      this.userProfile.givenName +
      ' ' +
      this.userProfile.displayName.split(',')[0]
    );
  }

  getColumnData(tabName): Observable<any> {
    let url;
    if (tabName == 'optimizer-runs') {
      url = `${environment.optimizerCaseColumnDataUrl}`;
    }
    else {
      url = `${environment.baseCaseColumnDataUrl}`;
    }
    return this.http.getData(url);
  }

  // getColumnData(): Observable<any> {
  //   let url = environment.baseCaseColumnDataUrl;
  //   return this.http.getData(url);
  // }

  updateBaseCase({ id, title, description }): Observable<any> {
    const url = environment.baseCaseUrl;
    return this.http.postData(url, { id, title, description });
  }

  newBaseCase(data): Observable<any> {
    const url = environment.baseCaseUrl;
    if (data.description === null) {
      data.description = '';
    }
    return this.http.postData(url, data);
  }

  copyCase(data): Observable<any> {
    const url = environment.baseCaseUrl + '/copy';
    if (data.description === null) {
      data.description = '';
    }
    return this.http.postData(url, data);
  }

  deleteBaseCase(data): Observable<any> {
    const url = environment.baseCaseUrl + '/' + data.id;
    return this.http.deleteData(url);
  }

  getProfile() {
    return this.http.getData(environment.profileUrl);
  }

  getUserPermissions() {
    return this.http.getData(environment.doUserPermissionUrl);
  }

  setLoginRefinery(val: any) {
    this.loginedRefinery.next(val);
  }

  getLoginRefinery(): Observable<any> {
    return this.loginedRefinery.asObservable();
  }
}
