import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private httpClient: HttpClient) {}

  getData(url): Observable<any> {
    return this.httpClient.get(url);
  }

  postData(url, data): Observable<any> {
    return this.httpClient.post(url, data);
  }

  deleteData(url, options?): Observable<any> {
    return this.httpClient.delete(url, options);
  }
}
