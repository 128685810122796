export enum TabSection {
  MarketElasticityMogas = 1,
  MarketElasticityJet = 2,
  MarketElasticityLsd = 3,
  MarketElasticityUlsd = 4,
  MarketElasticityRds = 5,
  MarketElasticityPulDomestic = 18,
  MarketElasticityRulDomestic = 19,
  MarketElasticityPulExport = 20,
  MarketElasticityRulExport = 21,
  MarketElasticityLco = 22,
  LATAMCCAPlanning = 23,
  MKTElast = 24,
  

  RefineryInputScreen = 6,
  PipelineInputScreen = 7,
  DomesticCargoInputScreen = 8,
  IntCargoInputScreen = 9,
  StorageAvailInputScreen = 10,
  DockAvailInputScreen = 11,
  ContractsInputScreen = 12,
  CaseManagementScreen = 13,
  PriceInputScreen = 14,
  RefineryIsoMaxScreen = 15,
  OptimizerManagementScreen = 16,
  AdminVesselScreen = 17,
}
