import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './common/http.service';

@Injectable({
  providedIn: 'root',
})
export class PriceService {
  constructor(private http: HttpService) {}
  getRowData(id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/PfsPricing`;
    return this.http.getData(url);
  }

  getColumnData(): Observable<any> {
    const url = environment.pricePageColumnDataUrl;
    return this.http.getData(url);
  }

  saveData(data, _id): Observable<any> {
    const url = `${environment.baseCaseUrl}/PfsPricing`;
    return this.http.postData(url, data);
  }
}
