import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { HttpService } from './common/http.service';
import { HomeService } from 'src/app/services/home.service';
import {  IConstants } from './../market-elasticity/market-elasticity-model';

@Injectable({
  providedIn: 'root',
})

export class MarketElasticityService {
  dateFrom: any;

  constructor(private http: HttpService,
    private homeService: HomeService) {}
    constants: IConstants;
  getRowData(objScene, dateFrom?): Observable<any> { 
    this.dateFrom = dateFrom;

    let url = `${environment.marketElasticityV2Url}/${objScene.plantName}/${objScene.category}/${objScene.scenario}?dateFrom=${this.dateFrom}`;

    return this.http.getData(url).pipe(
      map((res) => {
        let entity = [];
        if (res === null)
          return entity;  
          const date = new Date(res.data.header.dateETLExecuted);
          res.data.header.dateETLExecuted = date.toLocaleString();

          const now = new Date();
          entity[0] = res.data.header;
          entity[0].date = now.toLocaleDateString();
          entity[1] = res.data.details; 
          if(objScene.category.toLowerCase() == "baseoils"){
            entity[2] = res.data.boConstants;
          }
          else{
            entity[2] = res.data.constants;
          }
          entity[3] = res.permissions.allowMktElasticityEdit;
          entity[4] = now.toLocaleDateString();
          entity[5] = res.data.isPublishedData;
        return entity;
      })
    );
  }


  getPublishedRowData(objScene,id?): Observable<any> {

    var url =  `${environment.vcoPublishHistoryUrl}/${objScene.plantName}/${objScene.category}/${objScene.scenario}/published`; 

    if(id != null && id != undefined)
    {
      url +=  `/${id}`;
    }

    return this.http.getData(url).pipe(
      map((res) => {
        let entity = [];
        const now = new Date();

        let objSnapShot = {
          scenarioId: id,
          date: res.data.datePublished,
          maxShipment: res.data.maxShipment,
          dateETLExecuted: res.data.datePublished  
        }        
        entity[0] = objSnapShot;   
        entity[1] = res.data.records;
        if(objScene.category.toLowerCase() == "baseoils"){
          entity[2] = res.constants;
        }
        else{
          entity[2] = res.constants[0];
        }

        entity[3] = res.permissions.allowMktElasticityEdit;
        entity[4] = res.data.datePublished;
        entity[5] = true;
        entity[6] = res.history;
        return entity;
      })
    );
  }

  getColumnData(scenario?, plantName?): Observable<any> {
    let url = "";
    switch(scenario){
      
        case "MOGAS":
        case "JET":
        case "LSD":
        case "ULSD":
        case "RDS":
        case "DIESEL":
        case "RD":
          if(plantName.toLowerCase() == "pascagou")
          {
            url = environment.mogasColumnDataUrl;
          }
          else
          {  
            url = environment.mogasRicEseColumnDataUrl; 
          }
          break;
        case "PUL Domestic":
        case "RUL Domestic":
        case "PUL Export":
        case "RUL Export":
          url = environment.mogasGradeColumnUrl;
          break;
        case "AVGAS":
          if(plantName.toLowerCase() == "pascagou")
          { 
            url = environment.avgasColumnDataUrl;
          }
          else
          {  
            url = environment.mogasRicEseColumnDataUrl; 
          }
          break;
        case "LCO":
          url = environment.lcoColumnDataUrl;
          break;
        case "LSVGO":
          url = environment.lsvgoColumnDataUrl;
          break;
        case "HCGO":
          url = environment.hcgoColumnDataUrl;
          break;
        case "HSVGO":
          url = environment.hsvgoColumnDataUrl;
          break;
        case "HCO":
          url = environment.hcoColumnDataUrl;
          break;
        case "BaseOils":
          if(plantName.toLowerCase() == "pascagou") {
            url = environment.baseoilsColumnDataUrl;
          } else {
            url = environment.ricmondBaseoilsColumnDataUrl;
          }
          break;
        default:
          url = environment.mogasGradeColumnUrl;
          break;
    }
    return this.http.getData(url);
  }  

  getPublishHistory(product): Observable<any> {
    const url = `${environment.vcoPublishHistoryUrl}/PASCAGOU/HVP/${product}/published`;    
    return this.http.getData(url);
  }

  isInventoryDifferent(localInventory, plantName, category, scenario): Observable<any> {
    const url = `${environment.vcoPublishHistoryUrl}/${localInventory}/${plantName}/${category}/${scenario}/localvsbackendinventory?${this.dateFrom}`;   
    return this.http.getData(url);
  }

  isInventoryDifferentBaseOils(localInventory1, localInventory2, localInventory3, localInventory4, localInventory5, plantName, category, scenario): Observable<any> {
    const url = `${environment.vcoPublishHistoryUrl}/${localInventory1}/${localInventory2}/${localInventory3}/${localInventory4}/${localInventory5}/${plantName}/${category}/${scenario}/localvsbackendinventorybaseoils?${this.dateFrom}`;   
    return this.http.getData(url);
  }

  getSnapshots(): Observable<any> {
    const url = environment.vcoSnapshotUrl;
    return this.http.getData(url);
  }

  saveData(obj, data): Observable<any> {    
    const url = `${environment.vcoSaveSnapshotV2Url}/${obj.plantName}/${obj.category}/${obj.scenario}`;    
    return this.http.postData(url, data);
  }

  publishData(data,product?): Observable<any> {
    data.currentDate = new Date();
    data.currentDate = data.currentDate.toLocaleString('en-US', {
      hour12: false,
    });
    const url = `${environment.vcoPublishSnapshotUrl}/${product}`;
    return this.http.postData(url,data);
  }
  
  getGraphData(): Observable<any> {
    const url = `${environment.marketElasticityV2Url}/PASCAGOU/charts`;
    return this.http.getData(url);
  }

  getGrapEseData(): Observable<any> {
    const url = `${environment.marketElasticityV2Url}/ELSEGUND/charts`;
    return this.http.getData(url);
  }

  getGraphDataGasOils(): Observable<any> {
    const url = `${environment.marketElasticityV2Url}/PASCAGOU/graphGasoils`;
    return this.http.getData(url);
  }

  getGraphRicData(): Observable<any> {
    const url = `${environment.marketElasticityV2Url}/RICHMOND/charts`;
    return this.http.getData(url);
  }

  getGraphDataBaseOils(plantName): Observable<any> {
    const url = `${environment.marketElasticityV2Url}/${plantName}/graphbaseoils`;
    return this.http.getData(url);
  }

  getUserName() {
    //console.log("this.homeService.userProfile", this.homeService.userProfile);
    return (      
      this.homeService.userProfile.givenName +
      ' ' +
      this.homeService.userProfile.name.split(',')[0]
    );
  }

  getMKTAdhocRefreshStatus(data): Observable<any> {
    const url = `${environment.marketElasticityUrl}/DataRefresh/Start`;
    return this.http.postData(url,data);
  }
}
