
<div class="nomination-details-wrapper">
  <ag-grid-angular
    style="width: 100%; height: 100%;"
    [overlayLoadingTemplate]="loader"
    class="ag-theme-balham"
    [masterDetail]="true"
    [isRowMaster]="isRowMaster"
    [columnDefs]="columnDefs"
    [defaultColDef]="defaultColDef"
    [detailCellRendererParams]="detailCellRendererParams"
    [rowData]="gridData"
    (firstDataRendered)="onFirstDataRendered($event)"
    (gridReady)="onGridReady($event)"
    (rowSelected)="onRowSelected($event)"
  ></ag-grid-angular>
</div>