import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import {LicenseManager} from "ag-grid-enterprise";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

LicenseManager.setLicenseKey("CompanyName=Insight_on_behalf_of_Chevron Pipe Line Company,LicensedGroup=Enterprise ag-Grid license,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=5,LicensedProductionInstancesCount=0,AssetReference=AG-022936,ExpiryDate=11_January_2023_[v2]_MTY3MzM5NTIwMDAwMA==af5be19186fabe30a955503ccd46df65");

