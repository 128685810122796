import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dateformat',
  templateUrl: './dateformat.component.html',
  styleUrls: ['./dateformat.component.scss'],
})
export class DateformatComponent {
  params;
  constructor() {
     // This is intentional
  }

  agInit(params: any): void {
    this.params = params;
  }
}
