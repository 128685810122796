import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './common/http.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsService {
  dataRefreshSubject = new Subject();
  showSettingsSubject = new BehaviorSubject(true);

  constructor(private http: HttpService) {}

  saveSettings(data, configData: any): Observable<any> {
    const url = `${environment.marketElasticityV2Url}/configelasticity/${configData.plantName}/${configData.scenario}`;
    return this.http.postData(url, data);
  }
  
  getDataRefreshed(key) {
    this.dataRefreshSubject.next(key);
  }

  showSettingsBtn(bool: boolean) {
    this.showSettingsSubject.next(bool);
  }

}
