import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { CalAngularService, ICvxClaimsPrincipal} from '@cvx/cal-angular';
//import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { EventMessageUtils, EventMessage, EventType } from '@azure/msal-browser';
import { EventService } from './services/common/event.service';
import { HomeService } from './services/home.service';
import { filter } from 'rxjs/operators';
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { AudTokenService } from './services/aud-token.service';

type ProfileType = {
  givenName?: string,
  surname?: string,
  userPrincipalName?: string,
  id?: string
};


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy, OnInit {
  title = 'app';
  showHeader = true;
  profileLoaded: Boolean = false;  
  subscription;

  constructor(
    //private broadcastService: MsalBroadcastService,
    private authService: CalAngularService,
    private router: Router,
    private event: EventService,
    private homeService: HomeService,
    private matIconRegistry: MatIconRegistry,
    private audTokenSr: AudTokenService,
    private domSanitizer: DomSanitizer
  ) 
  {
    this.matIconRegistry.addSvgIcon(
      `export`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/export.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `upload`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/upload.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `autorenew`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/autorenew.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `cloud_queue`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/cloud_queue.svg")
    );
    this.matIconRegistry.addSvgIcon(
      `add_circle_outline`,
      this.domSanitizer.bypassSecurityTrustResourceUrl("../assets/images/add_circle_outline.svg")
    );
    this.login();
  }
  currentUserProfile! : ICvxClaimsPrincipal;
  loggedIn : boolean = false;

  ngOnInit(): void {
    // this.subscription = this.broadcastService.msalSubject$
    //                         .subscribe((msg: EventMessage) => {
    //                           if(msg.eventType == EventType.ACQUIRE_TOKEN_SUCCESS){                              
    //                             if(this.profileLoaded === false){
    //                               this.getProfile();
    //                               this.profileLoaded = true;
    //                             }                                
    //                           }
                              
    //                         } );
    this.getToken([environment.audience + '/user_impersonation']);
    this.getProfile();
  }

  login() {
    this.authService.userInitiatedSignIn()
    .subscribe({
      next: (user: any) => {
      this.loggedIn = user?.isUser;
      if (this.loggedIn) {
        this.currentUserProfile = this.authService.cvxClaimsPrincipal;
        let userCai = this.currentUserProfile.cai;
        console.log(`Welcome ${userCai}`, this.currentUserProfile)
        //this.getToken([environment.audience + '/user_impersonation']);
        //this.getProfile();
        this.profileLoaded = true;
      }
      else {
        console.log("user not yet signed in");
      }
      },error: (error) => {
        console.log(error);
      },complete: ()=> {
        console.log('Login Completed');
      }
    })
    
  }

  private getToken(scopes: string[])
  {
    this.authService.getAADToken(scopes, false)
      .subscribe({
        next: (response) =>{
          const token = response?response:'';
          this.audTokenSr.setAudToken(token);
          //console.log("token: " + token);
        },
        error: (err)=>{
          console.log('Unable to create audience token');
          console.log(err);
        }
      });
  }

  getProfile() {
    // this.homeService.getProfile().subscribe((data) => {
    //   this.homeService.userProfile = data;
    //   console.log("data:", data);
    //   this.event.newEvent({ name: 'profile', userProfile: data });
    //   this.profileLoaded = true;
    // });
    this.homeService.userProfile = this.authService.cvxClaimsPrincipal;
    //console.log("data:", this.authService.cvxClaimsPrincipal);
    this.event.newEvent({ name: 'profile', userProfile: this.authService.cvxClaimsPrincipal });
    this.profileLoaded = true;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
