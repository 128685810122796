import { Component, Input, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/common/event.service';

@Component({
  selector: 'app-searchbox',
  templateUrl: './searchbox.component.html',
  styleUrls: ['./searchbox.component.scss'],
})
export class SearchboxComponent implements OnInit {
  @Input() searchText = '';
  constructor(private event: EventService) {}

  ngOnInit() {
     // This is intentional
  }
  onValueChange(value) {
    this.event.newEvent({ name: 'search', value });
  }

  onValueChanging(value) {
    if (value === '') {
      this.event.newEvent({ name: 'search', value });
    }
    this.event.newEvent({ name: 'search', value });
  }
}
