<div [ngClass]="isHighlighted ? 'highlightedCell' : ''">
  <span
    style="width: 100%"
    *ngIf="params.colDef.type === 'date'; else other_content"
    >{{ params.value | date: 'mediumDate' }}</span
  >
  <ng-template #other_content>
    <span style="width: 100%">{{
      params.colDef.type === 'rightAligned'
        ? (params.value | number: '1.0-0')
        : params.value
    }}</span>
  </ng-template>
  <i
    *ngIf="params.isDropdown && params.value"
    class="fas fa-caret-down align-icon"
    data-toggle="dropdown"
  ></i>
</div>
