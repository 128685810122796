class LcoState {
  constructor() {
     // This is intentional
  }
  searchText = '';
  maxShipment = 50;
  filteredProps = {};
  columnState: any[] = [];
}

export { LcoState };
