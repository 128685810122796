import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { from } from 'rxjs';
import { NotificationService } from 'src/app/services/common/notification.service';
import { SettingsService } from 'src/app/services/settings.service';
declare var $: any;

@Component({
  selector: 'app-base-oil-settings',
  templateUrl: './base-oil-settings.component.html',
  styleUrls: ['./base-oil-settings.component.css']
})
export class BaseOilSettingsComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private service: SettingsService,
    private notification: NotificationService
  ) { }

  @Input() constants: any;
  @Input() configData: any;
  @Output() saveEvent = new EventEmitter();
  @Input() readOnly: boolean;

  baseOilsSettingsForm: FormGroup;
  groups: any;
  controls: any;
  defaultFrom = new Date();
  defaultTo = new Date(this.defaultFrom.getTime() + 2 * 24 * 60 * 60 * 1000);

  ngOnInit() {
    this.initLoad();
    this.populateForm();
    console.log("BaseOilSettingsComponent -> readOnly: " + this.readOnly);
  }

  buildPayload() {
    const keys = Object.keys(this.baseOilsSettingsForm.value);
    const form = this.baseOilsSettingsForm.value;
    let payload = [];
    if(window.sessionStorage.getItem('loginRefinery') == 'richmond' ){
      keys.forEach((ele, index) => {
        const obj = {
          scenarioName: this.getName(ele).split(" ").join(""),
          opsMin: form[ele]['opsMin'],
          opsMax: form[ele]['opsMax'],
          minESL: this.constants[index]['minESL'],
          maxESL: this.constants[index]['maxESL'],
          minTargetInventory: form[ele]['minTargetInventory'],
          maxTargetInventory: form[ele]['maxTargetInventory'],
          targetInventory: this.constants[index]['targetInventory']
        }
        payload.push(obj)
      });
    }
    else{
      keys.forEach((ele, index) => {
        const obj = {
          scenarioName: this.getName(ele).split(" ").join(""),
          opsMin: form[ele]['opsMin'],
          opsMax: form[ele]['opsMax'],
          minESL: this.constants[index]['minESL'],
          maxESL: this.constants[index]['maxESL'],
          minTargetInventory: form[ele]['minTargetInventory'],
          maxTargetInventory: form[ele]['maxTargetInventory'],
          hurricaneOpsMin: form[ele]['hurricaneOpsMin'],
          targetInventory: this.constants[index]['targetInventory'],
          hurricaneTimePeriodStart: this.getFormattedDate(form[ele]['hurricaneTimePeriod']['startDate']),
          hurricaneTimePeriodEnd: this.getFormattedDate(form[ele]['hurricaneTimePeriod']['endDate'])
        }
        payload.push(obj)
      });
    }

    return payload;
  }

  initLoad() {      

    if(window.sessionStorage.getItem('loginRefinery') == 'richmond' ){

      let formControls = {
        opsMin: '',
        opsMax: '',
        minTargetInventory: '',
        maxTargetInventory: ''
      } 

      this.baseOilsSettingsForm = this.fb.group({
        group100R: this.fb.group(formControls),
        group220R: this.fb.group(formControls),
        group600R: this.fb.group(formControls),
        groupNB3060: this.fb.group(formControls),
        groupNB3043: this.fb.group(formControls),
      });

      this.groups = Object.keys(this.baseOilsSettingsForm.controls);
      this.controls = Object.keys(formControls);
    }
    else
    {
      let formControls = {
        opsMin: '',
        opsMax: '',
        minTargetInventory: '',
        maxTargetInventory: '',
        hurricaneOpsMin: '',
        hurricaneTimePeriod: {
          startDate: '',
          endDate: ''
        }
      } 

      this.baseOilsSettingsForm = this.fb.group({
        group60R: this.fb.group(formControls),
        group100R: this.fb.group(formControls),
        group220R: this.fb.group(formControls),
        group600R: this.fb.group(formControls),
      });

      this.groups = Object.keys(this.baseOilsSettingsForm.controls);
      this.controls = Object.keys(formControls);
    }

    
  }

  populateForm() {     

    if(window.sessionStorage.getItem('loginRefinery') == 'richmond' ){
      this.constants.forEach(constant => {      
        this.baseOilsSettingsForm.controls[`group${constant['scenarioName']}`].setValue({
          opsMin: constant['opsMinAct'],
          opsMax: constant['opsMax'],
          minTargetInventory: constant['targetMin'],
          maxTargetInventory: constant['targetMax']
        });
      })
    }
    else{
      this.constants.forEach(constant => {      
        this.baseOilsSettingsForm.controls[`group${constant['scenarioName']}`].setValue({
          opsMin: constant['opsMinAct'],
          opsMax: constant['opsMax'],
          minTargetInventory: constant['targetMin'],
          maxTargetInventory: constant['targetMax'],
          hurricaneOpsMin: constant['hurricaneOpsMin'],
          hurricaneTimePeriod: {
            startDate: new Date(constant['hurricaneTimePeriodStart']),
            endDate: new Date(constant['hurricaneTimePeriodEnd'])
          }
        });
      });
    }
    
  }

  saveBaseOilSettings() {
    this.notification.info('Saving...');
    this.service.saveSettings(this.buildPayload(), this.configData)
    .subscribe(res => {
      this.notification.success();
      this.service.getDataRefreshed('table');
      $('#settings-modal').modal('hide');
    }, err => {
      this.notification.error();
    })
  }

  getName(name: string) {   

    if(window.sessionStorage.getItem('loginRefinery') == 'richmond' ){
      let names = {
        group100R: "100 R",
        group220R: "220 R",
        group600R: "600 R",
        groupNB3060: "NEXBASE 3060",
        groupNB3043: "NEXBASE 3043",
      }

      return names[name];
    }
    else{
      let names = {
        group60R: "60 R",
        group100R: "100 R",
        group220R: "220 R",
        group600R: "600 R",
      }

      return names[name];

    }

    
  }

  get f() {
    return this.baseOilsSettingsForm.controls;
  }

  getFormattedDate(selectedDate:Date) {
    const yr = selectedDate.getFullYear();
    const month = (selectedDate.getMonth() + 1);
    const date = selectedDate.getDate();
    return `${yr}-${month}-${date}`;
  }

  public onDateRangeSelection(range: { from: Date, to: Date }, groupName) {
    this.baseOilsSettingsForm.controls[groupName].patchValue({
      hurricaneTimePeriod: {
        startDate: new Date(range.from),
        endDate: new Date(range.to)
      }
    })
    
  }

}