import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MultiNominationsViewComponent } from './multi-nominations-view/multi-nominations-view.component';
import { SharedModule } from '../shared/shared.module';
import { NominationsSearchComponent } from './nominations-search/nominations-search.component';
import { NominationsDetailsComponent } from './nominations-search/nomination-details/nomination-details.component';
import { AgGridModule } from 'ag-grid-angular';


@NgModule({
  declarations: [
    MultiNominationsViewComponent,
    NominationsSearchComponent,
    NominationsDetailsComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AgGridModule.withComponents([
      NominationsDetailsComponent
    ]),
  ],
  exports: [
    MultiNominationsViewComponent,
    NominationsSearchComponent,
    NominationsDetailsComponent
  ]
})
export class NominationsManagerModule { }
