export default {
  APP_HEADER_NAME: 'Distribution Optimizer',
  RECENT_CASE: 'My Recent Cases',
  PUBLISHED_CASE: 'Published Cases',
  HOME_BUTTON_1: "TODAY'S BASE CASE",
  HOME_BUTTON_2: 'NEW CASE',
  HOME_BUTTON_3: 'ADMIN',
  HOME_BUTTON_4: 'REPORT',
  SEARCH_DELIMITER: ',',
  INPUT_SCREENS: {
    REFINERY: 'Refinery',
    DOMESTIC_CARGO: ' Domestic Cargo',
    INTERNATIONAL_CARGO: 'International Cargo',
    PIPELINE_LIFTINGS: 'Pipeline Liftings',
    CONTRACTS: ' Contract',
    STORAGE_TANKS: ' Storage Tanks',
    DOCK_AVAILABILITY: ' Dock Availability',
  },
};
