import { Component, OnInit } from '@angular/core';
import { EventService } from 'src/app/services/common/event.service';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss'],
})
export class PanelComponent implements OnInit {
  zoomStyle;
  constructor(private event: EventService) {
    this.event.events$.subscribe((payload) => {
      if (payload.zoom) {
        this.onZoomChange(payload.zoom);
      }
    });
  }

  ngOnInit() {
     // This is intentional
  }
  onZoomChange(props) {
    this.zoomStyle = (props.value / 100).toString();
  }
}
