<div class="icons">

  <a title="Refresh" (click)="optimizerAction('refresh')">
    <i class="fa fa-redo cursor" aria-hidden="true"></i>
  </a>

  <a title="View Case" (click)="optimizerAction('view')">
    <i class="fa fa-eye cursor" aria-hidden="true"></i>
  </a>
   
  <a title="View Reports" (click)="optimizerAction('report')" [ngClass]="reportAvailable? '' : 'adisabled'">  
    <i class="fas fa-flag-checkered cursor" aria-hidden="true"></i>
  </a> 
</div>
