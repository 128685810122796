import { Component } from '@angular/core';

@Component({
  selector: 'app-case-action',
  templateUrl: './case-action.component.html',
  styleUrls: ['./case-action.component.scss'],
})
export class CaseActionComponent {
  params;
  constructor() {
     // This is intentional
  }

  agInit(params: any): void {
    this.params = params;
  }

  caseAction(action) {
    this.params.caseAction(action, this.params);
  }
}
