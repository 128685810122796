import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class OptimizerService {
  userProfile;
  api_url: string = "api/optimizer/";
  optimizerapi_url: string = "api/caseruns/startrun/";
  runStatusApi_url: string = "api/caseruns/status/";

  constructor(private http: HttpService) { }

  run(id: number): Observable<any> {
    const url = this.api_url + "run/" + id;
    return this.http.postData(url, { id });
  }

  getRunStatus(caseRunId: number): Observable<any> {
    const url = this.runStatusApi_url + caseRunId;
    return this.http.getData(url);
  }

  runOptimizer(userCaseId: number, initiatedBy: string): Observable<any> {
    const url = this.optimizerapi_url;
    return this.http.postData(url, {userCaseId, initiatedBy});
  }
}