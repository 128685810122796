import { Component } from '@angular/core';

@Component({
  selector: 'app-vessel-action',
  templateUrl: './vessel-action.component.html',
  styleUrls: ['./vessel-action.component.scss'],
})
export class VesselActionComponent {
  params;
  constructor() {
     // This is intentional
  }

  agInit(params: any): void {
    this.params = params;
  }

  vesselAction(action) {
    this.params.vesselAction(action, this.params);
  }
}
