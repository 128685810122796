import { Component, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { shareMKTDataService } from 'src/app/market-elasticity/common/market-elasticity-share-data';
import { NotificationService } from 'src/app/services/common/notification.service';
import { MarketElasticityService } from 'src/app/services/market-elasticity.service';
import { MatStep, MatStepper } from '@angular/material/stepper';

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [{
    provide: STEPPER_GLOBAL_OPTIONS, useValue: { displayDefaultIndicatorType: false }
  }]
})

export class StepperComponent {
  @ViewChild('stepper') stepper: MatStepper;
  @ViewChild('inventory') inventory: MatStep;
  @ViewChild('production') production: MatStep;
  @ViewChild('nomination') nomination: MatStep;

  showProgress: boolean = false;

  inventoryStatus: string = "disable";
  productionStatus: string = "disable";
  nominationStatus: string = "disable";
  refreshEndsStatus: string = "disable";

  percentage: string = "percentage successcolor";
  status: string = "status successcolor";
  refreshPercentage: string = "";
  refreshStatus: string = "refreshing";
  statusIcon: string = "autorenew";
  rotateRefresh: string = "refresh-start successcolor";

  inventoryMessage: string = "";
  productionMessage: string = "";
  nominationMessage: string = "";

  constructor(private _formBuilder: FormBuilder,
    private mktShareDataService: shareMKTDataService,
    private notification: NotificationService,
    private marketService: MarketElasticityService) {
  }

  ngOnInit() {
    this.mktShareDataService.dataRefresh.subscribe(response => {
      if (response != null) {
        this.showProgress = response;
        this.resetStepperStatus();
      }
    });

    this.mktShareDataService.getAdhocRefreshData().subscribe((response) => {
      console.log(response);
      if (response != null) {
        let objInven : any;
        let objNomi : any;
        let objProd  : any;
       
        response.forEach(obj => {
          let typeVal: string = obj.type;
          if (typeVal === "Opening Inventory Data") { objInven = obj;}
          else if (typeVal === "Production Data") {  objProd = obj;}
          else if (typeVal === "Nominations Data") { objNomi = obj;}
        });
       
        this.inventoryMessage = objInven.message;
        this.productionMessage = objProd.message;
        this.nominationMessage = objNomi.message;

        //Set Inventory status
        if (objInven.status == 'CompletedSuccessfully') {
          this.inventoryStatus = "done";
        } else if (objInven.status == 'Failed') {
          this.inventoryStatus = "error";          
        } else if (objInven.status == 'PartialSuccess') {
          this.inventoryStatus = "partial";
        }

        //Set Production status
        if (objProd.status == 'CompletedSuccessfully') {
          this.productionStatus = "done";
        } else if (objProd.status == 'Failed') {
          this.productionStatus = "error";
        } else if (objProd.status == 'PartialSuccess') {
          this.productionStatus = "partial";
        }

        //Set Nomination status
        if (objNomi.status == 'CompletedSuccessfully') {
          this.nominationStatus = "done";
        } else if (objNomi.status == 'Failed') {
          this.nominationStatus = "error";
        } else if (objNomi.status == 'PartialSuccess') {
          this.nominationStatus = "partial";
        }

        if (objInven.status == 'CompletedSuccessfully' && objProd.status == 'CompletedSuccessfully' && objNomi.status == 'CompletedSuccessfully') {
          this.refreshEndsStatus = "done";
          this.refreshPercentage = "100 %";
          this.refreshStatus = "refresh completed";
          this.statusIcon = "info";
          this.percentage = "percentage successcolor";
          this.status = "status successcolor";
          this.rotateRefresh = "successcolor";

          setTimeout(function () {
            this.showProgress = false;
          }.bind(this), 10000);

        } else {
          this.refreshEndsStatus = "error";
          this.refreshPercentage = "";
          this.refreshStatus = "refresh failed";
          this.statusIcon = "error";
          this.percentage = "percentage errorcolor";
          this.status = "status errorcolor";
          this.rotateRefresh = "errorcolor";
        }
      }
    });
  }

  resetStepperStatus() {
    this.inventoryStatus = "disable";
    this.productionStatus = "disable";
    this.nominationStatus = "disable";
    this.refreshEndsStatus = "disable";

    this.percentage = "percentage successcolor";
    this.status = "status successcolor";
    this.refreshPercentage = "";
    this.refreshStatus = "refreshing";
    this.statusIcon = "autorenew";
    this.rotateRefresh = "refresh-start successcolor";

    this.inventoryMessage = "";
    this.productionMessage = "";
    this.nominationMessage = "";
  }

  ShowStatusMessage(arg) {
    let objStat = null;
    if (arg == 'inv') {
      objStat = {
        type: "inventory",
        errMsg: this.inventoryMessage
      };

      if (this.inventoryStatus == "error" || this.inventoryStatus == "partial")
        this.notification.status(objStat);
        this.inventory.reset();

    } else if (arg == 'prod') {
      objStat = {
        type: "production",
        errMsg: this.productionMessage
      };

      if (this.productionStatus == "error" || this.productionStatus == "partial")
        this.notification.status(objStat);
        this.production.reset();
    } else if (arg == 'nomi') {
      objStat = {
        type: "nominations",
        errMsg: this.nominationMessage
      };

      if (this.nominationStatus == "error" || this.nominationStatus == "partial")
        this.notification.status(objStat);
        this.nomination.reset();
    }
  }
}