import { Component, NgModule } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { EventService } from 'src/app/services/common/event.service';

@Component({
  selector: 'app-zoomer',
  templateUrl: './zoomer.component.html',
  styleUrls: ['./zoomer.component.scss'],
})
export class ZoomerComponent {
  zoomerProps = { value: 100, minValue: 50, maxValue: 150 };
  constructor(private router: Router, private event: EventService) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.zoomerProps.value = 100;
      }
    });
  }

  onChange(val) {
    this.zoomerProps.value = val;
    this.event.newEvent({ zoom: this.zoomerProps });
  }

  resetColumns() {
    this.event.newEvent({ reset: true });
  }
}