export class CaseModel {
  id?: number;
  title: string;
  status: string;
  description: string;
  baseCaseDateString: string;
  createdBy: string;
  isCopy: boolean;
  copyTitle: string;
  reset(
    dateString,
    id,
    title,
    status,
    description,
    createdBy,
    isCopy,
    copytitle
  ) {
    this.id = id;
    this.title = title;
    this.status = status;
    this.description = description;
    this.baseCaseDateString = dateString;
    this.createdBy = createdBy;
    this.isCopy = isCopy;
    this.copyTitle = copytitle;
  }
}
