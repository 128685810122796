import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { EventService } from 'src/app/services/common/event.service';
import { HomeService } from 'src/app/services/home.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
})
export class NavMenuComponent {
  isExpanded = true;
  displayName;
  loginedRefinery;
  constructor(public router: Router, private event: EventService, private homeService: HomeService) {

    this.event.events$.subscribe((payload) => {
      //console.log("payload:", payload);
      if (payload.userProfile) {
        this.displayName = payload.userProfile.name;
      }
      this.loginedRefinery = window.sessionStorage.getItem('loginRefinery');      
    });

    this.homeService.getLoginRefinery().subscribe((data) => {
      if (data != undefined && data != "") {
        this.loginedRefinery = data;
      }
    });
  }

  toggleSideBar() {
    this.isExpanded = document.getElementById('mySidebar')['opened'];

    if (!this.isExpanded) {
      document.getElementById('mySidebar').style.width = '100%';
      document
        .getElementById('grid-panel')
        .classList.remove('col-lg-12', 'pl-5');
      document.getElementById('mySidebar')['opened'] = true;
    } else {
      document.getElementById('mySidebar').style.width = '0px';
      document.getElementById('grid-panel').classList.add('col-lg-12', 'pl-5');
      document.getElementById('mySidebar')['opened'] = false;
    }
  }
}
