import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { StateComponent } from 'src/app/core/StateComponent';
import { AgHelper } from 'src/app/services/agHelper';
import { EventService } from 'src/app/services/common/event.service';

import { NotificationService } from 'src/app/services/common/notification.service';
import { HomeService } from 'src/app/services/home.service';
import { TabSection } from 'src/app/services/model/TabSection';
import { OptimizerService } from 'src/app/services/optimizer.service';
import { PipelineState } from 'src/app/services/States/case-input-screens/pipelineState';
import { TransportationService } from 'src/app/services/transportation.service';
import { OverlayComponent } from 'src/app/shared/common/overlay/overlay.component';
import { CellHighlighterComponent } from 'src/app/shared/grid-components/cell-highlighter/cell-highlighter.component';
import { DropdownEditorComponent } from 'src/app/shared/grid-components/dropdown-editor/dropdown-editor.component';
import { DropdownComponent } from 'src/app/shared/grid-components/dropdown/dropdown.component';
import appConstant from '../../app.constants';
import { IPipelineDataToSave, IVesselDataToSave } from '../transportation/transportation.model';
declare var $: any;
@Component({
  selector: 'app-transportation',
  templateUrl: './transportation.component.html',
  styleUrls: ['./transportation.component.scss'],
})
export class TransportationComponent
  extends StateComponent<PipelineState>
  implements OnInit, OnDestroy {
  columnDefs = [];
  rowData = [];
  agHelper = AgHelper;
  gridApi;
  sideBar;
  gridColumnApi;
  gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      filter: true,
      enableRowGroup: true,
    },
    frameworkComponents: {
      cellHighlighterComponent: CellHighlighterComponent,
      overlayComponent: OverlayComponent,
      dropdownComponent: DropdownComponent,
      dropdownEditorComponent: DropdownEditorComponent,
    },
    components: {
      datePicker: this.agHelper.getDatePicker(),
    },
    autoGroupColumnDef: {
      cellRendererParams: {
        suppressCount: true,
      },
    },
    groupDefaultExpanded: -1,
    groupMultiAutoColumn: true,
    groupIncludeFooter: true,
    groupIncludeTotalFooter: false,
    enableRangeSelection: true,
    enableFillHandle: true,
  };
  subscription: Subscription;
  pipelineDataToSave: IPipelineDataToSave[] = [];
  vesselDataToSave: IVesselDataToSave[] = [];
  dataToSave: boolean;
  id;
  overlayComponent = 'overlayComponent';
  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'center',
      },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };
  viewAction = {
    handleSearchChange: (data: any) => {
      this.setState({ searchText: data });
    },
    onFilterChanged(filterEvent) {
      const filters = filterEvent.api.filterManager.getFilterModel();
      this.setState({ filteredProps: filters }, true);
    },
    onColumnStateChanged(_event) {
      if (this.gridColumnApi) {
        const columnStates = this.gridColumnApi.getColumnState();
        this.setState({ columnState: columnStates }, true);
      }
    },
    onResetFilter: () => {
      this.clearGridHandlers();
      this.resetState();
      this.attachGridHandlers();
    },
  };
  liftingsData = {
    domestic: [],
    international: [],
    pipeline: [],
  };

  tabName;
  filteredData = [];
  screenName = '';
  constructor(
    private transportationService: TransportationService,
    private notification: NotificationService,
    private homeService: HomeService,
    private optimizerService: OptimizerService,
    private router: Router,
    private route: ActivatedRoute,
    private eventService: EventService
  ) {
    super();
    this.initState(TabSection.PipelineInputScreen);
    this.sideBar = this.agHelper.getFilterPanelConfig();
    this.subscription = this.eventService.events$.subscribe((event) => {
      switch (event.name) {
        case 'search':
          this.viewAction.handleSearchChange(event.value);
          break;
      }
    });
  }
  ngOnInit() {
    this.route.url.subscribe((url) => {
      this.tabName = url[0].path;
      this.getColumns();
      switch (this.tabName) {
        case 'domestic':
          this.screenName = appConstant.INPUT_SCREENS.DOMESTIC_CARGO;
          this.initState(TabSection.DomesticCargoInputScreen);
          break;
        case 'international':
          this.screenName = appConstant.INPUT_SCREENS.INTERNATIONAL_CARGO;
          this.initState(TabSection.IntCargoInputScreen);
          break;
        case 'pipeline':
          this.screenName = appConstant.INPUT_SCREENS.PIPELINE_LIFTINGS;
          this.initState(TabSection.PipelineInputScreen);
          break;
        default:
          this.screenName = 'Liftings';
      }
    });
    this.id = window.sessionStorage.getItem('caseID');
    this.getData();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
  }

  getData() {
    this.clearGridHandlers();
    switch (this.tabName) {
      case 'domestic':
        this.transportationService.getGetVesselNomsData(this.id).subscribe((data) => {
          this.liftingsData.domestic = data.data.filter(
            (payload) =>
              payload.usFlag.value == '1'
          );
          this.rowData = this.liftingsData[this.tabName];
          this.setColumnState(this.state);
          this.attachGridHandlers();
          setTimeout(() => {
            this.filterBySearch(this.state.searchText);
          }, 0);
        });
        break;
      case 'international':
        this.transportationService.getGetVesselNomsData(this.id).subscribe((data) => {
          this.liftingsData.international = data.data.filter(
            (payload) =>
              payload.usFlag.value !== '1'
          );
          this.rowData = this.liftingsData[this.tabName];
          this.setColumnState(this.state);
          this.attachGridHandlers();
          setTimeout(() => {
            this.filterBySearch(this.state.searchText);
          }, 0);
        });
        break;
      case 'pipeline':
        this.transportationService.getGetPipelineNomsData(this.id).subscribe((data) => {
          this.liftingsData.pipeline = data.data;
          this.rowData = this.liftingsData[this.tabName];
          this.setColumnState(this.state);
          this.attachGridHandlers();
          setTimeout(() => {
            this.filterBySearch(this.state.searchText);
          }, 0);
        });
        break;
      default:
        this.screenName = 'Liftings';
    }
  }

  getColumns() {
    const columns = this.transportationService.getColumnData(this.tabName);
    const options = this.transportationService.getProductOptions();
    forkJoin([columns, options]).subscribe((payload) => {
      this.columnDefs = payload[0];
      const productOptions = payload[1].data.map(
        (option) => option.productName
      );
      this.mapFilterToColumns();
      const productCol = this.columnDefs.filter(
        (x) => x.field === 'product'
      )[0];

      productCol.cellEditorParams = {
        values: [...productOptions],
      };

      productCol.cellRendererParams = {
        isDropdown: true,
      };
      productCol.valueFormatter = (params) =>
        params.value && params.value.value;
    });
  }
  handleMultipleClick(event) {
    switch (event) {
      case 'save':
        this.onSaveData();
        break;
      case 'export':
        this.exportAsExcel();
        break;
      case 'optimize':
        if (this.canDeactivate())
          this.runOptimizer(this.id, this.homeService.getUserName());
        break;
      default:
        break;
    }
  }

  onCellValueChanged(params) {
    let node = params.node.data;
    if (this.tabName != 'pipeline') {
      if (params.colDef.field === 'dateDelivered') {
        const dateDelivered = new Date(params.value);
        const deliveryLaycanEnd = new Date(dateDelivered.getTime() + 86400000 * 2); // +2 day in ms
        params.data[
          'deliveryLaycanEnd'
        ].value = deliveryLaycanEnd.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        params.node.setDataValue(
          'deliveryLaycanEnd',
          params.data['deliveryLaycanEnd'].value
        );
        params.node.setDataValue('deliveryLaycanBegin', params.value);
      } else if (params.colDef.field === 'dateLoad') {
        const dateLoad = new Date(params.value);
        const loadLaycanEnd = new Date(dateLoad.getTime() + 86400000 * 1); // +2 day in ms
        params.data['loadLaycanEnd'].value = loadLaycanEnd.toLocaleDateString(
          'en-US',
          {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          }
        );
        params.node.setDataValue(
          'loadLaycanEnd',
          params.data['loadLaycanEnd'].value
        );
        params.node.setDataValue('loadLaycanBegin', params.value);
      }
      this.vesselUpdatesToSave(node);
    }
    else {
      this.pipelineUpdatesToSave(node);
    }
  }

  pipelineUpdatesToSave(node) {
    if (
      !this.pipelineDataToSave.find((data) => {
        return data.Id == node.pipelineNominationId.value;
      })
    ) {
      this.pipelineDataToSave.push({
        Id: node.pipelineNominationId.value,
        product: node.product.value != node.product.orgVal ? node.product.value : null,
        dateLoad: node.dateLoad.value != node.dateLoad.orgVal ? node.dateLoad.value : null,
        dateDelivered: node.dateDeliver.value != node.dateDeliver.orgVal ? node.dateDeliver.value : null,
        quantity: node.quantity.value != node.quantity.orgVal ? node.quantity.value : null,
      });
    } else {
      const objIndex = this.pipelineDataToSave.findIndex(
        (obj) => obj.Id == node.pipelineNominationId.value
      );
      this.pipelineDataToSave[objIndex].product = node.product.value != node.product.orgVal ? node.product.value : null;
      this.pipelineDataToSave[objIndex].dateLoad = node.dateLoad.value != node.dateLoad.orgVal ? node.dateLoad.value : null;
      this.pipelineDataToSave[objIndex].dateDelivered = node.dateDeliver.value != node.dateDeliver.orgVal ? node.dateDeliver.value : null;
      this.pipelineDataToSave[objIndex].quantity = node.quantity.value != node.quantity.orgVal ? node.quantity.value : null;
    }
  }

  vesselUpdatesToSave(node) {
    if (
      !this.vesselDataToSave.find((data) => {
        return data.Id == node.vesselNominationId.value;
      })
    ) {
      this.vesselDataToSave.push({
        Id: node.vesselNominationId.value,
        product: node.product.value != node.product.orgVal ? node.product.value : null,
        dateLoad: node.dateLoad.value != node.dateLoad.orgVal ? node.dateLoad.value : null,
        dateDelivered: node.dateDelivered.value != node.dateDelivered.orgVal ? node.dateDelivered.value : null,
        loadLaycanBegin: node.loadLaycanBegin.value != node.loadLaycanBegin.orgVal ? node.loadLaycanBegin.value : null,
        loadLaycanEnd: node.loadLaycanEnd.value != node.loadLaycanEnd.orgVal ? node.loadLaycanEnd.value : null,
        deliveryLaycanBegin: node.deliveryLaycanBegin.value != node.deliveryLaycanBegin.orgVal ? node.deliveryLaycanBegin.value : null,
        deliveryLaycanEnd: node.deliveryLaycanEnd.value != node.deliveryLaycanEnd.orgVal ? node.deliveryLaycanEnd.value : null,
        amount: node.cargoVolume.value != node.cargoVolume.orgVal ? node.cargoVolume.value : null,
      });
    } else {
      const objIndex = this.vesselDataToSave.findIndex(
        (obj) => obj.Id == node.vesselNominationId.value
      );
      this.vesselDataToSave[objIndex].product = node.product.value != node.product.orgVal ? node.product.value : null;
      this.vesselDataToSave[objIndex].dateLoad = node.dateLoad.value != node.dateLoad.orgVal ? node.dateLoad.value : null;
      this.vesselDataToSave[objIndex].dateDelivered = node.dateDelivered.value != node.dateDelivered.orgVal ? node.dateDelivered.value : null;
      this.vesselDataToSave[objIndex].loadLaycanBegin = node.loadLaycanBegin.value != node.loadLaycanBegin.orgVal ? node.loadLaycanBegin.value : null;
      this.vesselDataToSave[objIndex].loadLaycanEnd = node.loadLaycanEnd.value != node.loadLaycanEnd.orgVal ? node.loadLaycanEnd.value : null;
      this.vesselDataToSave[objIndex].deliveryLaycanBegin = node.deliveryLaycanBegin.value != node.deliveryLaycanBegin.orgVal ? node.deliveryLaycanBegin.value : null;
      this.vesselDataToSave[objIndex].deliveryLaycanEnd = node.deliveryLaycanEnd.value != node.deliveryLaycanEnd.orgVal ? node.deliveryLaycanEnd.value : null;
      this.vesselDataToSave[objIndex].amount = node.cargoVolume.value != node.cargoVolume.orgVal ? node.cargoVolume.value : null;
    }
  }

  onSaveData() {
    if (this.tabName == 'pipeline') {
      if (this.pipelineDataToSave.length === 0) {
        this.notification.warn('No data to save');
        return;
      }
      this.notification.info('Saving...');
      this.transportationService
        .savePipelineData({ data: this.pipelineDataToSave }, this.id)
        .subscribe(
          (_data) => {
            this.pipelineDataToSave = [];
            this.notification.success();
            this.gridApi.undoRedoService.clearStacks();
          },
          (_err) => {
            this.notification.error();
          }
        );
    }
    else {
      if (this.vesselDataToSave.length === 0) {
        this.notification.warn('No data to save');
        return;
      }
      this.notification.info('Saving...');
      this.transportationService
        .saveVesselData({ data: this.vesselDataToSave }, this.id)
        .subscribe(
          (_data) => {
            this.vesselDataToSave = [];
            this.notification.success();
            this.gridApi.undoRedoService.clearStacks();
          },
          (_err) => {
            this.notification.error();
          }
        );
    }
  }


  runOptimizer(id: number, userName: string) {
    if (confirm('Are you sure you want to schedule an optimizer run?')) {
      this.optimizerService
        .runOptimizer(id, userName)
        .subscribe((_payload) => {
          this.notification.success(
            'Optimizer run has been scheduled successfully'
          );
          this.router.navigate(['optimizer-runs']);
        },
        (err) => {
          this.notification.error(err);
        }
        );
    }
  }

  canDeactivate(): Observable<Boolean> | Promise<boolean> | boolean {
    if (this.tabName == 'pipeline') {
      if (this.pipelineDataToSave.length > 0)
        this.dataToSave = true;
    }
    else {
      if (this.vesselDataToSave.length > 0)
        this.dataToSave = true;
    }
    if (this.dataToSave) {
      window.alert(
        'There are unsaved changes on this page. please save to continue with optimizer'
      );      
      return false;
    } else {
      return true;
    }
  }

  mapFilterToColumns() {
    this.columnDefs.forEach((col) => {
      if (
        [
          'dateLoad',          
        ].includes(col.field)
      ) {
        col.filter = 'agDateColumnFilter';
        col.filterParams = this.agHelper.getDateFilter();
      }      

      if (col.field === 'amount') {
        col.cellStyle = (params) => {
          let borderClass;
          if (params.node.footer) {
            borderClass = { fontWeight: 'bold', textAlign: 'right' };
          } else {
            borderClass = { textAlign: 'right' };
          }
          return borderClass;
        };
      }
    });
  }

  filterBySearch = (value) => {
    let collection = this.rowData;
    if (!collection) {
      return;
    }

    if (value && value != '') {
      collection = collection.filter(
        (item) =>
          this.agHelper.findSubstring(item.origin.value, value) ||
          this.agHelper.findSubstring(item.destination.value, value) ||
          this.agHelper.findSubstring(item.product.value, value)
      );
    }
    this.filteredData = collection;
    this.gridApi.setRowData(this.filteredData);
  };

  groupRowAggNodes(nodes) {
    let aggRow = {
      amount: 0,
    };

    nodes.forEach((node) => {
      let data = node.group ? node.aggData : node.data;

      const _amount =
        typeof data.amount === 'object' ? data.amount['value'] : data.amount;

      aggRow.amount += parseInt(_amount || 0);
    });

    return aggRow;
  }

  onStateChange(
    newValue: Partial<PipelineState>,
    _oldValue: Partial<PipelineState>
  ) {
    if (newValue.searchText || newValue.searchText.length === 0) {
      this.filterBySearch(newValue.searchText);
    }
  }

  setColumnState(newValue, _isColumnStateSet = true) {
    if (newValue.columnState && newValue.columnState.length > 0) {
      this.gridColumnApi.setColumnState(newValue.columnState);
    } else if (newValue.columnState && newValue.columnState.length === 0) {
      this.gridColumnApi.resetColumnState();
    }
  }
  attachGridHandlers() {
    const handler = this.viewAction.onColumnStateChanged.bind(this);
    const filterChangeHandler = this.viewAction.onFilterChanged.bind(this);
    setTimeout(() => {
      this.gridOptions['onFilterChanged'] = filterChangeHandler;
      this.gridOptions['onVirtualColumnsChanged'] = handler;
      this.gridOptions['onGridColumnsChanged'] = handler;
      this.gridOptions['onColumnValueChanged'] = handler;
      this.gridOptions['onDisplayedColumnsChanged'] = handler;
      this.gridOptions['onColumnGroupOpened'] = handler;
    }, 1000);
  }
  clearGridHandlers() {
    this.gridOptions['onFilterChanged'] = null;
    this.gridOptions['onVirtualColumnsChanged'] = null;
    this.gridOptions['onGridColumnsChanged'] = null;
    this.gridOptions['onColumnValueChanged'] = null;
    this.gridOptions['onDisplayedColumnsChanged'] = null;
    this.gridOptions['onColumnGroupOpened'] = null;
  }

  exportAsExcel() {
    const params = {
      fileName: 'Liftings',
      sheetName: this.tabName,
    };
    this.gridApi.exportDataAsExcel(params);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
