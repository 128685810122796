<app-mulitbutton
  (clickHandler)="handleMultipleClick($event)"
  [showCaseDetails]="true"
  [screenName]="screenName"
  [searchText]="state.searchText"
></app-mulitbutton>
<app-panel>
  <ag-grid-angular
    style="height: 100%; border: solid 1px"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions"
    [context]="agHelper"
    [undoRedoCellEditing]="true"
    [undoRedoCellEditingLimit]="5"
    [rowGroupPanelShow]="'always'"
    (cellValueChanged)="onCellValueChanged($event)"
    [loadingOverlayComponent]="overlayComponent"
  >
  </ag-grid-angular>
  <!--   <div style="z-index: 5; position: fixed; right: 10px; bottom: 50px">
    <button
      class="button"
      (click)="addBlankRow()"
      *ngIf="rowCount == 0; else remove"
    >
      <img src="../../../assets/images/Add_Icon.png" alt="Add_icon.png" />
    </button>
    <ng-template #remove
      ><button class="button" (click)="addBlankRow()">
        <img
          src="../../../assets/images/delete.jpg"
          alt="Add_icon.png"
          width="42"
          height="42"
        /></button
    ></ng-template>
  </div> -->
</app-panel>
