import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DateformatComponent } from './grid-components/dateformat/dateformat.component';
import {DatePickerComponent} from './common/datepicker-popup/datepicker-popup.component';
import { MulitbuttonComponent } from './common/mulitbutton/mulitbutton.component';
import { CellHighlighterComponent } from './grid-components/cell-highlighter/cell-highlighter.component';
import { OverlayComponent } from './common/overlay/overlay.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ConfirmationModalComponent } from './common/comment-modal/comment-modal.component';
import { DropdownComponent } from './grid-components/dropdown/dropdown.component';
import { DropdownEditorComponent } from './grid-components/dropdown-editor/dropdown-editor.component';
import { UpdateGridComponent } from './grid-components/update-grid/update-grid.component';
import { ZoomerComponent } from './common/zoomer/zoomer.component';
import { StepperComponent } from './common/stepper/stepper.component';
import { PanelComponent } from './common/panel/panel.component';
import { SearchboxComponent } from './common/searchbox/searchbox.component';
import { NavbarComponent } from './common/horizontal-nav-bar/navbar.component';
import { RouterModule } from '@angular/router';
import {NgbModule, NgbTypeaheadModule} from '@ng-bootstrap/ng-bootstrap'
import { ForbiddenValidatorDirective } from './common/forbidden-name.directive';
import { VcoHighlighterComponent } from './grid-components/vco-highlighter/vco-highlighter.component';
import { TextBoxComponent } from './grid-components/text-box/text-box.component';
import { TextboxEditorComponent } from './grid-components/textbox-editor/textbox-editor.component';
import { CaseActionComponent } from './grid-components/case-action/case-action.component';
import { ConfirmationComponent } from './common/confirmation/confirmation.component';
import { OptimizerActionComponent } from './grid-components/optimizer-action/optimizer-action.component';
import { VesselActionComponent } from './grid-components/vessel-action/vessel-action.component';
import { MaterialModule } from '../core/material.module';
import { CustomTooltip } from './common/tooltip/custom-tooltip.component';
import { SettingsBtnComponent } from './common/settings-btn/settings-btn.component';
import { BaseOilSettingsComponent } from './common/settings-btn/base-oil-settings/base-oil-settings.component';
import { DateRangeSelectionComponent } from './common/date-range-selection/date-range-selection.component';
import { NgbdTypeaheadComponent } from './common/typeahead/typeahead.component';

@NgModule({
  declarations: [
    DateformatComponent,
    MulitbuttonComponent,
    DatePickerComponent,
    CellHighlighterComponent,
    OverlayComponent,
    ConfirmationModalComponent,
    DropdownComponent,
    DropdownEditorComponent,
    UpdateGridComponent,
    ZoomerComponent,
    StepperComponent,
    PanelComponent,
    SearchboxComponent,
    NavbarComponent,
    ForbiddenValidatorDirective,
    VcoHighlighterComponent,
    TextBoxComponent,
    TextboxEditorComponent,
    CaseActionComponent,
    OptimizerActionComponent,
    ConfirmationComponent,
    VesselActionComponent,
    CustomTooltip,
    SettingsBtnComponent,
    BaseOilSettingsComponent,
    DateRangeSelectionComponent,
    NgbdTypeaheadComponent
  ],
  imports: [CommonModule, FormsModule,ReactiveFormsModule, RouterModule, NgbModule, NgbTypeaheadModule, MaterialModule],
  exports: [
    DateformatComponent,
    DatePickerComponent,
    MulitbuttonComponent,
    CellHighlighterComponent,
    VcoHighlighterComponent,
    OverlayComponent,
    ConfirmationModalComponent,
    DropdownComponent,
    DropdownEditorComponent,
    UpdateGridComponent,
    ZoomerComponent,
    StepperComponent,
    PanelComponent,
    SearchboxComponent,
    NavbarComponent,
    ForbiddenValidatorDirective,
    TextBoxComponent,
    TextboxEditorComponent,
    CaseActionComponent,
    OptimizerActionComponent,
    ConfirmationComponent,
    VesselActionComponent,
    MaterialModule,
    CustomTooltip,
    DatePickerComponent,
    DatePickerComponent,
    DateRangeSelectionComponent,
    NgbdTypeaheadComponent,
  ],
})
export class SharedModule {}
