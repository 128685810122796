import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, catchError, delay, of, throwError, timer } from 'rxjs';
import { environment } from '../../environments/environment';
import { Nomination, NominationInfo, NominationDataHandlerInterface, getNominationResponse, referenceData, SaveNominationResponse, NominationProduct, attachNominationResponse, attachNominationRequest } from './models';
//import { mockData } from './nominations-search/nomination-details/mock-data';

export class ScheduleNominationsService  {

  constructor(private httpClient: HttpClient, private source: string) {

  }

  public nominationInfos: NominationInfo[];
  private cargoScheduleId: number;

  refreshData(id: number, selectedNominationIndex: number | null): void {
    this.cargoScheduleId = id;
    this.nominationInfos = [];
    this.httpClient.get<getNominationResponse>('/api/nominations/' + this.source + '/' + this.cargoScheduleId)
      .subscribe((data: getNominationResponse) => {
        this.nominationInfos = data.noms;
        if(selectedNominationIndex !== null) {
          this.focusSelectedTab(selectedNominationIndex);
        }
        for(let i = 0; i < this.nominationInfos.length; i++) {
          let nomInfo:NominationInfo = this.nominationInfos[i];
          for(let k=0; k < nomInfo.nomination.items.length; k++) {
            let lineItem: NominationProduct = nomInfo.nomination.items[k];
            lineItem.canDelete = lineItem.deletionIndicator;
          }
        }
      });
  }

  focusSelectedTab(selectedNominationIndex) {
    this.nominationInfos.forEach((item, index) => {
      if(index !== selectedNominationIndex) {
        item.nomination.isSelected = false;
      } else {
        item.nomination.isSelected = true;
      }
    });
  }

  saveData(onSuccess, onError): void{
    for (var i = 0; i < this.nominationInfos.length; i++) {
      let nominationInfo = this.nominationInfos[i];
      let nom = nominationInfo.nomination;
      if (nom.isSelected === true) {
        let api_url;
        if (this.nominationInfos[i].nominationId) {
          api_url = '/api/nominations/update/' + this.nominationInfos[i].nominationId;
        } else {
          api_url = '/api/nominations/' + this.source + '/create/' + this.cargoScheduleId;
        }

        let subscription = this.httpClient.post<SaveNominationResponse>(api_url, nom).pipe();
        subscription.subscribe(data => {
          console.log(data);
          if (data.success === false) {
            nom.nominationNoMissing = data.errors.nominationNoMissing;
            nom.udmError = data.errors.udmError;
            nom.udmErrorMessage = data.errors.udmErrorMessage;
            for (var i = 0; i < data.errors.lineItemResults.length; i++) {
              let error = data.errors.lineItemResults[i];
              nom.items[error.index].destinationRDIMissing = error.destinationRDIMissing;
              nom.items[error.index].originRDIMissing = error.originRDIMissing;
              nom.items[error.index].qtyMissing = error.qtyMissing;
              nom.items[error.index].referenceDocItemMissing = error.referenceDocItemMissing;
              nom.items[error.index].scheduledDateMissing = error.scheduledDateMissing;
              nom.items[error.index].inCorrectDeletionIndicator = error.inCorrectDeletionIndicator;

            }
          } else {
            if (data.nominationId) {
              nominationInfo.nominationId = data.nominationId;
              nominationInfo.isSyncComplete = data.isSyncComplete;
            }
            onSuccess();
          }
        },
        err => onError(err));
      }
    }
  }

  /* Search Nomination */
  attachNomination(request: attachNominationRequest): Observable<attachNominationResponse> {
    let api_url = '/api/nominations/attach';

    return this.httpClient.post<attachNominationResponse>(api_url, request)
  }

}
