<app-mulitbutton
  (clickHandler)="handleMultipleClick($event)"
  [showCaseDetails]="true"
  [screenName]="screenName"
  [searchText]="state.searchText"
></app-mulitbutton>

<app-panel>
  <ag-grid-angular
    style="height: 100%; border: solid 1px"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions"
    [context]="agHelper"
    [undoRedoCellEditing]="true"
    [undoRedoCellEditingLimit]="5"
    [rowGroupPanelShow]="'always'"
    (cellValueChanged)="onCellValueChanged($event)"
    [loadingOverlayComponent]="overlayComponent"
    [groupRowAggNodes]="groupRowAggNodes.bind(this)"
  >
  </ag-grid-angular>
</app-panel>
