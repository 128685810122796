import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Observable, of } from 'rxjs';
declare var $: any;
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
})
export class ConfirmationComponent {
  @Output() clickHandler = new EventEmitter<any>();
  @Input() title = '';
  @Input() subTitle = '';
  @Input() buttonPrimary = '';
  @Input() buttonSecondary = '';
  constructor() {
     // This is intentional
  }

  onClick(val) {
    this.clickHandler.emit(val);
  }
  showModal(): any {
    $('#myModal').modal('show');
    return this.onCloseModal;
  }

  onCloseModal() {
    $('#myModal').on('hidden.bs.modal', (_e) => {
      // Fire a function in the console
      console.log('Function executed when gfgModal closed');      
    });   
  }
}
