<div
  class="d-flex justify-content-between flex-wrap flex-md-nowrap border-bottom dock-top"
>

  <div>
    <div *ngIf="showCaseDetails" class="case-details">
      <small class="badge bold">Title:</small>
      <small class="bold">{{ title }}</small> |
      <small class="badge bold">Created By:</small>
      <small class="bold">{{ createdBy }}</small> |
      <small class="badge bold">Status:</small>
      <small
        [ngClass]="
          status === 'Pending'
            ? 'btn-outline-danger bold'
            : 'btn-outline-success bold'
        "
        >{{ status }}</small
      >
    </div>
  </div>

  <div>
    <div class="d-flex">
      <div class="pr-3">
        <app-searchbox
          *ngIf="showSearchbar"
          [searchText]="searchText"
        ></app-searchbox>
      </div>
      <div [ngClass]="flowName === 'vco' ? 'pt-2' : ''" style="border:0px solid white;padding-right: 5px;">
      <div class="d-inline-block" *ngIf="pageIsNotBaseOil">
        <app-settings-btn [readOnly]="!showSettings"></app-settings-btn>
      </div>
      <button type="button" *ngIf="showButton.save" class="button-blue" mat-stroked-button 
        (click)="onClick('save')" >
          save&nbsp;<mat-icon svgIcon="cloud_queue"></mat-icon>
      </button>&nbsp;

      <button type="button" class="button-blue" mat-stroked-button 
      (click)="onClick('export')" >
      export&nbsp;<mat-icon svgIcon="export"></mat-icon>
      </button>

        <input *ngIf="showButton.optimize"
            type="image"
            (click)="onClick('optimize')"
            class="buttonicon"
            src="../../../../assets/images/optimizebutton.png"
            title="optimize" />
        &nbsp;
        <button type="button" *ngIf="showButton.add" class="button-blue" mat-stroked-button 
        (click)="onClick('add')">
        add&nbsp;<mat-icon svgIcon="add_circle_outline"></mat-icon>
        </button>
        &nbsp;
        <input *ngIf="showButton.delete"
            type="image"
            (click)="onClick('delete')"
            class="buttonicon"
            src="../../../../assets/images/delete.jpg"
            title="delete" />
      </div>
    </div>
  </div>
</div>


