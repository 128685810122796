import {
  AfterViewInit,
  Component,
  ElementRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';

@Component({
  selector: 'app-dropdown-editor',
  templateUrl: './dropdown-editor.component.html',
  styleUrls: ['./dropdown-editor.component.scss'],
})
export class DropdownEditorComponent implements AfterViewInit {
  params;
  @ViewChild('container', { static: true }) el: ElementRef;
  public container;
  constructor() {
     // This is intentional
  }

  agInit(params: any): void {
    this.params = params;
  }

  getValue(): any {
    return this.params.value;
  }

  isPopup(): boolean {
    return true;
  }

  getPopupPosition() {
    return 'under';
  }

  ngAfterViewInit() {
    window.setTimeout(() => {
      console.log(this.el);
      this.el.nativeElement.className = 'dropdown show';
    });
  }
}
