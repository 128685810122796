import { TabSection } from '../services/model/TabSection';
import StateProvider from '../services/States/StateProvider';

export abstract class StateComponent<TState> {
  private _originalState: Partial<TState>;
  state: Partial<TState>;
  tabSection: TabSection;

  constructor() {
     
  }

  initState(tabSection: TabSection) {
    this.tabSection = tabSection;
    const savedState = StateProvider.getState<TState>(tabSection);
    this._initState(savedState);
  }

  abstract onStateChange(newValue: Partial<TState>, oldValue: Partial<TState>);

  setState(newValue: Partial<TState>, saveOnly: boolean = false) {
    if (!this._originalState) {
      this._initState(newValue);
    }

    setTimeout(this._setState.bind(this, newValue, saveOnly), 0);
  }

  resetState() {
    //const defaultState = StateProvider.loadDefault<TState>(this.tabSection);
    this._setState(
      StateProvider.loadDefault<TState>(this.tabSection),
      false,
      true
    );
    // this._setState(this._originalState); reset to saved state
  }

  private _findChanges(newObject) {
    const changes = {};
    for (const key in newObject) {
      if (JSON.stringify(this.state[key]) !== JSON.stringify(newObject[key])) {
        changes[key] = this.state[key];
      }
    }
    return changes;
  }

  private _setState(
    newValue: Partial<TState>,
    saveOnly: boolean = false,
    forced = false
  ): void {
    const oldValue = this._findChanges(newValue);
    if (Object.keys(oldValue).length > 0) {
      if (forced) {
        this.state = newValue;
      } else {
        Object.assign(this.state, newValue);
      }
      StateProvider.setState(this.state);
      if (!saveOnly) {
        this.onStateChange(newValue, oldValue);
      }
    }
  }

  private _initState(newValue: Partial<TState>) {
    this.state = Object.assign({}, newValue);
    this._originalState = Object.assign({}, this.state);
  }
}
