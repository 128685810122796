class StorageAvailState {
  constructor() {
     // This is intentional
  }
  searchText = '';

  filteredProps = {};
  columnState: any[] = [];
}

export { StorageAvailState };
