<div class="input-group mb-3">
  <div class="input-group-prepend">
    <span class="input-group-text" id="basic-addon1">
      <i class="fa fa-search" aria-hidden="true"> </i
    ></span>
  </div>
  <input
    type="text"
    class="form-control"
    placeholder="Search"
    (input)="onValueChanging($event.target.value)"
    (keyup.enter)="onValueChange($event.target.value)"
    (blur)="onValueChange($event.target.value)"
    [value]="searchText"
  />
</div>
