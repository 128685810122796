import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutComponent } from './basecase/layout/layout.component';
import { SupplyComponent } from './basecase/supply/supply.component';
import { IndexComponent } from './basecase/index/index.component';
import { LocationComponent } from './basecase/storage/storage.component';
import { ThirdPartyComponent } from './basecase/third-party/third-party.component';
import { TransportationComponent } from './basecase/transportation/transportation.component';
import { CalAngularService, CalGuardService, ICvxClaimsPrincipal} from '@cvx/cal-angular';
//import { MsalGuard } from '@azure/msal-angular';
import { ReportsComponent } from './basecase/reports/reports.component';
import { DocksComponent } from './basecase/docks/docks.component';
import { HomeComponent } from './basecase/home/home.component';
import { PriceComponent } from './basecase/price/price.component';
import { UnauthorizedAccessComponent } from './unauthorized-access.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    data:{title: "My Cases"}
  },
  {
    path: 'case',
    pathMatch: 'full',
    component: IndexComponent,
    canActivate: [CalGuardService],
    data:{title: "My Cases"}
  },
  {
    path: 'published-cases',
    component: IndexComponent,
    data:{title: "Published Cases"}
  },
  {
    path: 'optimizer-runs',
    component: IndexComponent,
    data:{title: "Optimizer Runs"}
  },
  {
    path: 'report', 
    data:{title:'Reports'},
    children: [
      {
        path: 'data-validation-report',
        component: ReportsComponent,
        data:{title:'Validation Reports'}
      },
      {
        path: 'base-case-report',
        component: ReportsComponent,
      },
      {
        path: 'results',
        component: ReportsComponent,
        data:{title:'Results'}
      },
    ],
  },
  {
    path: 'case',
    component: LayoutComponent,
    data:{title: "Case"},
    children: [
      {
        path: 'refinery',
        component: SupplyComponent,
        data:{title: "Refinery"},
      },
      {
        path: 'storage-tanks',
        component: LocationComponent,
        data:{title: "Storage Tanks"},
      },
      {
        path: 'dock',
        component: DocksComponent,
        data:{title: "Dock"},
      },
      {
        path: 'contracts',
        component: ThirdPartyComponent,
        data:{title: "Contracts"},
      },
      {
        path: 'liftings',
        children: [
          {
            path: 'domestic',
            component: TransportationComponent,
          },
          {
            path: 'international',
            component: TransportationComponent,
          },
          {
            path: 'pipeline',
            component: TransportationComponent,
          },
        ],
      },
      {
        path: 'price',
        component: PriceComponent,
      },
    ],
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [CalGuardService],
    data:{title: "Reference Data"}    
  },
  {
    path: 'market-elasticity',
    loadChildren: () =>
      import('./market-elasticity/market-elasticity.module').then(
        (m) => m.MarketElasticityModule
      ),
    canActivate: [CalGuardService],
    data:{title: "Market Elasticity"}
  },
  {
    path: 'cca',
    loadChildren: () =>
      import('./cca/cca.module').then((m) => m.CCAModule),
    canActivate: [CalGuardService],
    data:{title: "LATAM"}
  },
  {
    path: 'refinery-exports',
    loadChildren: () =>
      import('./refinery-exports/refinery-exports.module').then((m) => m.RefineryExportsModule),
      canActivate: [CalGuardService],
      data:{title: "Exports"}
  },  
  {
    path: 'unauthorized',
    component: UnauthorizedAccessComponent,
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppRoutingModule {}
