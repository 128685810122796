import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CCAPlanningService {

  constructor(private http: HttpService) {

  }

  getColumnDefsData(planId : number):Observable<any>  {
    let url = `${environment.ccaColumnDefsUrl}/${planId}`;
    return this.http.getData(url);
  }

  getData(planId : number, showCompleted: boolean = false): Observable<any> {
    let data = {
      planId: planId,
      showCompleted: showCompleted
    };

    let url = `${environment.ccaDataUrl}`;
    return this.http.postData(url, data);
  }

  getPlans(): Observable<any> {
    let url = `${environment.ccaDataUrl}/plans`;
    return this.http.getData(url);
  }

  GetVessels(): Observable<any> {
    let url = `${environment.ccaDataUrl}/vessels`;
    return this.http.getData(url);
  }

  GetLoadPorts(planId: number): Observable<any> {
    let url = `${environment.ccaDataUrl}/loadPorts/${planId}`;
    return this.http.getData(url);
  }

  getNominations(nominationKey: number, nominationNumber: string): Observable<any> {
    let url = `${environment.ccaDataUrl}/getNominations/${nominationKey}/${nominationNumber}`;
    return this.http.getData(url);
  }

  attachNominations(cargoScheduleId, payload): Observable<any> {
    let url = `${environment.ccaDataUrl}/attachNominations/${cargoScheduleId}`;
    return this.http.postData(url, payload);
  }

  public nomSubject = new BehaviorSubject<any>(null);
  changeNomination(message: any) {
    this.nomSubject.next(message)
  }

  saveData(planId, data): Observable<any> {
    const url = `${environment.ccaDataUrl}/plans/${planId}/schedules`;
    return this.http.postData(url, data);
  }

  deleteSchedule(id): Observable<any> {
    const url = `${environment.ccaDataUrl}/schedules` + '/' + id;
    return this.http.deleteData(url);
  }

  toggleScheduleCompletedFlag(request): Observable<any> {
    const url = `${environment.ccaDataUrl}/completed-schedules`;
    return this.http.postData(url, request);
  }

  toggleSchedulePublishedFlag(request): Observable<any> {
    const url = `${environment.ccaDataUrl}/published-schedules`;
    return this.http.postData(url, request);
  }

  getVesselTypesDetail(): Observable<any> {
    let url = `${environment.ccaDataUrl}/vesselTypeDetails`;
    return this.http.getData(url);
  }

  getCommentDetail(locRefId,scheId): Observable<any> {
    let url = `${environment.ccaDataUrl}/getdemandcomments/${locRefId}/${scheId}`;
    return this.http.getData(url);
  }

  addComment(scheId,data): Observable<any> {
    const url = `${environment.ccaDataUrl}/adddemandcomments/${scheId}`;
    return this.http.postData(url, data);
  }

  updateComment(id,data): Observable<any> {
    const url = `${environment.ccaDataUrl}/updatedemandcomments/${id}`;
    return this.http.postData(url, data);
  }

  deleteComment(id): Observable<any> {
    const url = `${environment.ccaDataUrl}/deletedemandcomments/${id}`;
    return this.http.deleteData(url);
  }

  loadCommentsExist(planId): Observable<any> {
    let url = `${environment.ccaDataUrl}/getcommentsexist/${planId}`;
    return this.http.getData(url);
  }

  getStatusDetails(): Observable<any> {
    let url = `${environment.ccaDataUrl}/statusDetails`;
    return this.http.getData(url);
  }

  getDemandDetails(scheId,locRefId): Observable<any> {
    let url = `${environment.ccaDataUrl}/getdemanddetails/${scheId}/${locRefId}`;
    return this.http.getData(url);
  }

  getTSWNominationDetails(tswNominaNo,tswNominaLineItem): Observable<any> {
    let url = `${environment.tswNominationUrl}/tsw-download/${tswNominaNo}/${tswNominaLineItem}`;
    return this.http.getData(url);
  }

  getLocations(planId): Observable<any> {
    let url = `${environment.ccaDataUrl}/getLocations/${planId}`;
    return this.http.getData(url);
  }

  saveLayycan(data): Observable<any> {
    const url = `${environment.ccaDataUrl}/saveDeilveryLaycan`;
    return this.http.postData(url, data);
  }

  getLocationLaycans(id): Observable<any> {
    let url = `${environment.ccaDataUrl}/getLocationLaycans/${id}`;
    return this.http.getData(url);
  }
}
