<button type="button" (click)="openModal()" class="button-white rounded-0 mr-2" mat-stroked-button>
    <span class="d-flex align-items-center"> settings &nbsp;
        <mat-icon>settings</mat-icon> </span>
</button>

<div class="modal fade" tabindex="-1" id="settings-modal" role="dialog" aria-labelledby="settingslabel"
    aria-hidden="true">
    <div class="modal-dialog" id="settings-modal-dialog">

        <div class="modal-content p-5">

            <button type="button" class="close" aria-label="Close" (click)="closeModal()">
                <span aria-hidden="true">&times;</span>
            </button>
            <p id="modal-header-name" class="mb-4"> settings </p>
            <div class="c-wrapper" *ngIf="configData?.category !== 'BaseOils'">
                <div> <strong> {{configData?.scenario}} </strong> </div>
                <div class="plant mb-3"> {{configData?.plantName}} </div>

                <form class="d-flex justify-content-between mt-3" [formGroup]="settingsForm">
                    <!-- <div class="form-group">
                        <label for="DaysToForecast">days to forecast</label>
                        <input type="number" class="form-control" id="daysToForecast" name="daysToForecast"
                            formControlName="DaysToForecast" />
                    </div> -->
                    <div class="form-group">
                        <label for="opsMin">ops min</label>
                        <input type="number" class="form-control" id="opsMin" name="opsMin" formControlName="OpsMin" [readonly]="readOnly" />
                    </div>
                    <div class="form-group">
                        <label for="opsMax">ops max</label>
                        <input type="number" class="form-control" id="opsMax" name="opsMax" formControlName="OpsMax" [readonly]="readOnly" />
                    </div>
                    <div class="form-group">
                        <label for="minEsl">min esl</label>
                        <input type="number" class="form-control" id="minEsl" name="minEsl" formControlName="MinESL" [readonly]="readOnly" />
                    </div>
                    <div class="form-group">
                        <label for="maxEsl">max esl</label>
                        <input type="number" class="form-control" id="maxEsl" name="maxEsl" formControlName="MaxESL" [readonly]="readOnly" />
                    </div>
                    <div class="form-group">
                        <label for="minTargetInventory">minTargetInventory</label>
                        <input type="number" class="form-control" id="minTargetInventory" name="minTargetInventory"
                            formControlName="MinTargetInventory" [readonly]="readOnly" />
                    </div>
                    <div class="form-group">
                        <label for="maxTargetInventory">maxTargetInventory</label>
                        <input type="number" class="form-control" id="maxTargetInventory" name="maxTargetInventory"
                            formControlName="MaxTargetInventory" [readonly]="readOnly" />
                    </div>
                    <div class="form-group">
                        <label for="targetInventory">TargetInventory</label>
                        <input type="number" class="form-control" id="targetInventory" name="targetInventory"
                            formControlName="TargetInventory" [readonly]="readOnly" />
                    </div>
                </form>
            </div>

            <div class="base-oil-wrapper" *ngIf="configData?.category == 'BaseOils'">
                <app-base-oil-settings 
                    [constants]="baseOilConstants" 
                    [configData]="configData"
                    [readOnly]="readOnly"
                    >
                </app-base-oil-settings>
            </div>

            <div *ngIf="submitted" class="error-container">
                <div class="has-error" *ngIf="f['DaysToForecast'].errors">
                    Days to forecast values should be in between 30 and 120
                </div>
                <div class="has-error" *ngIf="settingsForm?.errors && settingsForm?.errors['opsMin_error']">
                    Ops Min Value should be smaller than Ops Max
                </div>
                <div class="has-error" *ngIf="settingsForm?.errors && settingsForm.errors['minESL_error']">
                    Min ESL Value should be greater than or equal to Ops Min
                </div>
                <div class="has-error" *ngIf="settingsForm?.errors && settingsForm.errors['maxESL_error']">
                    Max ESL Value should be less than or equal to Ops Max
                </div>
                <div class="has-error" *ngIf="settingsForm?.errors && settingsForm.errors['minTargetInventory_error']">
                    MinTargetInventory Value should be greater than or equal to MinESL
                </div>
                <div class="has-error" *ngIf="settingsForm?.errors && settingsForm.errors['maxTargetInventory_error']">
                    MaxTargetInventory Value should be less than or equal to MaxESL
                </div>
                <div class="has-error" *ngIf="settingsForm?.errors && settingsForm.errors['targetInventory_error1']">
                    TargetInventory Value should be greater than or equal to MinTargetInventory
                </div>
                <div class="has-error" *ngIf="settingsForm?.errors && settingsForm.errors['targetInventory_error2']">
                    TargetInventory Value should be less than or equal to MaxTargetInventory
                </div>
                <div class="has-error"
                    *ngIf="settingsForm.invalid && !f['DaysToForecast'].errors && !settingsForm?.errors ">
                    Please enter only non-negative numbers.
                </div>
            </div>
            <div class="text-right mt-3">
                <button class="button-white rounded-0 mr-2" (click)="closeModal()" mat-stroked-button> close </button>
                <button class="button-white rounded-0 mr-2" mat-stroked-button (click)="onSubmit()" [hidden]="readOnly"> save changes </button>
            </div>

        </div>

    </div>
</div>