<div class="zoombar pt-3" style="border: 0px solid white!important;">
  <div class="cursor" style="border: 0px solid white!important;">
    <!-- <small> Reset Columns </small>
    <i
      class="fa fa-undo"
      aria-hidden="true"
      title="Reset Columns"
      (click)="resetColumns()"
    ></i> -->
  </div>
  <div style="border: 0px solid white!important;">
    <input
      class="input_css"
      type="range"
      (change)="onChange($event.target.value)"
      style="padding-right: 15px"
      value="{{ zoomerProps.value }}"
      min="{{ zoomerProps.minValue }}"
      max="{{ zoomerProps.maxValue }}"
    />
    {{ zoomerProps.value }}%
    <!-- <div style="font-size: 15px" class="pt-1">{{ zoomerProps.value }}%</div> -->
  </div>
</div>