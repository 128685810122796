import { Injectable } from '@angular/core';
import { HttpService } from './common/http.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private http: HttpService) {}
  getRowData(id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/storage`;
    return this.http.getData(url);
  }

  getDockRowData(id?): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/dockData`;
    return this.http.getData(url);
  }

  getColumnData(): Observable<any> {
    const url = environment.locationPageColumnDataUrl;
    return this.http.getData(url);
  }
  saveData(data, id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/dockData`;
    return this.http.postData(url, data);
  }
  getDockColumnData(): Observable<any> {
    const url = environment.locationPageDockColumnDataUrl;
    return this.http.getData(url);
  }
}
