import { Component, OnDestroy, OnInit } from '@angular/core';
import { RefineryService } from 'src/app/services/refinery.service';

import { CellHighlighterComponent } from '../../shared/grid-components/cell-highlighter/cell-highlighter.component';
import { OverlayComponent } from '../../shared/common/overlay/overlay.component';
import { AgHelper } from 'src/app/services/agHelper';
import { Router, ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/common/notification.service';
import { EventService } from 'src/app/services/common/event.service';
import appConstant from '../../app.constants';
import { RefineryState } from 'src/app/services/States/case-input-screens/refineryState';
import { StateComponent } from 'src/app/core/StateComponent';
import { TabSection } from 'src/app/services/model/TabSection';
import { OptimizerService } from 'src/app/services/optimizer.service';
import { HomeService } from 'src/app/services/home.service';
@Component({
  selector: 'app-supply',
  templateUrl: './supply.component.html',
  styleUrls: ['./supply.component.scss'],
})
export class SupplyComponent
  extends StateComponent<RefineryState>
  implements OnInit, OnDestroy {
  columnDefs = [];
  rowData = [];
  productColsDef = [];
  isoMaxColDef = [];
  sideBar;
  agHelper = AgHelper;
  gridApi;
  gridColumnApi;
  gridOptions = {
    defaultColDef: {
      resizable: true,
      filter: true,
      sortable: true,
      enableRowGroup: true,
    },
    frameworkComponents: {
      cellHighlighterComponent: CellHighlighterComponent,
      overlayComponent: OverlayComponent,
    },
    components: {
      datePicker: this.agHelper.getDatePicker(),
    },
    autoGroupColumnDef: {
      cellRendererParams: {
        suppressCount: true,
      },
    },
    groupIncludeFooter: true,
    groupIncludeTotalFooter: false,
    groupMultiAutoColumn: true,
  };

  subscription: Subscription;
  dataToSave = [];
  id;
  overlayComponent = 'overlayComponent';
  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'center',
      },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };

  viewAction = {
    handleSearchChange: (data: any) => {
      this.setState({ searchText: data });
    },
    onFilterChanged(filterEvent) {
      const filters = filterEvent.api.filterManager.getFilterModel();
      this.setState({ filteredProps: filters }, true);
    },
    onColumnStateChanged(_event) {
      if (this.gridColumnApi) {
        const columnStates = this.gridColumnApi.getColumnState();
        this.setState({ columnState: columnStates }, true);
      }
    },
    onResetFilter: () => {
      this.clearGridHandlers();
      this.resetState();
      this.attachGridHandlers();
    },
  };
  filteredData = [];
  screenName = appConstant.INPUT_SCREENS.REFINERY;
  mogasData = [];
  distillateData = [];
  tabName = 'Mogas';
  productsList = ['Mogas', 'Jet', 'LSD', 'ULSD', 'ISOMAX'];
  constructor(
    private refinerySerivce: RefineryService,
    private eventService: EventService,
    private homeService: HomeService,
    private optimizerService: OptimizerService,
    private router: Router,
    private notification: NotificationService
  ) {
    super();
    this.initState(TabSection.RefineryInputScreen);
    this.getColumns();
    this.sideBar = this.agHelper.getFilterPanelConfig();
    this.subscription = this.eventService.events$.subscribe((event) => {
      switch (event.name) {
        case 'search':
          this.viewAction.handleSearchChange(event.value);
          break;
      }
    });
  }

  ngOnInit() {
    this.id = window.sessionStorage.getItem('caseID');
    this.getData(this.tabName);
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
  }

  getData(pFam) {
    this.clearGridHandlers();
    this.refinerySerivce.getRowData(this.id, pFam).subscribe((data) => {
      this.rowData = data.data;
      this.setColumnState(this.state);
      this.attachGridHandlers();
    });
  }

  getColumns() {
    const productCols = this.refinerySerivce.getMogasColumnData();
    const isoMaxCol = this.refinerySerivce.getIsoMaxColumnData();
    forkJoin([productCols, isoMaxCol]).subscribe((colData) => {
      this.productColsDef = colData[0];
      this.isoMaxColDef = colData[1];
      this.state.tabName === 'ISOMAX'
        ? (this.columnDefs = this.isoMaxColDef)
        : (this.columnDefs = this.productColsDef);
      this.mapFilterToColumns();
    });
  }

  handleMultipleClick(event) {
    switch (event) {
      case 'save':
        this.onSaveData();
        break;
      case 'export':
        this.exportAsExcel();
        break;
      case 'optimize':
        if (this.canDeactivate())
          this.runOptimizer(this.id, this.homeService.getUserName());                  
        break;
      default:
        break;
    }
  }

  onSaveData() {
    if (this.dataToSave.length === 0) {
      this.notification.warn('No data to save');
      return;
    }
    this.notification.info('Saving...');
    this.refinerySerivce.saveData({ data: this.dataToSave }, this.id).subscribe(
      (_data) => {
        this.dataToSave = [];
        this.notification.success();
        this.gridApi.undoRedoService.clearStacks();
      },
      (err) => {
        this.notification.error(err);
      }
    );
  }

  runOptimizer(id: number, userName: string) {
    if (confirm('Are you sure you want to schedule an optimizer run?')) {
      this.optimizerService
        .runOptimizer(id, userName)
        .subscribe((_payload) => {
          this.notification.success(
            'Optimizer run has been scheduled successfully'
          );
          this.router.navigate(['optimizer-runs']);
        },
          (err) => {
            this.notification.error(err);
          }
        );
    }
  }

  canDeactivate(): Observable<Boolean> | Promise<boolean> | boolean {
    if (this.dataToSave.length > 0) {
      window.alert(
        'There are unsaved changes on this page. please save to continue with optimizer'
      );      
      return false;
    } else {
      return true;
    }
  }

  onCellValueChanged(params) {
    const valueSet = params.data[params.colDef.field];
    if (valueSet.value != valueSet.orgVal) {
      const { refineryProductionInputId } = params.data;
      if (
        !this.dataToSave.find((data) => {
          return (
            data.id == params.data.refineryProductionInputId.value &&
            data.field === params.colDef.field
          );
        })
      ) {
        this.dataToSave.push({
          id: +refineryProductionInputId.value,
          production: valueSet.value,
        });
      } else {
        const objIndex = this.dataToSave.findIndex(
          (obj) =>
            obj.id == params.data.id.value && obj.field === params.colDef.field
        );
        this.dataToSave[objIndex]['production'] = valueSet.value;
      }
    } else {
      const objIndex = this.dataToSave.findIndex(
        (obj) => obj.id === params.data.id && obj.field === params.colDef.field
      );
      this.dataToSave.splice(objIndex, 1);
    }
  }

  mapFilterToColumns() {
    this.columnDefs.forEach((col) => {
      if (['productionDate'].includes(col.field)) {
        col.filter = 'agDateColumnFilter';
        col.filterParams = this.agHelper.getDateFilter();
      }
      if (col.field === 'production') {
        col.cellStyle = (params) => {
          let borderClass;
          if (params.node.footer) {
            borderClass = { fontWeight: 'bold', textAlign: 'right' };
          } else {
            borderClass = { textAlign: 'right' };
          }
          return borderClass;
        };
      }
    });
  }

  filterBySearch = (value) => {
    let collection = this.rowData;
    if (!collection) {
      return;
    }

    if (value && value != '') {
      collection = collection.filter(
        (item) =>
          this.agHelper.findSubstring(item.product.value, value) ||
          this.agHelper.findSubstring(item.isoMaxID.value, value)
      );
    }
    this.filteredData = collection;
    this.gridApi.setRowData(this.filteredData);
  };

  toggleData(productFamily) {
    if (productFamily === 'ISOMAX') {
      this.initState(TabSection.RefineryIsoMaxScreen);
      this.setState({ tabName: '' }, true);
    } else {
      this.initState(TabSection.RefineryInputScreen);
      this.setState({ tabName: '' }, true);
    }

    this.tabName = productFamily;
    this.setState({ tabName: productFamily });
  }

  bindIsoMaxColDef() {
    this.columnDefs = this.isoMaxColDef;
    this.mapFilterToColumns();
  }

  groupRowAggNodes(nodes) {
    let aggRow = {
      production: 0,
    };

    nodes.forEach((node) => {
      let data = node.group ? node.aggData : node.data;
      const _production =
        typeof data.production === 'object'
          ? data.production['value']
          : data.production;

      aggRow.production += parseInt(_production || 0);
    });

    return aggRow;
  }

  onStateChange(
    newValue: Partial<RefineryState>,
    _oldValue: Partial<RefineryState>
  ) {
    if (newValue.tabName) {
      if (newValue.tabName === 'ISOMAX') {
        this.bindIsoMaxColDef();
      } else {
        this.columnDefs = this.productColsDef;
      }

      this.getData(newValue.tabName);
      this.filterBySearch(this.state.searchText);
    } else if (newValue.searchText || newValue.searchText.length === 0) {
      this.filterBySearch(newValue.searchText);
    }
  }

  setColumnState(newValue, _isColumnStateSet = true) {
    if (newValue.columnState && newValue.columnState.length > 0) {
      this.gridColumnApi.setColumnState(newValue.columnState);
    } else if (newValue.columnState && newValue.columnState.length === 0) {
      this.gridColumnApi.resetColumnState();
    }
  }
  attachGridHandlers() {
    const handler = this.viewAction.onColumnStateChanged.bind(this);
    const filterChangeHandler = this.viewAction.onFilterChanged.bind(this);
    setTimeout(() => {
      this.gridOptions['onFilterChanged'] = filterChangeHandler;
      this.gridOptions['onVirtualColumnsChanged'] = handler;
      this.gridOptions['onGridColumnsChanged'] = handler;
      this.gridOptions['onColumnValueChanged'] = handler;
      this.gridOptions['onDisplayedColumnsChanged'] = handler;
      this.gridOptions['onColumnGroupOpened'] = handler;
    }, 10000);
  }
  clearGridHandlers() {
    this.gridOptions['onFilterChanged'] = null;
    this.gridOptions['onVirtualColumnsChanged'] = null;
    this.gridOptions['onGridColumnsChanged'] = null;
    this.gridOptions['onColumnValueChanged'] = null;
    this.gridOptions['onDisplayedColumnsChanged'] = null;
    this.gridOptions['onColumnGroupOpened'] = null;
  }
  exportAsExcel() {
    const params = {
      fileName: 'Refinery'
    };
    this.gridApi.exportDataAsExcel(params);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
