import { Component, OnDestroy, OnInit } from '@angular/core';
import { CellHighlighterComponent } from '../../shared/grid-components/cell-highlighter/cell-highlighter.component';
import { OverlayComponent } from '../../shared/common/overlay/overlay.component';
import { AgHelper } from 'src/app/services/agHelper';
import { Observable, Subscription } from 'rxjs';
import { NotificationService } from 'src/app/services/common/notification.service';

import { ThridPartyService } from 'src/app/services/thrid-party.service';
import { EventService } from 'src/app/services/common/event.service';
import appConstant from '../../app.constants';
import { ContractState } from 'src/app/services/States/case-input-screens/contractState';
import { StateComponent } from 'src/app/core/StateComponent';
import { TabSection } from 'src/app/services/model/TabSection';
import { HomeService } from 'src/app/services/home.service';
import { OptimizerService } from 'src/app/services/optimizer.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-third-party',
  templateUrl: './third-party.component.html',
  styleUrls: ['./third-party.component.scss'],
})
export class ThirdPartyComponent
  extends StateComponent<ContractState>
  implements OnInit, OnDestroy {
  columnDefs = [];
  rowData = [];
  gridApi;
  sideBar;
  agHelper = AgHelper;
  gridColumnApi;
  gridOptions = {
    defaultColDef: {
      resizable: true,
      sortable: true,
      flex: 1,
      filter: true,
      enableRowGroup: true,
    },
    frameworkComponents: {
      cellHighlighterComponent: CellHighlighterComponent,
      overlayComponent: OverlayComponent,
    },
    components: {
      datePicker: this.agHelper.getDatePicker(),
    },
  };
  subscription: Subscription;
  dataToSave = [];
  id;
  overlayComponent = 'overlayComponent';
  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'center',
      },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };
  viewAction = {
    handleSearchChange: (data: any) => {
      this.setState({ searchText: data });
    },
    onFilterChanged(filterEvent) {
      const filters = filterEvent.api.filterManager.getFilterModel();
      this.setState({ filteredProps: filters }, true);
    },
    onColumnStateChanged(_event) {
      if (this.gridColumnApi) {
        const columnStates = this.gridColumnApi.getColumnState();
        this.setState({ columnState: columnStates }, true);
      }
    },
    onResetFilter: () => {
      this.clearGridHandlers();
      this.resetState();
      this.attachGridHandlers();
    },
  };
  filteredData = [];
  screenName = appConstant.INPUT_SCREENS.CONTRACTS;
  rowCount = 0;
  constructor(
    private thirdPartyService: ThridPartyService,
    private notification: NotificationService,
    private homeService: HomeService,
    private optimizerService: OptimizerService,
    private router: Router,
    private eventService: EventService
  ) {
    super();
    this.initState(TabSection.ContractsInputScreen);
    this.getColumns();
    this.sideBar = this.agHelper.getFilterPanelConfig();
    this.subscription = this.eventService.events$.subscribe((event) => {
      switch (event.name) {
        case 'search':
          this.viewAction.handleSearchChange(event.value);
          break;
      }
    });
  }

  ngOnInit() {
    this.id = window.sessionStorage.getItem('caseID');
    this.getData();
  }
  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
  }

  getData() {
    this.clearGridHandlers();
    this.thirdPartyService.getRowData(this.id).subscribe((data) => {
      this.rowData = data.data;
      this.setColumnState(this.state);
      this.attachGridHandlers();
      setTimeout(() => {
        this.filterBySearch(this.state.searchText);
      }, 0);
    });
  }

  getColumns() {
    const columns = this.thirdPartyService.getColumnData();
    columns.subscribe((data) => {
      this.columnDefs = data;
      this.mapFilterToColumns();
    });
  }

  setDropDownOptions(data) {
    const deliveryLocation = this.columnDefs.filter(
      (x) => x.field === 'location'
    )[0];
    const spot = this.columnDefs.filter((x) => x.field === 'category')[0];
    const receipt = this.columnDefs.filter((x) => x.field === 'type')[0];
    const productName = this.columnDefs.filter((x) => x.field === 'product')[0];

    const productOptions = data[1].data.map((option) => option.productName);
    const locationOptions = data[2].data.map((option) => option.location);
    spot.cellEditorParams = {
      values: ['Spot', 'Term'],
    };
    spot.cellRendererParams = {
      isDropdown: true,
    };
    receipt.cellEditorParams = {
      values: ['Delivery', 'Receipt'],
    };

    receipt.cellRendererParams = {
      isDropdown: true,
    };

    deliveryLocation.cellRendererParams = {
      isDropdown: true,
    };
    deliveryLocation.cellEditorParams = {
      values: [...locationOptions],
    };

    deliveryLocation.cellRendererParams = {
      isDropdown: true,
    };
    deliveryLocation.valueFormatter = (params) =>
      params.value && params.value.value;

    productName.cellEditorParams = {
      values: [...productOptions],
    };

    productName.cellRendererParams = {
      isDropdown: true,
    };
    productName.valueFormatter = (params) => params.value && params.value.value;
  }

  onSelectionChanged(event) {
    this.rowCount = event.api.getSelectedNodes().length;
  }
  handleMultipleClick(event) {
    switch (event) {
      case 'save':
        this.onSaveData();
        break;
      case 'export':
        this.exportAsExcel();
        break;
      case 'optimize':
        if (this.canDeactivate())
          this.runOptimizer(this.id, this.homeService.getUserName());                  
        break;
      default:
        break;
    }
  }

  onSaveData() {
    if (this.dataToSave.length === 0) {
      this.notification.warn('No data to save');
      return;
    }
    this.notification.info('Saving...');
    this.thirdPartyService
      .saveData({ data: this.dataToSave }, this.id)
      .subscribe(
        (_data) => {
          this.dataToSave = [];
          this.notification.success();
          this.gridApi.undoRedoService.clearStacks();
        },
        (_err) => {
          this.notification.error();
        }
      );
  }

  runOptimizer(id: number, userName: string) {
    if (confirm('Are you sure you want to schedule an optimizer run?')) {
      this.optimizerService
        .runOptimizer(id, userName)          
        .subscribe((_payload) => {
          this.notification.success(
            'Optimizer run has been scheduled successfully'
          );
          this.router.navigate(['optimizer-runs']);
        },
        (err) => {
          this.notification.error(err);
        }
        );
    }
  }

  canDeactivate(): Observable<Boolean> | Promise<boolean> | boolean {
    if (this.dataToSave.length > 0) {
     window.alert(
        'There are unsaved changes on this page. please save to continue with optimizer'
      );
      return false;
    } else {
      return true;
    }
  }

  onCellValueChanged(params) {
    const valueSet = params.data[params.colDef.field];
    if (valueSet.value != valueSet.orgVal) {
      const { thirdPartyInputId } = params.data;
      if (
        !this.dataToSave.find((data) => {
          return (
            data.id == params.data.thirdPartyInputId.value &&
            data.field === params.colDef.field
          );
        })
      ) {
        this.dataToSave.push({
          id: +thirdPartyInputId.value,
          field: params.colDef.field,
          value: valueSet.value,
        });
      } else {
        const objIndex = this.dataToSave.findIndex(
          (obj) =>
            obj.id == params.data.thirdPartyInputId.value &&
            obj.field === params.colDef.field
        );
        this.dataToSave[objIndex].value = valueSet.value;
      }
    } else {
      const objIndex = this.dataToSave.findIndex(
        (obj) =>
          obj.id === params.data.thirdPartyInputId &&
          obj.field === params.colDef.field
      );
      this.dataToSave.splice(objIndex, 1);
    }
  }

  mapFilterToColumns() {
    this.columnDefs.forEach((col) => {
      if (['transactionDate', 'windowBeg', 'windowEnd'].includes(col.field)) {
        col.filter = 'agDateColumnFilter';
        col.filterParams = this.agHelper.getDateFilter();
      }
      /*   switch (col.field) {
        case 'location':
          col.cellStyle = (params) => {
            let borderClass;
            if (params.data) {
              if (params.data.location.value === '-') {
                borderClass = { border: '1px solid red', textAlign: 'center' };
              } else {
                borderClass = {
                  'border-color': 'rgb(240, 235, 235)',
                  'border-right': '1px solid #ccc',
                  textAlign: 'center',
                };
              }
            }
            return borderClass;
          };
          break;
        case 'product':
          col.cellStyle = (params) => {
            let borderClass;
            if (params.data) {
              if (params.data.product.value === '-') {
                borderClass = { border: '1px solid red', textAlign: 'center' };
              } else {
                borderClass = {
                  'border-color': 'rgb(240, 235, 235)',
                  'border-right': '1px solid #ccc',
                  textAlign: 'center',
                };
              }
            }
            return borderClass;
          };
          break;
        case 'type':
          col.cellStyle = (params) => {
            let borderClass;
            if (params.data) {
              if (params.data.type.value === '-') {
                borderClass = { border: '1px solid red', textAlign: 'center' };
              } else {
                borderClass = {
                  'border-color': 'rgb(240, 235, 235)',
                  'border-right': '1px solid #ccc',
                  textAlign: 'center',
                };
              }
            }
            return borderClass;
          };
          break;
        case 'category':
          col.cellStyle = (params) => {
            let borderClass;
            if (params.data) {
              if (params.data.category.value === '-') {
                borderClass = { border: '1px solid red', textAlign: 'center' };
              } else {
                borderClass = {
                  'border-color': 'rgb(240, 235, 235)',
                  'border-right': '1px solid #ccc',
                  textAlign: 'center',
                };
              }
            }
            return borderClass;
          };
          break;
      } */
    });
  }

  filterBySearch = (value) => {
    let collection = this.rowData;
    if (!collection) {
      return;
    }

    if (value && value != '') {
      collection = collection.filter(
        (item) =>
          this.agHelper.findSubstring(item.location.value, value) ||
          this.agHelper.findSubstring(item.product.value, value) ||
          this.agHelper.findSubstring(item.category.value, value)
      );
    }
    this.filteredData = collection;
    this.gridApi.setRowData(this.filteredData);
  };

  onStateChange(
    newValue: Partial<ContractState>,
    _oldValue: Partial<ContractState>
  ) {
    if (newValue.searchText || newValue.searchText.length === 0) {
      this.filterBySearch(newValue.searchText);
    }
  }

  setColumnState(newValue, _isColumnStateSet = true) {
    if (newValue.columnState && newValue.columnState.length > 0) {
      this.gridColumnApi.setColumnState(newValue.columnState);
    } else if (newValue.columnState && newValue.columnState.length === 0) {
      this.gridColumnApi.resetColumnState();
    }
  }
  attachGridHandlers() {
    const handler = this.viewAction.onColumnStateChanged.bind(this);
    const filterChangeHandler = this.viewAction.onFilterChanged.bind(this);
    setTimeout(() => {
      this.gridOptions['onFilterChanged'] = filterChangeHandler;
      this.gridOptions['onVirtualColumnsChanged'] = handler;
      this.gridOptions['onGridColumnsChanged'] = handler;
      this.gridOptions['onColumnValueChanged'] = handler;
      this.gridOptions['onDisplayedColumnsChanged'] = handler;
      this.gridOptions['onColumnGroupOpened'] = handler;
    }, 5000);
  }
  clearGridHandlers() {
    this.gridOptions['onFilterChanged'] = null;
    this.gridOptions['onVirtualColumnsChanged'] = null;
    this.gridOptions['onGridColumnsChanged'] = null;
    this.gridOptions['onColumnValueChanged'] = null;
    this.gridOptions['onDisplayedColumnsChanged'] = null;
    this.gridOptions['onColumnGroupOpened'] = null;
  }

  exportAsExcel() {
    const params = {
      fileName: 'Contracts',
    };
    this.gridApi.exportDataAsExcel(params);
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
