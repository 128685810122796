export const environment = {
  showForProd:true,
  production: true,
  clientId: '00a05d21-a204-4904-b4a0-3df329c45bb4',
  authority:
    'https://login.microsoftonline.com/fd799da1-bfc1-4234-a91c-72b3a1cb9e26',
  audience: "https://distri-test.azure.chevron.com",
  redirectUri: 'https://distri-dev.azure.chevron.com', //'https://distri-dev-cvx.azurewebsites.net'
  profileUrl: 'https://graph.microsoft.com/v1.0/me',
  powerBIBaseUrl: 'https://app.powerbi.com/reportEmbed',
  powerBIReports: {
    baseCaseReportId: '16b1e817-7f4a-4928-a813-735d4bcb7839',
    baseCaseConfig:
      'eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
    comparisionReportId: '5a3440d7-ad9e-4b34-8692-56779442cae4',
    comparisionReportConfig:
      'eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
    dataValidationReportId: 'bf2ba41e-b07f-48ec-b1b2-f9f8dd8b036c',
    dataValidationConfig:
      'eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
    resultReportId: 'aac2795d-4418-44a0-9c91-463fc198315c',
    resultConfig:
      'eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
    aopReportId: 'c9b75e6f-120d-44c4-a8de-777d074acc00',
    aopReportConfig:
      'eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
    gasoilsComparisionReportId: 'dc19f5a9-4d86-40d4-a5d5-ddc6976ad4bf',
    gasoilsComparisionReportConfigConfig:
      'eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXVzLWVhc3QyLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9',
    richmondComparisionReportId: 'deecabbe-1e7a-496c-88f1-f866828586cc',
    elsegundComparisionReportId: '932ec98a-42c3-49e0-948e-5099b9a95908',
    baseOilsComparisionReportId: '2a335373-f6f4-4014-97ad-c82824993b54',
    baseOilsRICComparisionReportId: '34a3e794-06c5-422b-a20d-f5d66c20cfb0',
  },
  powerBIEmbedTokenUrl: 'https://api.powerbi.com/v1.0/myorg/GenerateToken',
  baseCaseUrl: '/api/cases',
  optimizerCaseUrl: '/api/caseruns',
  adminUrl: '/api/admin',
  masterDataLocationsUrl: '/api/masterdata/locations',
  masterDataProductsUrl: '/api/masterdata/products',
  marketElasticityUrl: 'api/mktElasticity',
  marketElasticityV2Url: 'api/mktElasticity/v2',
  marketElasticityPublishUrl: 'api/mktElasticity/published',
  baseCaseColumnDataUrl: '../../assets/baseCaseColumnData.json',
  optimizerCaseColumnDataUrl: '../../assets/optimizerCaseColumnData.json',
  homePageRightColumnDataUrl: '../../assets/homeRightColumnData.json',
  refineryPageColumnDataUrl: '../../assets/refineryColumnData.json',
  refineryIsoMaxColumnDataUrl: '../../assets/refineryIsoMaxColumnData.json',
  locationPageColumnDataUrl: '../../assets/locationColumnData.json',
  locationPageDockColumnDataUrl: '../../assets/locationDockColumnData.json',
  thirdpartyPageColumnDataUrl: '../../assets/thirdPartyColumnData.json',
  refVesselEconPageColumnDataUrl: '../../assets/refVesselEconColumnData.json',
  terminalCostPageColumnDataUrl: '../../assets/terminalCostColumnData.json',
  storageTierPageColumnDataUrl: '../../assets/storageTierColumnData.json',
  pipelinePageColumnDataUrl: '../../assets/pipeLineColumnData.json',
  domesticPageColumnDataUrl: '../../assets/domesticColumnData.json',
  internationalPageColumnDataUrl: '../../assets/internationalColumnData.json',
  doConstantPageColumnDataUrl: '../../assets/DO-constantColumnData.json',
  transportationVesselIntColumnDataUrl:
    '../../assets/transportationVesselCostIntColumnData.json',
  transportationVesselDomColumnDataUrl:
    '../../assets/transportationVesselCostDomColumnData.json',
  domesticVesselColumnDataUrl:
  '../../assets/domesticVesselColumnData.json',
  mogasColumnDataUrl: '../../assets/mogasColumnData.json',
  avgasColumnDataUrl: '../../assets/avgasColumnData.json',
  mogasRicEseColumnDataUrl: '../../assets/mogasRicEseColumnData.json',
  lcoColumnDataUrl: '../../assets/lcoColumnData.json',
  hcoColumnDataUrl: '../../assets/hcoColumnData.json',
  mogasGradeColumnUrl: '../../assets/mogasGradeColumns.json',
  mogasGradeColumnDataUrl: '../../assets/mogasGradeColumnData.json',
  lsvgoColumnDataUrl: '../../assets/lsvgoColumnData.json',
  hcgoColumnDataUrl: '../../assets/hcgoColumnData.json',
  hsvgoColumnDataUrl: '../../assets/hsvgoColumnData.json',
  baseoilsColumnDataUrl: '../../assets/baseoilsColumnData.json',
  ricmondBaseoilsColumnDataUrl: '../../assets/ricmondBaseoilsColumnData.json',
  vcoSaveSnapshotUrl: '/api/mktElasticity',
  vcoSaveSnapshotV2Url: '/api/mktElasticity/v2',
  vcoPublishSnapshotUrl: '/api/mktElasticity/v2/publish/scenario',
  vcoSnapshotUrl: 'api/mktElasticity/snapshots',
  vcoPublishHistoryUrl: 'api/mktElasticity/v2',
  vcoGraphDataUrl: 'api/mktElasticity/chart',
  pricePageColumnDataUrl: '../../assets/priceColumnData.json',
  ccaDataUrl: 'api/cca',
  ccaColumnDefsUrl: 'api/ccacolumns',
  tswNominationUrl: 'api/tswnomination',  
  doUserPermissionUrl: 'api/User/Permissions',
  agGridLicenceKey:
    "Chevron_Enterprise_agGrid_license_multi_5_Devs__7_January_2021_[v2]_MTYwOTk3NzYwMDAwMA==7c0a7213cb31885c31d9b0357a052635",
  hideNominationsMenuFromLATAM: false,
  hideETBFromLATAM: false
};
