  <app-notification></app-notification>
  <app-nav-menu></app-nav-menu>
  <div>    
    <app-top-nav-bar></app-top-nav-bar>    
    <app-breadcrumb></app-breadcrumb>
    <router-outlet> </router-outlet>
  </div>
  <footer>
    <app-footer></app-footer>
  </footer>
