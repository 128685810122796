<nav class="navbar sticky-top flex-md-nowrap p-0 top-bar">
  <div class="col-sm-4">
    <!-- <h3 class="font-weight-bold mt-2">Distribution Optimizer</h3>        -->
    &nbsp;
  </div>
  <div class="navbar-brand col-sm-3 col-md-2 mr-0 d-inline-flex">    
    <a href="javascript:void(0)" routerLink="/">
      <img
        src="./../../../../assets/images/logo_with_name.png"        
      />
    </a>    
  </div>
  <div>
    <nav class="my-2 my-md-0 mr-md-3">
      <div>
        <em class="fas fa-user"> </em>
        <strong class="strong_css">{{ displayName }}</strong>
      </div>
      <div *ngIf="loginedRefinery">
        <em> Refinery : </em>
        <strong class="strong_css">{{loginedRefinery}}</strong>
      </div>
    </nav>
  </div>
</nav>