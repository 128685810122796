import { Component } from '@angular/core';

@Component({
  selector: 'app-cell-highlighter',
  templateUrl: './cell-highlighter.component.html',
  styleUrls: ['./cell-highlighter.component.scss'],
})
export class CellHighlighterComponent {
  params;
  isHighlighted = false;
  changed = false;
  constructor() {
     // This is intentional
  }
  agInit(params: any): void {
    this.params = params;
    if (this.params.data && this.params.data !== '') {
      this.changed =
        typeof this.params.data[this.params.colDef.field] === 'object' &&
        this.params.data[this.params.colDef.field] !== null
          ? this.params.data[this.params.colDef.field].changed
          : false;

      if (
        !(
          typeof this.params.data[this.params.colDef.field] === 'object' &&
          this.params.data[this.params.colDef.field] !== null &&
          Object.keys(this.params.data[this.params.colDef.field]).includes(
            'orgVal'
          )
        )
      ) {
        const orgVal = this.params.value;
        const newObj = {
          value: orgVal,
          orgVal: orgVal,
          changed: this.changed,
        };
        this.params.data[this.params.colDef.field] = newObj;
      }

      if (!this.params.data[this.params.colDef.field]) {
        this.params.data[this.params.colDef.field] = {
          value: this.params.value,
          orgVal: this.params.value,
        };
      }
      this.isHighlighted = this.isHighlight();
    }
  }

  refresh(params: any): boolean {
    this.params = params;
    this.isHighlighted = this.isHighlight();
    
    return true;
  }

  isHighlight() {
    if (
      this.params.data &&
      this.params.data[this.params.colDef.field] &&
      typeof this.params.data[this.params.colDef.field] === 'object'
    ) {
      return (
        this.params.data[this.params.colDef.field]['value'] !=
          this.params.data[this.params.colDef.field]['orgVal'] || this.changed
      );
    }
    return false;
  }
}
