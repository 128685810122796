import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { NotificationComponent } from './notification/notification.component';
import { RouterModule } from '@angular/router';
import { SidenavComponent } from './sidenav/sidenav.component';
import { TopNavBarComponent } from './top-nav-bar/top-nav-bar.component';
import { FooterComponent } from './footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { MaterialModule } from '../core/material.module';

@NgModule({
  declarations: [
    NotificationComponent,
    NavMenuComponent,
    SidenavComponent,
    TopNavBarComponent,
    FooterComponent,    
  ],
  imports: [CommonModule, RouterModule, SharedModule,MaterialModule],
  exports: [
    NotificationComponent,
    NavMenuComponent,
    SidenavComponent,
    TopNavBarComponent,
    FooterComponent,
    MaterialModule,
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class CoreModule {}
