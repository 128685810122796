import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauthorized-access',
  templateUrl: './unauthorized-access.component.html',
  styleUrls: ['./unauthorized-access.component.css']
})
export class UnauthorizedAccessComponent implements OnInit {

  constructor() { 
     // This is intentional
  }

  ngOnInit() {
     // This is intentional
  }

}
