import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpService } from './common/http.service';

@Injectable({
  providedIn: 'root',
})
export class TransportationService {
  constructor(private http: HttpService) {
    this.http.getData(
      'https://api.powerbi.com/v1.0/myorg/reports/8b218841-1c59-4396-8c74-48d9c340a22f'
    );
  }
  getRowData(id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/transport`;
    return this.http.getData(url);
  }

  getGetPipelineNomsData(id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/pipelinenominations`;
    return this.http.getData(url);
  }
  getGetVesselNomsData(id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/vesselnominations`;
    return this.http.getData(url);
  }

  getColumnData(tabName): Observable<any> {
    let url;
    switch (tabName) {
      case 'pipeline':
        url = `${environment.pipelinePageColumnDataUrl}`;
        break;
      case 'domestic':
        url = `${environment.domesticPageColumnDataUrl}`;
        break;
      case 'international':
        url = `${environment.internationalPageColumnDataUrl}`;
        break;
      default:
        break;
    }

    return this.http.getData(url);
  }

  getProductOptions(): Observable<any> {
    const url = environment.masterDataProductsUrl;
    return this.http.getData(url);
  }

  saveData(data, id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/transport`;
    return this.http.postData(url, data);
  }
  savePipelineData(data, id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/pipelinenominations`;
    return this.http.postData(url, data);
  }
  saveVesselData(data, id): Observable<any> {
    const url = `${environment.baseCaseUrl}/${id}/vesselnominations`;
    return this.http.postData(url, data);
  }
}
