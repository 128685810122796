import { TabSection } from '../model/TabSection';
import { PageStateMapping } from './PageStatesMapping';
export default class StateProvider {
  static setAllState(allStates: any) {
    this.appStates = allStates;
    localStorage.setItem(StateProvider.appStateKey, JSON.stringify(allStates));
  }
  static appStateKey = 'appState';
  static appStates = (() => {
    return JSON.parse(localStorage.getItem(StateProvider.appStateKey)) || {};
  })();

  static setState(state: any) {
    StateProvider.appStates[state.name] = state;
    localStorage.setItem(
      StateProvider.appStateKey,
      JSON.stringify(StateProvider.appStates)
    );
  }

  static getState<TState>(tabSection: TabSection): TState {
    let currentState = StateProvider.appStates[
      TabSection[tabSection]
    ] as TState;

    if (currentState == undefined || currentState == null) {
      currentState = StateProvider.loadDefault<TState>(tabSection);
    }

    return currentState as TState;
  }

  static getAllState(): {} {
    return StateProvider.appStates;
  }

  static loadDefault<TState>(tabSection: TabSection): TState {
    let stateType = PageStateMapping[tabSection.toString()];
    const newState = new stateType();
    newState['name'] = TabSection[tabSection];
    return newState;
  }
}
