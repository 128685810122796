<div class="mainContainer p-3">
  <ul class="nav nav-pills mb-3 pills">
    <span [ngStyle]="{ display: 'inline-flex' }">
      <li class="nav-item">
        <a
          class="nav-link nav-font"
          routerLink="/case"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >My Cases</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link nav-font"
          routerLink="/published-cases"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >Published Cases</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link cursor nav-font"
          href="javascript:void(0)"
          (click)="showNewCaseForm()"
          >Create Case</a
        >
      </li>
      <li class="nav-item">
        <a
          class="nav-link nav-font"
          routerLink="/optimizer-runs"
          routerLinkActive="active"
          [routerLinkActiveOptions]="{ exact: true }"
          >Optimizer Runs</a
        >
      </li>
    </span>

    <app-searchbox [searchText]="state.searchText"></app-searchbox>
  </ul>

  <app-panel>
    <ag-grid-angular
      style="height: 100%; border: solid 1px"
      class="ag-theme-balham"
      [rowData]="rowData"
      [columnDefs]="columnDefs"
      (gridReady)="onGridReady($event)"
      [gridOptions]="gridOptions"
      [loadingOverlayComponent]="overlayComponent"
    >
    </ag-grid-angular>
  </app-panel>
  <!--- modal-->

  <div
    class="modal left fade"
    id="caseForm"
    tabindex=""
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-md" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" *ngIf="caseModel.isCopy; else other_header">
            Copy Case Information
          </h5>
          <ng-template #other_header>
            <h5 class="modal-title">
              {{
                caseModel.id != null
                  ? 'Update Case Information'
                  : 'New Case Information'
              }}
            </h5>
          </ng-template>

          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col">
                <form #myform="ngForm">
                  <fieldset disabled>
                    <div class="form-group">
                      <label for="disabledTextInput">{{
                        caseModel.isCopy ? 'Copied From' : 'Base Reference'
                      }}</label>
                      <input
                        type="text"
                        id="disabledTextInput"
                        class="form-control"
                        placeholder="Disabled input"
                        [(ngModel)]="
                          caseModel.isCopy
                            ? caseModel.copyTitle
                            : caseModel.baseCaseDateString
                        "
                        name="baseCaseDateString"
                      />
                    </div>
                  </fieldset>
                  <div class="form-group">
                    <label for="formGroupExampleInput">Title</label>
                    <input
                      type="text"
                      class="form-control"
                      id="formGroupExampleInput"
                      placeholder="Provide Unique Case Title"
                      [(ngModel)]="this.caseModel.title"
                      name="title"
                      appForbiddenName
                      [forbiddenName]="caseModel.copyTitle"
                      required
                      #name="ngModel"
                    />
                    <small id="passwordHelpInline" class="text-muted">
                      (required)
                    </small>
                    <div *ngIf="name.errors && name.errors.forbiddenName">
                      <small [style.color]="'red'"
                        >Name cannot be {{ caseModel.copyTitle }}</small
                      >
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="formGroupExampleInput">Status</label>
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {{ caseModel.status }}
                      </button>
                      <div
                        class="dropdown-menu option"
                        aria-labelledby="dropdownMenuButton"
                      >
                        <a
                          *ngFor="let option of status"
                          class="dropdown-item"
                          href="javascript:void(0)"
                          (click)="changeStatus(option)"
                          >{{ option }}</a
                        >
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="formGroupExampleInput2">Description</label>
                    <textarea
                      class="form-control"
                      [(ngModel)]="this.caseModel.description"
                      name="description"
                    ></textarea>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button
            type="button"
            class="btn btn-primary"
            (click)="saveCaseChanges()"
            [disabled]="myForm.invalid"
          >
            Save changes
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal"
  id="casemodal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="myModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title" id="myModalLabel">Case Information</h4>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="recipient-name" class="col-form-label">Title:</label>

          <input
            type="text"
            class="form-control"
            [value]="modal.title"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="recipient-name" class="col-form-label"
            >Description:</label
          >
          <textarea
            class="form-control"
            [value]="modal.description"
            readonly
          ></textarea>
        </div>
        <div class="form-group">
          <label for="recipient-name" class="col-form-label">
            Created By:</label
          >
          <input
            type="text"
            class="form-control"
            [value]="modal.createdBy"
            readonly
          />
        </div>
        <div class="form-group">
          <label for="recipient-name" class="col-form-label">
            Created On:</label
          >
          <input
            type="text"
            class="form-control"
            [value]="modal.createdOn"
            readonly
          />
        </div>
      </div>
    </div>
  </div>
</div>

<app-confirmation
  #mod
  [subTitle]="'Are you sure you want to delete this case?'"
  [buttonPrimary]="'Yes'"
  [buttonSecondary]="'No'"
  (clickHandler)="removeCase($event)"
></app-confirmation>
