import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { EventService } from 'src/app/services/common/event.service';
import * as pbi from 'powerbi-client';
import { environment } from 'src/environments/environment';
import { CalAngularModule, CalAngularService } from '@cvx/cal-angular';
//import { MsalService } from '@azure/msal-angular';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit, OnDestroy {
  @ViewChild('reportContainer', { static: true }) reportContainer: ElementRef;
  tabName;
  id;
  screenName = 'Base Case Report';
  subscription: Subscription;

  constructor(
    private event: EventService,
    private authService: CalAngularService,
    private route: ActivatedRoute
  ) {
    
  }

  ngOnInit() {
    this.id = window.sessionStorage.getItem('reportsCaseID');
    this.route.url.subscribe((url) => {
      this.tabName = url[0].path;
      //TODO Report
      // this.subscription =  this.authService
      //                           .acquireTokenSilent({
      //                             scopes: ['https://analysis.windows.net/powerbi/api/.default'],
      //                           })
      //                           .subscribe((tokenResponse) => this.showReport(tokenResponse.accessToken));
    });
  }

  showReport(accessToken) {
    let reportId;
    let config; 
    let embedUrl;    
    if (this.tabName === 'results') {
      this.screenName = 'Results';
      reportId = environment.powerBIReports.resultReportId;
      config = environment.powerBIReports.resultConfig;   
      embedUrl = `${environment.powerBIBaseUrl}?reportId=${reportId}&config=${config}&filter=CasesUserCases/UserCaseId eq ${this.id}`;   
    } else if (this.tabName ==='base-case-report'){
      this.screenName = 'Base Case Report';
      reportId = environment.powerBIReports.baseCaseReportId;
      config = environment.powerBIReports.baseCaseConfig;
      embedUrl = `${environment.powerBIBaseUrl}?reportId=${reportId}&config=${config}`;
    }
    else {
      this.screenName = 'Data Validation Report';
      reportId = environment.powerBIReports.dataValidationReportId;
      config = environment.powerBIReports.dataValidationConfig;
      embedUrl = `${environment.powerBIBaseUrl}?reportId=${reportId}&config=${config}`;
    }   
   
    const embedReportId = reportId;
    const configuration = {
      type: 'report',
      tokenType: pbi.models.TokenType.Aad,
      accessToken,
      embedUrl: embedUrl,
      id: embedReportId,
      permissions: pbi.models.Permissions.All,
      viewMode: pbi.models.ViewMode.View      
    };
    const reportContainer = this.reportContainer.nativeElement;
    const powerbi = new pbi.service.Service(
      pbi.factories.hpmFactory,
      pbi.factories.wpmpFactory,
      pbi.factories.routerFactory
    );
    const report = powerbi.embed(reportContainer, configuration);
    report.off('loaded');
    report.on('loaded', () => {
      console.log('Loaded');
    });
    report.on('error', () => {

    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
