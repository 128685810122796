<app-mulitbutton
  (clickHandler)="handleMultipleClick($event)"
  [showCaseDetails]="true"
  [screenName]="screenName"
  [searchText]="state.searchText"
></app-mulitbutton>
<ul class="nav nav-pills mb-3 pills">
  <span [ngStyle]="{ display: 'inline-flex' }">
    <li *ngFor="let product of productsList" class="nav-item">
      <a
        class="nav-link nav-font blue"
        [ngClass]="{ active: tabName === product }"
        (click)="toggleData(product)"
        >{{ product }}</a
      >
    </li>
  </span>
</ul>
<app-panel>
  <ag-grid-angular
    style="height: 100%; border: solid 1px"
    class="ag-theme-balham"
    [rowData]="rowData"
    [columnDefs]="columnDefs"
    (gridReady)="onGridReady($event)"
    [gridOptions]="gridOptions"
    [context]="agHelper"
    [undoRedoCellEditing]="true"
    [undoRedoCellEditingLimit]="5"
    [rowGroupPanelShow]="'always'"
    (cellValueChanged)="onCellValueChanged($event)"
    [loadingOverlayComponent]="overlayComponent"
    [groupRowAggNodes]="groupRowAggNodes.bind(this)"
  >
  </ag-grid-angular>
</app-panel>
