import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { AgGridModule } from 'ag-grid-angular';
import { SharedModule } from '../shared/shared.module';
import { LayoutComponent } from './layout/layout.component';
import { SupplyComponent } from './supply/supply.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IndexComponent } from './index/index.component';
import { LocationComponent } from './storage/storage.component';
import { ThirdPartyComponent } from './third-party/third-party.component';

import { OverlayComponent } from '../shared/common/overlay/overlay.component';
import { CellHighlighterComponent } from '../shared/grid-components/cell-highlighter/cell-highlighter.component';
import { TransportationComponent } from './transportation/transportation.component';
import { ReportsComponent } from './reports/reports.component';
import { DropdownComponent } from '../shared/grid-components/dropdown/dropdown.component';
import { DocksComponent } from './docks/docks.component';
import { HomeComponent } from './home/home.component';
import { CaseActionComponent } from '../shared/grid-components/case-action/case-action.component';
import { DateformatComponent } from '../shared/grid-components/dateformat/dateformat.component';
import { PriceComponent } from './price/price.component';
import { OptimizerActionComponent } from '../shared/grid-components/optimizer-action/optimizer-action.component';
import { VesselActionComponent } from '../shared/grid-components/vessel-action/vessel-action.component';

@NgModule({
  declarations: [
    LayoutComponent,
    SupplyComponent,
    IndexComponent,
    LocationComponent,
    ThirdPartyComponent,
    TransportationComponent,
    ReportsComponent,
    DocksComponent,
    HomeComponent,
    PriceComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    AgGridModule.withComponents([
      CellHighlighterComponent,
      OverlayComponent,
      CaseActionComponent,
      OptimizerActionComponent,
      DateformatComponent,
      VesselActionComponent,
    ]),
  ],
  entryComponents: [OverlayComponent, DropdownComponent],
})
export class BaseCaseModule {}
