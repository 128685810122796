
    
      <div class="form-group" [id]="'dateRangePicker_'+id">
        <!-- <i class="material-icons">expand_more</i> -->
        <label> {{label}} </label>
        <input class="form-control" [id]="'dateRange_'+id" [placeholder]="placeholder" name="dp" [value]="formattedDateRange" ngbDatepicker
          #dp="ngbDatepicker" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" [autoClose]="false"
          (click)="dp.toggle(); isOpen = !isOpen"
          [disabled]="readOnly" />
      </div>
    
  
  <ng-template #t let-date="date" let-focused="focused">
    <span class="custom-day" [class.focused]="focused" [class.range]="isFrom(date) || isTo(date) || isInside(date) || isHovered(date)"
      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = toDate(date)" (mouseleave)="hoveredDate = null" >
      {{ date.day }}
    </span>
  </ng-template>