import { StorageAvailState } from './case-input-screens/storageAvailState';
import { ContractState } from './case-input-screens/contractState';
import { PipelineState } from './case-input-screens/pipelineState';
import { RefineryState } from './case-input-screens/refineryState';
import { JetState } from './market-elasticity-states/jetState';
import { LsdState } from './market-elasticity-states/lsdState';
import { MogasState } from './market-elasticity-states/mogasState';
import { RdsState } from './market-elasticity-states/rdsState';
import { UlsdState } from './market-elasticity-states/ulsdState';
import { DockState } from './case-input-screens/dockAvailState';
import { CaseManagementState } from './case-input-screens/caseManagementState';
import { PriceState } from './case-input-screens/priceState';
import { VesselState } from './admin-input-screens/vesselState';
import { PulDomesticState } from './market-elasticity-states/puldomesticState';
import { RulDomesticState } from './market-elasticity-states/ruldomesticState';
import { PulExportState } from './market-elasticity-states/pulexportState';
import { RulExportState } from './market-elasticity-states/rulexportState';
import { LcoState } from './market-elasticity-states/lcoState';
import { ccaPlanningState } from './latam-states/ccaPlanningState';
import { elasticityState } from './market-elasticity-states/elasticityState';

export const PageStateMapping = {
  1: MogasState,
  2: JetState,
  3: LsdState,
  4: UlsdState,
  5: RdsState,
  18: PulDomesticState,
  19: RulDomesticState,
  20: PulExportState,
  21: RulExportState,
  22: LcoState,
  23: ccaPlanningState,
  24: elasticityState,

  6: RefineryState,
  7: PipelineState,
  8: PipelineState,
  9: PipelineState,
  10: StorageAvailState,
  11: DockState,
  12: ContractState,
  13: CaseManagementState,
  14: PriceState,
  15: RefineryState,
  16: CaseManagementState,
  17: VesselState
};
