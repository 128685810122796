import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { NgChartsModule, ThemeService } from 'ng2-charts';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BaseCaseModule } from './basecase/basecase.module';
import { CoreModule } from './core/core.module';
import { AppRoutingModule } from './app-routing.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { AgGridModule } from 'ag-grid-angular';
import 'ag-grid-enterprise';
import { environment } from 'src/environments/environment';
import { HttpErrorInterceptor } from './services/httpErrorInterceptor';
import { UnauthorizedAccessComponent } from './unauthorized-access.component';
import { AuthTokenServiceInterceptor } from './services/auth-token-service.interceptor';
import { CalAngularModule } from '@cvx/cal-angular';
// import { MsalModule, MsalRedirectComponent, MsalGuard, MsalInterceptor } from '@azure/msal-angular'; // Import MsalInterceptor
import { InteractionType, PublicClientApplication } from '@azure/msal-browser'
import { SharedModule } from './shared/shared.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './core/material.module';
import {BreadcrumbComponent} from './core/breadcrumb/breadcrumb.component';
import { StepperComponent } from './shared/common/stepper/stepper.component';
import { MatIconModule } from "@angular/material/icon";
import { NominationsManagerModule } from './nominations-manager/nominations-manager.module';


const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;
@NgModule({
  declarations: [AppComponent, UnauthorizedAccessComponent,BreadcrumbComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgChartsModule,
    MaterialModule,
    MatIconModule,       
    CalAngularModule.forRoot({
      "autoSignIn": true,
      "popupForLogin": false,
      "cacheLocation": "localStorage",
      "instance": "https://login.microsoftonline.com/",
      "tenantId": "fd799da1-bfc1-4234-a91c-72b3a1cb9e26", // This is your tenant ID
      "clientId": environment.clientId,
      // redirectUri will need to match a redirectUri setup in your app registration
      "redirectUri": environment.redirectUri,
      "oidcScopes": ["openid", "profile", "User.Read","offline_access"],
      "graphScopes": ["User.Read", "User.Read.All"]
    }), 
    // MsalModule.forRoot( new PublicClientApplication({
    //   auth: {
    //       clientId: environment.clientId, // This is your client ID
    //       authority: environment.authority, // This is your tenant ID
    //       redirectUri: environment.redirectUri, // This is your redirect URI
    //   },
    //   cache: {
    //     cacheLocation: 'localStorage',
    //     storeAuthStateInCookie: isIE,
    //   }
    // }), {
    //   interactionType: InteractionType.Popup,
    //   authRequest: {
    //     scopes: [environment.audience + '/user_impersonation']
    //     }
    // }, {
    //   interactionType: InteractionType.Popup, // MSAL Interceptor Configuration
    //   protectedResourceMap: new Map([ 
    //       ['/api', [environment.audience + '/user_impersonation']],
    //       ['https://graph.microsoft.com/v1.0/me', ['user.read']],
    //       [
    //         'https://app.powerbi.com/reportEmbed',
    //         ['https://analysis.windows.net/powerbi/api/.default'],
    //       ]       
    //   ])
    // }),
    CoreModule,
    SharedModule,
    BaseCaseModule,
    NominationsManagerModule,
    NgbModule,
    AppRoutingModule,
    AgGridModule.withComponents([]),
    BrowserAnimationsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthTokenServiceInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    //ThemeService,
    //MsalGuard
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
