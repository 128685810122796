import { Component, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  showZoomer=true;
  showStepper = true;
  constructor(private router:Router) { 
    this.router.events.subscribe((event:Event)=> {
      if (event instanceof NavigationEnd){
        let evt: NavigationEnd = event;

        if (evt.url.indexOf('market-elasticity') !== -1) {
          this.showStepper = true;
          if (evt.url.indexOf('graphs') !== -1 || evt.url.indexOf('comparision') !== -1) {
            this.showStepper = false;
          }
        }
        else
        {
          this.showStepper = false;          
        }        
        if (evt.url.indexOf('graphs') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('data-validation-report') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('results') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('aop') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('comparision') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('case') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('refvessel') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('terminalcost') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('storagetanktier') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('doconstant') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('domesticvessel') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('vesselcostdomestic') !== -1) {
          this.showZoomer = false;
        } else if (evt.url.indexOf('vesselcostinternational') !== -1) {
          this.showZoomer = false;

        } else {
          this.showZoomer = true;
        }
      }
    });
  }
  ngOnInit(): void {
  }
} 