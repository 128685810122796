import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent {
  params;
  constructor() {
     // This is intentional
  }

  agInit(params): void {
    this.params = params;
  }
}
