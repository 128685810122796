import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { StateComponent } from 'src/app/core/StateComponent';
import { AgHelper } from 'src/app/services/agHelper';
import { EventService } from 'src/app/services/common/event.service';
import { NotificationService } from 'src/app/services/common/notification.service';
import { HomeService } from 'src/app/services/home.service';
import { LocationService } from 'src/app/services/location.service';
import { TabSection } from 'src/app/services/model/TabSection';
import { OptimizerService } from 'src/app/services/optimizer.service';
import { DockState } from 'src/app/services/States/case-input-screens/dockAvailState';
import { OverlayComponent } from 'src/app/shared/common/overlay/overlay.component';
import { CellHighlighterComponent } from 'src/app/shared/grid-components/cell-highlighter/cell-highlighter.component';
import { DropdownEditorComponent } from 'src/app/shared/grid-components/dropdown-editor/dropdown-editor.component';
import { DropdownComponent } from 'src/app/shared/grid-components/dropdown/dropdown.component';
import appConstant from '../../app.constants';
@Component({
  selector: 'app-docks',
  templateUrl: './docks.component.html',
  styleUrls: ['./docks.component.scss'],
})
export class DocksComponent
  extends StateComponent<DockState>
  implements OnInit, OnDestroy {
  columnDefs = [];
  rowData = [];
  filteredData = [];
  gridApi;
  gridColumnApi;
  gridOptions = {
    defaultColDef: {      
      resizable: true,
      filter: true,
      sortable: true,
      enableRowGroup: true,
    },
    frameworkComponents: {
      cellHighlighterComponent: CellHighlighterComponent,
      overlayComponent: OverlayComponent,
      dropdownComponent: DropdownComponent,
      dropdownEditorComponent: DropdownEditorComponent,
    },
  };
  agHelper = AgHelper;
  subscription: Subscription;
  dataToSave = [];
  dockArrayData: any;
  id;
  overlayComponent = 'overlayComponent';
  sideBar = this.agHelper.getFilterPanelConfig();
  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'center',
      },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };
  viewAction = {
    handleSearchChange: (data: any) => {
      this.setState({ searchText: data });
    },
    onFilterChanged(filterEvent) {
      const filters = filterEvent.api.filterManager.getFilterModel();
      this.setState({ filteredProps: filters }, true);
    },
    onColumnStateChanged(_event) {
      if (this.gridColumnApi) {
        const columnStates = this.gridColumnApi.getColumnState();
        this.setState({ columnState: columnStates }, true);
      }
    },
    onResetFilter: () => {
      this.clearGridHandlers();
      this.resetState();
      this.attachGridHandlers();
    },
  };
  screenName = appConstant.INPUT_SCREENS.DOCK_AVAILABILITY;
  constructor(
    private locationService: LocationService,
    private eventService: EventService,
    private homeService: HomeService,
    private optimizerService: OptimizerService,
    private router: Router,
    private notification: NotificationService
  ) {
    super();
    this.initState(TabSection.DockAvailInputScreen);
    this.getColumns();
    this.subscription = this.eventService.events$.subscribe((event) => {
      switch (event.name) {
        case 'search':
          this.viewAction.handleSearchChange(event.value);
          break;
      }
    });
  }

  ngOnInit() {
    this.id = window.sessionStorage.getItem('caseID');
    this.getData();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
  }

  getData() {
    this.clearGridHandlers();
    this.locationService.getDockRowData(this.id).subscribe((data) => {
      this.rowData = data.data;      
      this.setColumnState(this.state);
      this.attachGridHandlers();
      setTimeout(() => {
        this.filterBySearch(this.state.searchText);
      }, 0);
    });
  }

  getColumns() {
    this.locationService.getDockColumnData().subscribe((colData) => {
      this.columnDefs = colData;
      this.mapFilterToColumns();
      const availableCol = this.columnDefs.filter(
        (x) => x.field === 'available'
      )[0];
      availableCol.cellEditorParams = {
        values: [0, 100],
      };

      availableCol.cellRendererParams = {
        isDropdown: true,
      };
      availableCol.valueFormatter = (params) =>
        params.value && params.value.value;
    });
  }
  handleMultipleClick(event) {
    switch (event) {
      case 'save':
        this.onSaveData();
        break;
      case 'export':
        this.exportAsExcel();
        break;
      case 'optimize':
        if (this.canDeactivate())
          this.runOptimizer(this.id, this.homeService.getUserName());                  
        break;
      default:
        break;
    }
  }

  onSaveData() {
    if (this.dataToSave.length === 0) {
      this.notification.warn('No data to save');
      return;
    }
    this.notification.info('Saving...');
    this.locationService.saveData({ data: this.dataToSave }, this.id).subscribe(
      (_data) => {
        this.dataToSave = [];
        this.notification.success();
        this.gridApi.undoRedoService.clearStacks();
      },
      (err) => {
        this.notification.error(err);
      }
    );
  }

  runOptimizer(id: number, userName: string) {
    if (confirm('Are you sure you want to schedule an optimizer run?')) {
      this.optimizerService
        .runOptimizer(id, userName)          
        .subscribe((_payload) => {
          this.notification.success(
            'Optimizer run has been scheduled successfully'
          );
          this.router.navigate(['optimizer-runs']);
        },
        (err) => {
          this.notification.error(err);
        }
        );
    }
  }

  canDeactivate(): Observable<Boolean> | Promise<boolean> | boolean {
    if (this.dataToSave.length > 0) {
      window.alert(
        'There are unsaved changes on this page. please save to continue with optimizer'
      );      
      return false;
    } else {
      return true;
    }
  }

  onCellValueChanged(params) {
    const valueSet = params.data[params.colDef.field];
    if (valueSet.value != valueSet.orgVal) {
      const { dockDetailId } = params.data;
      if (
        !this.dataToSave.find((data) => {
          return (
            data.id == params.data.dockDetailId.value
          );
        })
      ) {
        this.dataToSave.push({
          id: +dockDetailId.value,
          available: valueSet.value,
        });
      } else {
        const objIndex = this.dataToSave.findIndex(
          (obj) =>
            obj.id == params.data.id.value
        );
        this.dataToSave[objIndex]['available'] = valueSet.value;
      }
    } else {
      const objIndex = this.dataToSave.findIndex(
        (obj) => obj.id === params.data.dockDetailId.value
      );
      this.dataToSave.splice(objIndex, 1);
    }
  }

  mapFilterToColumns() {
    this.columnDefs.forEach((col) => {
      if (['date'].includes(col.field)) {
        col.filter = 'agDateColumnFilter';
        col.filterParams = this.agHelper.getDateFilter();
      }
    });
  }

  filterBySearch = (value) => {
    let collection = this.rowData;
    if (!collection) {
      return;
    }

    if (value && value != '') {
      collection = collection.filter((item) =>
        this.agHelper.findSubstring(item.location.value, value)
      );
    }
    this.filteredData = collection;
    this.gridApi.setRowData(this.filteredData);
  };

  onStateChange(newValue: Partial<DockState>, _oldValue: Partial<DockState>) {
    if (newValue.searchText || newValue.searchText.length === 0) {
      this.filterBySearch(newValue.searchText);
    }
  }

  setColumnState(newValue, _isColumnStateSet = true) {
    if (newValue.columnState && newValue.columnState.length > 0) {
      this.gridColumnApi.setColumnState(newValue.columnState);
    } else if (newValue.columnState && newValue.columnState.length === 0) {
      this.gridColumnApi.resetColumnState();
    }
  }
  attachGridHandlers() {
    const handler = this.viewAction.onColumnStateChanged.bind(this);
    const filterChangeHandler = this.viewAction.onFilterChanged.bind(this);
    setTimeout(() => {
      this.gridOptions['onFilterChanged'] = filterChangeHandler;
      this.gridOptions['onVirtualColumnsChanged'] = handler;
      this.gridOptions['onGridColumnsChanged'] = handler;
      this.gridOptions['onColumnValueChanged'] = handler;
      this.gridOptions['onDisplayedColumnsChanged'] = handler;
      this.gridOptions['onColumnGroupOpened'] = handler;
    }, 5000);
  }
  clearGridHandlers() {
    this.gridOptions['onFilterChanged'] = null;
    this.gridOptions['onVirtualColumnsChanged'] = null;
    this.gridOptions['onGridColumnsChanged'] = null;
    this.gridOptions['onColumnValueChanged'] = null;
    this.gridOptions['onDisplayedColumnsChanged'] = null;
    this.gridOptions['onColumnGroupOpened'] = null;
  }

  exportAsExcel() {
    const params = {
      fileName: 'Dock Availability',
    };
    this.gridApi.exportDataAsExcel(params);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
