import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationService } from 'src/app/services/common/notification.service';
import { Router, NavigationStart } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit, OnDestroy {
  notification;
  notificationSubscription: Subscription;
  routeSubscription: Subscription;
  constructor(
    private router: Router,
    private notificationService: NotificationService
  ) {}

  ngOnInit() {
    this.notificationSubscription = this.notificationService
      .onAlert()
      .subscribe((noti) => {
        this.notification = noti;
        if (noti.autoClose) {
          setTimeout(() => this.removeAlert(noti), noti.timeout);
        }
      });

    // clear alerts on location change
    this.routeSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.removeAlert(this.notification);
      }
    });
  }
  removeAlert(_alert) {
    if (this.notification) {
      this.notification.type = -1;
    }
  }

  ngOnDestroy() {
    this.notificationSubscription.unsubscribe();
    this.routeSubscription.unsubscribe();
  }
}
