import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-optimizer-action',
  templateUrl: './optimizer-action.component.html',
  styleUrls: ['./optimizer-action.component.scss'],
})
export class OptimizerActionComponent {
  params;
  @Input() reportAvailable = true;
  constructor() {
     // This is intentional
  }

  agInit(params: any): void {
    this.params = params;
  }

  optimizerAction(action) {
    this.params.optimizerAction(action, this.params);
  }
}
