import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { HomeService } from 'src/app/services/home.service';
import { SettingsService } from 'src/app/services/settings.service';

@Component({
  selector: 'app-mulitbutton',
  templateUrl: './mulitbutton.component.html',
  styleUrls: ['./mulitbutton.component.scss'],
})
export class MulitbuttonComponent implements OnInit {
  id;
  title;
  createdBy;
  status;
  showSettings = true;
  pageIsNotBaseOil = true;
  @Input() showButton = { save: true, export: true, optimize: true, add: true, delete: false, settings: true };
  @Input() showCaseDetails;
  @Input() screenName;
  @Input() showSearchbar = true;
  @Input() searchText = '';
  @Input() flowName = '';
  @Output() clickHandler = new EventEmitter<any>();
  constructor(
    private homeService: HomeService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    let path = location.pathname;
    this.id = window.sessionStorage.getItem('caseID');
    this.pageIsNotBaseOil = path.includes('market-elasticity')
    this.settingsService.showSettingsSubject.subscribe(bool => {
      this.showSettings = bool && this.pageIsNotBaseOil;
    })
    this.showSettings = this.pageIsNotBaseOil;
  }
  onClick(val: string) {
    console.log("onClick:", val);
    this.clickHandler.emit(val);
  }
  getData() {
    this.homeService.getCaseData(this.id).subscribe((payload) => {
      this.title = payload.title;      
      this.createdBy = payload.createdBy;
      this.status = payload.status;
    });
  }
}
