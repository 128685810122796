import { Component, OnDestroy, OnInit } from '@angular/core';
import { CellHighlighterComponent } from '../../shared/grid-components/cell-highlighter/cell-highlighter.component';
import { OverlayComponent } from '../../shared/common/overlay/overlay.component';
import { AgHelper } from 'src/app/services/agHelper';
import { Observable, Subscription } from 'rxjs';
import { LocationService } from 'src/app/services/location.service';
import { NotificationService } from 'src/app/services/common/notification.service';
import appConstant from '../../app.constants';
import { StorageAvailState } from 'src/app/services/States/case-input-screens/storageAvailState';
import { StateComponent } from 'src/app/core/StateComponent';
import { TabSection } from 'src/app/services/model/TabSection';
import { EventService } from 'src/app/services/common/event.service';
import { HomeService } from 'src/app/services/home.service';
import { OptimizerService } from 'src/app/services/optimizer.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-location',
  templateUrl: './storage.component.html',
  styleUrls: ['./storage.component.scss'],
})
export class LocationComponent
  extends StateComponent<StorageAvailState>
  implements OnInit, OnDestroy {
  columnDefs = [];
  rowData = [];
  filteredData = [];
  dockcolumnDefs = [];
  dockrowData = [];
  gridApi;
  gridColumnApi;
  gridOptions = {
    defaultColDef: {
      flex: 1,
      resizable: true,
      filter: true,
      sortable: true,
      enableRowGroup: true,
    },
    frameworkComponents: {
      cellHighlighterComponent: CellHighlighterComponent,
      overlayComponent: OverlayComponent,
    },
    groupMultiAutoColumn: true,
    groupIncludeFooter: true,
    groupIncludeTotalFooter: false,
  };
  agHelper = AgHelper;
  subscription: Subscription;
  dataToSave = [];
  id;
  sideBar = this.agHelper.getFilterPanelConfig();
  overlayComponent = 'overlayComponent';
  statusBar = {
    statusPanels: [
      {
        statusPanel: 'agTotalAndFilteredRowCountComponent',
        align: 'left',
      },
      {
        statusPanel: 'agTotalRowCountComponent',
        align: 'center',
      },
      { statusPanel: 'agFilteredRowCountComponent' },
      { statusPanel: 'agSelectedRowCountComponent' },
      { statusPanel: 'agAggregationComponent' },
    ],
  };

  viewAction = {
    handleSearchChange: (data: any) => {
      this.setState({ searchText: data });
    },
    onFilterChanged(filterEvent) {
      const filters = filterEvent.api.filterManager.getFilterModel();
      this.setState({ filteredProps: filters }, true);
    },
    onColumnStateChanged(_event) {
      if (this.gridColumnApi) {
        const columnStates = this.gridColumnApi.getColumnState();
        this.setState({ columnState: columnStates }, true);
      }
    },
    onResetFilter: () => {
      this.clearGridHandlers();
      this.resetState();
      this.attachGridHandlers();
    },
  };
  screenName = appConstant.INPUT_SCREENS.STORAGE_TANKS;
  constructor(
    private locationService: LocationService,
    private eventService: EventService,
    private homeService: HomeService,
    private optimizerService: OptimizerService,
    private router: Router,
    private notification: NotificationService
  ) {
    super();
    this.initState(TabSection.StorageAvailInputScreen);
    this.getColumns();
    this.subscription = this.eventService.events$.subscribe((event) => {
      switch (event.name) {
        case 'search':
          this.viewAction.handleSearchChange(event.value);
          break;
      }
    });
  }

  ngOnInit() {
    this.id = window.sessionStorage.getItem('caseID');
    this.getData();
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.showLoadingOverlay();
  }

  getData() {
    this.clearGridHandlers();
    this.locationService.getRowData(this.id).subscribe((data) => {
      this.rowData = data.data;
      this.setColumnState(this.state);
      this.attachGridHandlers();
      setTimeout(() => {
        this.filterBySearch(this.state.searchText);
      }, 0);
    });
  }

  getColumns() {
    this.locationService.getColumnData().subscribe((colData) => {
      this.columnDefs = colData;
      this.mapFilterToColumns(this.columnDefs);
    });
  }
  handleMultipleClick(event) {
    switch (event) {
      case 'save':
        this.onSaveData();
        break;
      case 'export':
        this.exportAsExcel();
        break;
      case 'optimize':
        if (this.canDeactivate())
          this.runOptimizer(this.id, this.homeService.getUserName());                  
        break;
      default:
        break;
    }
  }

  onSaveData() {
    if (this.dataToSave.length === 0) {
      this.notification.warn('No data to save');
      return;
    }
    this.notification.info('Saving...');
    this.locationService.saveData({ data: this.dataToSave }, this.id).subscribe(
      (_data) => {
        this.dataToSave = [];
        this.notification.success();
        this.gridApi.undoRedoService.clearStacks();
      },
      (err) => {
        this.notification.error(err);
      }
    );
  }

  runOptimizer(id: number, userName: string) {
    if (confirm('Are you sure you want to schedule an optimizer run?')) {
      this.optimizerService
        .runOptimizer(id, userName)          
        .subscribe((_payload) => {
          this.notification.success(
            'Optimizer run has been scheduled successfully'
          );
          this.router.navigate(['optimizer-runs']);
        },
        (err) => {
          this.notification.error(err);
        }
        );
    }
  }

  canDeactivate(): Observable<Boolean> | Promise<boolean> | boolean {
    if (this.dataToSave.length > 0) {
      window.alert(
        'There are unsaved changes on this page. please save to continue with optimizer'
      );      
      return false;
    } else {
      return true;
    }
  }

  onCellValueChanged(params) {
    const valueSet = params.data[params.colDef.field];
    if (valueSet) {
      const { locationInputId } = params.data;
      if (
        !this.dataToSave.find((data) => {
          return (
            data.id == params.data.locationInputId.value &&
            data.field === params.colDef.field
          );
        })
      ) {
        this.dataToSave.push({
          id: +locationInputId.value,
          field: params.colDef.field,
          value: valueSet.value,
        });
      } else {
        const objIndex = this.dataToSave.findIndex(
          (obj) =>
            obj.id == params.data.locationInputId.value &&
            obj.field === params.colDef.field
        );
        this.dataToSave[objIndex].value = valueSet.value;
      }
    } else {
      const objIndex = this.dataToSave.findIndex(
        (obj) =>
          obj.id === params.data.locationInputId &&
          obj.field === params.colDef.field
      );
      this.dataToSave.splice(objIndex, 1);
    }
  }

  mapFilterToColumns(columns) {
    columns.forEach((col) => {
      if (['date'].includes(col.field)) {
        col.filter = 'agDateColumnFilter';
        col.filterParams = this.agHelper.getDateFilter();
      }
      if (
        ['opsMin', 'opsMax', 'openingInventory', 'endingInventory'].includes(
          col.field
        )
      ) {
        col.cellStyle = (params) => {
          let borderClass;
          if (params.node.footer) {
            borderClass = { fontWeight: 'bold', textAlign: 'right' };
          } else {
            borderClass = { textAlign: 'right' };
          }
          return borderClass;
        };
      }
    });
  }

  groupRowAggNodes(nodes) {
    let aggRow = {
      openingInventory: 0,
      endingInventory: 0,
      opsMax: 0,
      opsMin: 0,
    };

    nodes.forEach((node) => {
      let data = node.group ? node.aggData : node.data;

      const _openingInventory =
        typeof data.openingInventory === 'object'
          ? data.openingInventory['value']
          : data.openingInventory;
      const _endingInventory =
        typeof data.endingInventory === 'object'
          ? data.endingInventory['value']
          : data.endingInventory;
      const _opsMax =
        typeof data.opsMax === 'object' ? data.opsMax['value'] : data.opsMax;

      const _opsMin =
        typeof data.opsMin === 'object' ? data.opsMin['value'] : data.opsMin;

      aggRow.openingInventory += parseInt(_openingInventory || 0);
      aggRow.endingInventory += parseInt(_endingInventory || 0);
      aggRow.opsMax += parseInt(_opsMax || 0);
      aggRow.opsMin += parseInt(_opsMin || 0);
    });

    return aggRow;
  }

  filterBySearch = (value) => {
    let collection = this.rowData;
    if (!collection) {
      return;
    }

    if (value && value != '') {
      collection = collection.filter(
        (item) =>
          this.agHelper.findSubstring(item.locationCode.value, value) ||
          this.agHelper.findSubstring(item.productName.value, value)
      );
    }
    this.filteredData = collection;
    this.gridApi.setRowData(this.filteredData);
  };

  onStateChange(
    newValue: Partial<StorageAvailState>,
    _oldValue: Partial<StorageAvailState>
  ) {
    if (newValue.searchText || newValue.searchText.length === 0) {
      this.filterBySearch(newValue.searchText);
    }
  }

  setColumnState(newValue, _isColumnStateSet = true) {
    if (newValue.columnState && newValue.columnState.length > 0) {
      this.gridColumnApi.setColumnState(newValue.columnState);
    } else if (newValue.columnState && newValue.columnState.length === 0) {
      this.gridColumnApi.resetColumnState();
    }
  }
  attachGridHandlers() {
    const handler = this.viewAction.onColumnStateChanged.bind(this);
    const filterChangeHandler = this.viewAction.onFilterChanged.bind(this);
    setTimeout(() => {
      this.gridOptions['onFilterChanged'] = filterChangeHandler;
      this.gridOptions['onVirtualColumnsChanged'] = handler;
      this.gridOptions['onGridColumnsChanged'] = handler;
      this.gridOptions['onColumnValueChanged'] = handler;
      this.gridOptions['onDisplayedColumnsChanged'] = handler;
      this.gridOptions['onColumnGroupOpened'] = handler;
    }, 5000);
  }
  clearGridHandlers() {
    this.gridOptions['onFilterChanged'] = null;
    this.gridOptions['onVirtualColumnsChanged'] = null;
    this.gridOptions['onGridColumnsChanged'] = null;
    this.gridOptions['onColumnValueChanged'] = null;
    this.gridOptions['onDisplayedColumnsChanged'] = null;
    this.gridOptions['onColumnGroupOpened'] = null;
  }
  exportAsExcel() {
    const params = {
      fileName: 'Storage Tanks'
    };
    this.gridApi.exportDataAsExcel(params);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
