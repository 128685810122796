import { Component, OnInit, ViewChild } from '@angular/core';
import { formatDate } from '@angular/common';
import { HomeService } from 'src/app/services/home.service';
import { OptimizerService } from 'src/app/services/optimizer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MyCasesModel } from './myCasesModel';
import { CaseModel } from './CaseModel';
import { NotificationService } from 'src/app/services/common/notification.service';
import { EventService } from 'src/app/services/common/event.service';
import { Subscription } from 'rxjs';
import { CaseActionComponent } from 'src/app/shared/grid-components/case-action/case-action.component';
import { DateformatComponent } from 'src/app/shared/grid-components/dateformat/dateformat.component';
import { StateComponent } from 'src/app/core/StateComponent';
import { CaseManagementState } from 'src/app/services/States/case-input-screens/caseManagementState';
import { TabSection } from 'src/app/services/model/TabSection';
import { AgHelper } from 'src/app/services/agHelper';
import { OverlayComponent } from 'src/app/shared/common/overlay/overlay.component';
import { OptimizerActionComponent } from 'src/app/shared/grid-components/optimizer-action/optimizer-action.component';

declare var $: any;

@Component({
  selector: 'app-index',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.scss'],
})
export class IndexComponent
  extends StateComponent<CaseManagementState>
  implements OnInit {
  @ViewChild('myform', { static: true }) myForm: any;
  @ViewChild('mod', { static: true }) deleteModal: any;
  tabName;
  columnDefs;
  optimizerColDefs;
  caseColDefs;
  rowData;
  gridApi;
  gridColumnApi;
  agHelper = AgHelper;
  casesModel: MyCasesModel;
  showMyCases: boolean;
  caseModel: CaseModel;
  nodeToDelete;
  gridOptions = {
    rowBuffer: 100,

    defaultColDef: {
      flex: 1,
      resizable: true,
      filter: true,
      sortable: true,
      enableRowGroup: false,
    },
    frameworkComponents: {
      caseActionComponent: CaseActionComponent,
      optimizerActionComponent: OptimizerActionComponent,
      dateFormat: DateformatComponent,
      overlayComponent: OverlayComponent,
    },
    enableRangeSelection: true,
    enableFillHandle: true,
  };
  subscription: Subscription;
  overlayComponent = 'overlayComponent';
  modal = { title: '', description: '', createdBy: '', createdOn: '' };
  viewAction = {
    handleSearchChange: (data: any) => {
      this.setState({ searchText: data });
    },
    onFilterChanged(filterEvent) {
      const filters = filterEvent.api.filterManager.getFilterModel();
      this.setState({ filteredProps: filters }, true);
    },
    onColumnStateChanged(_event) {
      if (this.gridColumnApi) {
        const columnStates = this.gridColumnApi.getColumnState();
        this.setState({ columnState: columnStates }, true);
      }
    },
    onResetFilter: () => {
      this.clearGridHandlers();
      this.resetState();
      this.attachGridHandlers();
    },
  };
  filteredData = [];
  status = ['Pending', 'Published'];
  constructor(
    private homeService: HomeService,
    private optimizerService: OptimizerService,
    private route: ActivatedRoute,
    private router: Router,
    private notification: NotificationService,
    private event: EventService
  ) {
    super();
    
    this.showMyCases = true;
    this.caseModel = new CaseModel();
    this.route.url.subscribe((url) => {
      this.tabName = url[0].path; 
      if (this.tabName == 'optimizer-runs') {
        this.initState(TabSection.OptimizerManagementScreen);
      } 
      else
      {
        this.initState(TabSection.CaseManagementScreen);
      }
      this.setState({ tabName: this.tabName });   
    });
    this.getColumns();
    if (this.homeService.userProfile) {
      this.getData();
    }
    this.subscription = this.event.events$.subscribe((_event) => {
      switch (_event.name) {
        case 'search':
          this.viewAction.handleSearchChange(_event.value);
          break;
        case 'profile':
          this.getData();
      }
    });
  }

  ngOnInit() {
   
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    this.gridApi.showLoadingOverlay();
  }

  getData() {
    this.clearGridHandlers();
    if (this.tabName == 'optimizer-runs') {
      this.homeService.getOptimizerRowData().subscribe((payload) => {
        this.rowData = payload.data;
        setTimeout(() => {
          this.filterBySearch(this.state.searchText);
        }, 0);
        this.setColumnState(this.state);
        this.attachGridHandlers();
      });
    }
    else {
      this.homeService.getRowData(this.getUserName()).subscribe((payload) => {
        if (this.route.snapshot.routeConfig.path === 'case') {
          this.casesModel = payload.data.myCases;
          
          this.rowData = payload.data.myCases;
        } else {
          this.casesModel = payload.data.publishedCases;
          this.rowData = payload.data.publishedCases;
        }
        setTimeout(() => {
          this.filterBySearch(this.state.searchText);
        }, 0);
        this.setColumnState(this.state);
        this.attachGridHandlers();
      });
    }
  }

  getColumns() {
    this.homeService.getColumnData(this.tabName).subscribe((colData) => {
      if (this.tabName != 'optimizer-runs') {
        this.caseColDefs = colData;
      }
      else {
        this.optimizerColDefs = colData;
      }
      this.columnDefs = colData;
      if (this.tabName === 'optimizer-runs') {
        this.columnDefs.filter(
          (col) => col.field === 'optimizerAction'
        )[0].cellRendererParams = {
          optimizerAction: this.optimizerAction.bind(this),
        };        
      }
      else{
        this.columnDefs.filter(
          (col) => col.field === 'action'
        )[0].cellRendererParams = {
          caseAction: this.caseAction.bind(this),
        };
        this.mapCellHandler();
      }
    });
  }

  mapCellHandler() {
    this.columnDefs.forEach((element) => {
      if (element.field !== 'action') {
        element.onCellClicked = this.onCellClicked.bind(this);
      }
    });
  }

  caseAction(action, params) {
    switch (action) {
      case 'copy':
        this.copyCaseForm(params.data);
        break;
      case 'edit':
        this.showUpdateCaseForm(params.data);
        break;
      case 'delete':
        this.nodeToDelete = params.data;
        this.deleteModal.showModal();  
        break;
      case 'optimize':
        this.runOptimizer(params.data.id);
        break;
      case 'view':
        this.setCaseIdInSessionAndNavigate(params.data);
        break;
      default:
        break;
    }
  }

  optimizerAction(action, params) {
    switch (action) {
      case 'refresh':
        this.refreshRunStatus(params.node, params.data.caseRunId);
        break;    
      case 'report':
        this.setCaseIdInSessionAndNavigateToReports(params.data);
        break;
      case 'view':
        this.setCaseIdInSessionAndNavigateToCase(params.data);
        break;
      default:
        break;
    }
  }

  refreshRunStatus(rowNode, caseRunId) {   
    this.optimizerService.getRunStatus(caseRunId).subscribe((payload) => {
      rowNode.setDataValue('runStatus', payload.status);
    });   
  }

  onCellClicked(event) {
    this.modal.title = event.data.title;
    this.modal.description = event.data.description;
    this.modal.createdBy = event.data.createdBy;
    this.modal.createdOn = event.data.dateCreatedString;
    $('#casemodal').modal('show');
  }

  showPublishedCases() {
    this.router.navigate(['/published-cases']);
  }

  showNewCaseForm() {
    this.caseModel.reset(
      formatDate(new Date(), 'MM/dd/yyyy', 'en-US'),
      null,
      null,
      'Pending',
      null,
      this.getUserName(),
      false,
      null
    );
    $('#caseForm').modal('show');
  }

  showUpdateCaseForm(model: CaseModel) {
    this.caseModel.reset(
      model.baseCaseDateString,
      model.id,
      model.title,
      model.status,
      model.description,
      model.createdBy,
      false,
      null
    );
    $('#caseForm').modal('show');
  }

  runOptimizer(id: number) {
    if (confirm('Are you sure you want to schedule an optimizer run?')) {
      this.optimizerService.run(id).subscribe((_payload) => {
        this.notification.success(
          'Optimizer run has been scheduled successfully'
        );
      });
    }
  }

  copyCaseForm(model: CaseModel) {
    this.caseModel.reset(
      model.baseCaseDateString,
      model.id,
      null,
      model.status,
      null,
      model.createdBy,
      true,
      model.title
    );
    $('#caseForm').modal('show');
  }

  getUserName() {
    return (
      this.homeService.userProfile.givenName +
      ' ' +
      this.homeService.userProfile.displayName.split(',')[0]
    );
  }

  saveCaseChanges() {
    if (this.caseModel.isCopy) {
      this.notification.info('Copying...');
      $('#caseForm').modal('hide');
      this.homeService.copyCase(this.caseModel).subscribe((payload) => {
        this.notification.success('Case is copied successfully');
        this.setCaseIdInSessionAndNavigate(payload.data);
      });
    } else {
      this.homeService.newBaseCase(this.caseModel).subscribe((payload) => {
        $('#caseForm').modal('hide');
        if (this.caseModel.id) {
          this.notification.success('Changes are saved successfully');
          this.getData();
        } else {
          this.setCaseIdInSessionAndNavigate(payload.data);
        }
      },
        (err) => { this.notification.error(err); });
    }
  }

  removeCase(remove) {
    if (remove) {
      this.notification.info('Deleting...!');
      this.homeService.deleteBaseCase(this.nodeToDelete).subscribe(
        (_payload) => {
          this.notification.success('Deleted');
          this.getData();
        },
        (err) => {
          console.log(err); 
         }
      );
    }
  }

  changeStatus(option) {
    this.caseModel.status = option;
  }

  filterBySearch = (value) => {
    let collection = this.rowData;
    if (!collection) {
      return;
    }

    if (value && value != '') {
      collection = collection.filter(
        (item) =>
          this.agHelper.findSubstring(item.title, value) ||
          this.agHelper.findSubstring(item.description, value) ||
          this.agHelper.findSubstring(item.id, value)
      );
    }
    this.filteredData = collection;
    this.gridApi.setRowData(this.filteredData);
  };

  
  onStateChange(
    newValue: Partial<CaseManagementState>,
    _oldValue: Partial<CaseManagementState>
  ) {
    if (newValue.tabName) {
      if (newValue.tabName === 'optimizer-runs') {
        this.columnDefs = this.optimizerColDefs;
      } else {
        this.columnDefs = this.caseColDefs;
      }
      this.getData();      
    } else if (newValue.searchText || newValue.searchText.length === 0) {
      this.filterBySearch(newValue.searchText);
    }
  }
 

  // onStateChange(
  //   newValue: Partial<CaseManagementState>,
  //   oldValue: Partial<CaseManagementState>
  // ) {
  //   if (newValue.searchText || newValue.searchText.length === 0) {
  //     this.filterBySearch(newValue.searchText);
  //   }
  // }

  setColumnState(newValue) {
    if (newValue.columnState && newValue.columnState.length > 0) {
      this.gridColumnApi.setColumnState(newValue.columnState);
    } else if (newValue.columnState && newValue.columnState.length === 0) {
      this.gridColumnApi.resetColumnState();
    }
  }
  attachGridHandlers() {
    const handler = this.viewAction.onColumnStateChanged.bind(this);
    const filterChangeHandler = this.viewAction.onFilterChanged.bind(this);
    setTimeout(() => {
      this.gridOptions['onFilterChanged'] = filterChangeHandler;
      this.gridOptions['onVirtualColumnsChanged'] = handler;
      this.gridOptions['onGridColumnsChanged'] = handler;
      this.gridOptions['onColumnValueChanged'] = handler;
      this.gridOptions['onDisplayedColumnsChanged'] = handler;
      this.gridOptions['onColumnGroupOpened'] = handler;
    }, 10000);
  }
  clearGridHandlers() {
    this.gridOptions['onFilterChanged'] = null;
    this.gridOptions['onVirtualColumnsChanged'] = null;
    this.gridOptions['onGridColumnsChanged'] = null;
    this.gridOptions['onColumnValueChanged'] = null;
    this.gridOptions['onDisplayedColumnsChanged'] = null;
    this.gridOptions['onColumnGroupOpened'] = null;
  }

  setCaseIdInSessionAndNavigate({ id}) {
    window.sessionStorage.setItem('caseID', id);
    this.router.navigate(['case/refinery']);
  }
  setCaseIdInSessionAndNavigateToCase({ userCaseId}) {
    window.sessionStorage.setItem('caseID', userCaseId);
    this.router.navigate(['case/refinery']);
  }

  setCaseIdInSessionAndNavigateToReports({ userCaseId }) {
    window.sessionStorage.setItem('reportsCaseID', userCaseId);    
    this.router.navigate(['report/results']);
  }

  ngOnDestroy() {
    this.subscription && this.subscription.unsubscribe();
  }
}
